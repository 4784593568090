import styled, {keyframes} from "../../styles/styled-components";
import {mediaQueries, sizes} from "../../styles/media-queries";

const FooterStyle = styled.footer`
	padding: 80px 0 20px;

	ul {
		list-style: none;
		display: flex;
    	align-items: stretch;
		justify-content: space-between;
		padding: 0;
		margin: 0;

		li {
			color: #000;
			display: block;
			flex: 0 1 auto;
			list-style-type: none;
			width: 100%;
			text-align: center;
			padding: 5px;

			a {				
				.anticon {
					margin-right: 5px;
				}
			}

			a:focus, a:hover {
				color: #000 !important;
			}
		}
	}
` as any;

export {
	FooterStyle,
};