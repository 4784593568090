import React, {Component} from "react";
import {Form, notification} from 'antd';
import lockr from 'lockr';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {pick} from 'lodash';
import {Link} from 'react-router-dom';

import * as userActions from '../../actions/user'
import {Row, Col, Button, Input, InputPassword, Checkbox, FormItem} from '../../components';

import {PasswordResetStyle} from "./PasswordResetStyles";
import {H2, PageStyle, H1, H3} from "../../styles/global";
import {Redirect} from "react-router";
import {StoreState, UserState, ApplicationState} from '../../types';

export interface Props {
	location: any,
	onSetProfileData: any
	fetchUserTeams: any;
	user: UserState;
	match: any;
	application: ApplicationState;
}

export interface ValidationObject {
	status: string,
	message: string 
}

export interface PasswordResetFormValues {
	password: string,
	passwordValidation: ValidationObject,
	passwordConfirm: string,
	passwordConfirmValidation: ValidationObject,
	redirectToLogin: boolean 
}

const defaultValidationObject =  {status: '', message: ''};

class PasswordReset extends Component<Props, PasswordResetFormValues> {
	state = {
		passwordConfirm: '',
		passwordConfirmValidation: defaultValidationObject,
		password: '',
		passwordValidation: defaultValidationObject,
		redirectToLogin: false
	}

	handleSubmit = (e: any) => {
		e.preventDefault();

		if(this.state.passwordConfirmValidation.status !== 'error' && this.state.passwordValidation.status !== 'error') {
			userActions.passwordReset(this.state.password, this.props.match.params.resetToken)
				.then(() => {
					notification.success({message: 'Je kan inloggen met je nieuw wachtwoord.'});
					this.setState({redirectToLogin: true});		
				})
				.catch(() => {
					notification.error({message: 'Er ging iets verkeerd. Contacteer ons via de chat.'});
					this.setState({redirectToLogin: true});		
				});
		}
	}

	onPasswordChange = (e: any) => {
		const newState = { password: e.target.value, passwordValidation: this.state.passwordValidation };
		if(!e.target.value) {
			newState.passwordValidation = { status: 'error', message: 'Vul je wachtwoord in!'}
		} else if(e.target.value && e.target.value.length < 6) {
			newState.passwordValidation = { status: 'error', message: 'Het wachtwoord moet minstens 6 tekens lang zijn!'}
		} else {
			newState.passwordValidation = defaultValidationObject;
		}
		this.setState(newState);
	};

	onPasswordConfirmChange = (e: any) => {
		const newState = { passwordConfirm: e.target.value, passwordConfirmValidation: this.state.passwordConfirmValidation };
		if(!e.target.value) {
			newState.passwordConfirmValidation = { status: 'error', message: 'Vul je wachtwoord in!'}
		} else if(e.target.value && e.target.value.length < 6) {
			newState.passwordConfirmValidation = { status: 'error', message: 'Het wachtwoord moet minstens 6 tekens lang zijn!'}
		} else if(e.target.value !== this.state.password) {
			newState.passwordConfirmValidation = { status: 'error', message: 'Beide wachwoorden moeten hetzelfde zijn!'}
		} else {
			newState.passwordConfirmValidation = defaultValidationObject;
		}
		this.setState(newState);
	};
	
	render() {
		const {password, passwordValidation, passwordConfirm, passwordConfirmValidation} = this.state;

		return (
			<PasswordResetStyle>
				{this.state.redirectToLogin && <Redirect
					to={{pathname: `/home`}}
				/>}
				<PageStyle>
					<Row gutter={32} style={{backgroundColor: '#F4F8FF', padding: '40px'}}>
						<Col className="gutter-row" md={{span: 10, offset:7}} sm={24} xs={24}>
							<div className="gutter-box" style={{background: '#FFF', padding: '20px', textAlign: 'center'}}>
								<Form className="password-reset-form">
									<H3 style={{textAlign: 'left'}}>Nieuw wachtwoord</H3>
									<FormItem
										validateStatus={passwordValidation.status}
										help={passwordValidation.message}>
											<InputPassword 
												type="password" 
												name="password"
												value={password}
												onChange={this.onPasswordChange}
												placeholder="Wachtwoord"/>
									</FormItem>

									<FormItem
										validateStatus={passwordConfirmValidation.status}
										help={passwordConfirmValidation.message}>
											<InputPassword 
												type="password" 
												name="password"
												value={passwordConfirm}
												onChange={this.onPasswordConfirmChange}
												placeholder="Bevestig wachtwoord"/>
									</FormItem>
									<Button type="primary" onClick={this.handleSubmit} htmlType="submit" style={{margin: '20px auto'}}>Verander wachtwoord</Button>
								</Form>
							</div>
						</Col>
					</Row>
				</PageStyle>
			</PasswordResetStyle>
		);
	}
}

export const mapDispatchToProps = {
	onSetProfileData: (data: any) => (dispatch: any) => dispatch(userActions.setProfileData(data)),
	fetchUserTeams: userActions.fetchTeams
};

export function mapStateToProps({user, application, matches}: StoreState.All) {
	return {
		user,
		application
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
