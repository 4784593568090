import React, { Component } from "react";
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Card, Form } from 'antd';
import { Link } from 'react-router-dom';
import { Collapse } from 'antd';
import lockr from 'lockr';
import Media from 'react-media';

import * as userActions from '../../actions/user'
import * as teamsActions from '../../actions/teams';
import { StoreState, UserState, MatchesState, Match, ApplicationState } from './../../types';
import { Button, Input, InputPassword, Checkbox, Team, Title, TitleType, FormItem, RegisterForm, Row, Col, Block, FootballCalendar } from './../../components';
import config from './../../config';
import { CardStyle, HomeStyle, Cover } from "./HomeStyles";
import { H2, PageStyle, H1, H3 } from "../../styles/global";
import { mediaQueries, sizes } from '../../styles/media-queries';
import moment from 'moment';
import Countdown from 'react-countdown-now';
import { Redirect } from "react-router";
import { notification } from 'antd';
import Login from "../Login/Login";

const { Panel } = Collapse;

export interface Props {
	user: UserState;
	match: any;
	matches: MatchesState;
	application: ApplicationState;
	onLogOut?: () => void;
	t: any;
}

export interface State {

}

const { Meta } = Card;

const WrappedRegisterForm = Form.create()(RegisterForm);

class Home extends Component<Props> {

	state = {
		
	}

	constructor(props: Props) {
		super(props);
	}

	render() {
		const deadlineDate = this.props.matches && this.props.matches.info && this.props.matches.info.deadlineDate;

		const momentDeadlineDate = deadlineDate ? moment(deadlineDate).valueOf() : moment().valueOf();
		const deadlineMatch = this.props.matches.data
			.find((match: Match) => !!(
				this.props.matches.info &&
				this.props.matches.info.deadlineWeek &&
				match.weekId === this.props.matches.info.deadlineWeek
			));
		const hasDeadlineWeek = this.props.matches && this.props.matches.info && this.props.matches.info.deadlineWeek;

		const deadlineRenderer = ({ days, hours, minutes, seconds }: any) => {
			return <H3 style={{ color: 'white' }}>
				{deadlineMatch ? deadlineMatch.feedUrl : ''} : {days}d {hours}u {minutes}m {seconds}sec
			</H3>;
		};

		const team = this.props.user && this.props.user.teams && this.props.user.teams.find(team => team.competitionFeed === this.props.application.competition.competitionFeed);
		const redirectToMyTeams = this.props.match.path !== '/home' && this.props.user && this.props.user.authenticated;

		return (
			<HomeStyle>
				{redirectToMyTeams && this.props.user.authenticated && team && <Redirect
					to={{ pathname: `/${this.props.application.competition.slug}/team/${team.id}` }}
				/>}
				{/* {redirectToMyTeams && this.props.user.authenticated && !team && <Redirect
					to={{ pathname: `/${this.props.application.competition.slug}/new` }}
				/>} */}
				<Row>
					<Col md={12} lg={12} sm={24} xs={24} >
						{/* <Block style={{marginTop: "20px"}}>
							<Title type={TitleType.h2}>HOE SPELEN?</Title>

							<Row className="onboarding">
								<Col lg={12}>
									<h4 className="title">De selectie <span style={{color: "#FFF"}}>☝️</span></h4>
									Je kan 100 miljoen spenderen aan 15 spelers uit Eerste Klasse A. Kies max. 3 spelers van dezelfde club en duid een kapitein en vice-kapitein aan.
								</Col>
								<Col lg={12} style={{textAlign: "center"}}><img src='https://fanarena.s3-eu-west-1.amazonaws.com/files/1-Team.png'></img></Col>
							</Row>
							<Row className="onboarding" style={{backgroundColor: "#121212"}}>
								<Col lg={12} style={{textAlign: "center"}}><img src='https://fanarena.s3-eu-west-1.amazonaws.com/files/2-Wedstrijd.png'></img></Col>
								<Col lg={12}>
									<h4 className="title">De wedstrijd <span style={{color: "#FFF"}}>⚽</span></h4>
									Iedere speeldag verdien je punten met de acties die jouw spelers in de échte matchen op de mat leggen. Doelpunten, assists, speelminuten en clean sheets leveren punten op.
								</Col>
							</Row>
							<Row className="onboarding" justify="center" align="middle">
								<Col lg={12}>
									<h4 className="title">De overwinning <span style={{color: "#FFF"}}>🏆</span></h4>
									Speel tegen vrienden, familie of collega's. Win jouw minicompetitie en verdien eeuwige roem. Ga aan de haal met de hoofdprijs, word jij de Fan Arena Baas dit jaar?
								</Col>
								<Col lg={12}><img src='https://fanarena.s3-eu-west-1.amazonaws.com/files/3-Overwinning.png'></img></Col>
							</Row>
							<Row className="onboarding" style={{backgroundColor: "#121212"}}>
								<Col lg={12} style={{textAlign: "center"}}><img src='https://fanarena.s3-eu-west-1.amazonaws.com/files/4-Premium.png'></img></Col>
								<Col lg={12}>
									<h4 className="title">De extraatjes <span style={{color: "#FFF"}}>👑</span></h4>
									Haal alles uit de kast. Word Premium en krijg leuke extra's zoals verfijnde stats, power subs, minicompetities met chat en veel meer. En zo steun je ons!
								</Col>
							</Row>

						</Block> */}
					</Col>

					<Col md={12} lg={12} sm={24} xs={24} >
						{/* {
							!this.props.user.authenticated ? <Login t={this.props.t} /> : null
						} */}

						{
							this.props.matches.data && this.props.matches.data.length && this.props.matches.info.displayWeek ? <Block>
								<Title type={TitleType.h2}>Calendar</Title>
								<FootballCalendar
									data={this.props.matches.data}
									size={30}
									t={this.props.t}
									weekId={this.props.matches.info.displayWeek}
									showHeader={false}
									assetsCdn={this.props.application.competition.assetsCdn}
									isLoading={this.props.matches.isFetching}
									application={this.props.application} />
							</Block> : null
						}
					</Col>
				</Row>
			</HomeStyle>
		);
	}
}

export function mapDispatchToProps(dispatch: Dispatch<userActions.UserAction>) {
	return {
		onLogOut: () => dispatch(userActions.logOut()),
	}
}

export function mapStateToProps({ user, matches, application }: StoreState.All) {
	return {
		user,
		matches,
		application
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
