import styled from "../../styles/styled-components";
import { mediaQueries } from "../../styles/media-queries";
import { H2, H1 } from "../../styles/global";

const LoginStyle = styled.div`
	margin-bottom: 20px;

	.login-content {
		padding: 20px;
		background-color: #FFF;
		text-align: center;
		color: #000;
	}

	.register-button {
		border-radius: 0px;
		height: auto;
		margin-bottom: 20px;
	}

	.register-button, .facebook-button {
		min-width: 250px;
		height: 60px;
		
		.anticon {
			margin-right: 10px; 
		}
	}

	.ant-form-item {
		margin-bottom: 0;
	}

	.facebook-button {
		font-weight: 700;
		-webkit-font-smoothing: antialiased;
		color: #000;
		cursor: pointer;
		display: inline-block;
		text-decoration: none;
		transition: background-color .3s,border-color .3s;
		background-color: #4c69ba;
		border: 0;
	}

	${H2} {
		text-align: center;
		margin: 40px 0;
	}
	

`;

export {
	LoginStyle,
};