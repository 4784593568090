import React, { Component } from 'react';
import { PlayerStyle, PlayerBg, SoccerJersey, Badge, Points, NoPlayer, TopRightAction, Value, OpponentBadge, ClubBadgeBg, TopLeftAction, BottomRightAction } from './PlayerStyle';
import { Icon } from 'antd';
import { theme } from "../../styles/theme-playsports";
import { firstLetterUppercased, getPlayerPositionHexColor } from './../../lib/helpers';
import { PlayerModal } from './../../components';
import { Club, BoostersWeekStatus } from './../../types';
import { AddButtonSvg, CaptainButtonSvg, ViceCaptainButtonSvg, SwapButtonSvg, RollBackSvg, DeleteButtonSvg, InjurySvg, redCardSvg } from './../../styles/custom-icons';
import i18next from 'i18next';

const AddIcon = (props: any) => <Icon component={AddButtonSvg} {...props} />;
const CaptainIcon = (props: any) => <Icon component={CaptainButtonSvg} {...props} />;
const ViceCaptainIcon = (props: any) => <Icon component={ViceCaptainButtonSvg} {...props} />;
const SwapIcon = (props: any) => <Icon component={SwapButtonSvg} {...props} />;
const UndoIcon = (props: any) => <Icon component={RollBackSvg} {...props} />;
const DeleteIcon = (props: any) => <Icon component={DeleteButtonSvg} {...props} />;
const InjuryIcon = (props: any) => <Icon component={InjurySvg} {...props} />;
const RedCardIcon = (props: any) => <Icon component={redCardSvg} {...props} />;

export enum PlayerType {
	Cycling = "Cycling",
	SoccerShirt = "SoccerShirt",
	SoccerPortrait = "SoccerPortrait"
};

export interface PlayerState {
	modalVisible: boolean;
	shirtSoccer?: string;
	shirtCycling?: string;
	portraitFace?: string;
}

export interface PlayerProps {
	type: PlayerType;
	bgShirt?: string;
	bgPortrait?: string;
	bgJersey?: string;
	player: any;
	badgeColor: string;
	badgeBgColor: string;
	pointsColor: string;
	pointsBgColor: string;
	positionIndex?: number;
	shirtCycling?: string;
	shirtSoccer?: string;
	portraitFace?: string;
	portraitFaceFallBack?: string;
	soccerJersey?: string;
	onRemove?: any;
	isSwapAble?: any;
	modalEnabled?: any;
	onPlaceholderClick?: any;
	swapPlayerId?: number | null;
	swappedFrom?: string | null;
	positionLabel?: string;
	onSwap?: any;
	onCaptainSelect?: any;
	onViceCaptainSelect?: any;
	actionLessPlayerIds?: any[];
	showPlayerValue?: boolean;
	showCaptainBadge?: boolean;
	showPlayerStatsPoints?: boolean;
	showPlayerValueInsteadOfPoints?: boolean;
	benchPlayer?: boolean;
	club?: Club;
	captainId?: number;
	boosterWeekStatus?: BoostersWeekStatus;
	viceCaptainId?: number;
	captainHasPlayed?: boolean;
	avatarOnly?: boolean;
	shirtFallback?: string;
	clubBadge?: string;
	t: i18next.TFunction;
}

class Player extends Component<PlayerProps, PlayerState> {
	constructor(props: PlayerProps) {
		super(props);

		this.state = {
			shirtSoccer: props.shirtSoccer,
			portraitFace: props.portraitFace,
			modalVisible: false
		};
	}

	onCancel = (event: any) => {
		event.stopPropagation();
		this.setState({ modalVisible: false });
	}

	onPlayerClick = (showModal?: boolean) => {
		if (this.props.player && this.props.player.id && showModal) {
			this.setState({ modalVisible: true });
		}
	}

	onBgLoadError = (event: any) => {
		if (this.props.shirtFallback) {
			this.setState({ shirtSoccer: this.props.shirtFallback });
		}

		if (this.props.portraitFaceFallBack) {
			this.setState({ portraitFace: this.props.portraitFaceFallBack });
		}
	}

	componentDidUpdate(prevProps: any) {
		if (this.props.shirtSoccer !== prevProps.shirtSoccer) {
			this.setState({ shirtSoccer: this.props.shirtSoccer });
		}

		if (this.props.portraitFace !== prevProps.portraitFace) {
			this.setState({ portraitFace: this.props.portraitFace });
		}
	}

	onSwap(event: any, player: Player) {
		event.stopPropagation();
		this.props.onSwap(player);
	}

	onRemoveHandler(e: any, player: Player) {
		e.stopPropagation();

		this.props.onRemove(player);
	}

	render() {
		const {
			type, bgShirt, player, badgeColor, badgeBgColor, pointsColor, pointsBgColor,
			shirtCycling, soccerJersey, onRemove, onSwap, swapPlayerId, swappedFrom,
			onPlaceholderClick, actionLessPlayerIds, showPlayerValue, benchPlayer, showPlayerValueInsteadOfPoints,
			modalEnabled, club, onCaptainSelect, captainId, shirtFallback, isSwapAble, showPlayerStatsPoints, clubBadge, t,
			portraitFaceFallBack, viceCaptainId, captainHasPlayed, showCaptainBadge, onViceCaptainSelect, avatarOnly,
			positionIndex, positionLabel, boosterWeekStatus
		} = this.props;

		let opponentInfo = null;

		if (player && player.upcomingMatches && player.upcomingMatches.length) {
			const nextMatch = player.upcomingMatches[0];
			opponentInfo = {
				playing: nextMatch.homeId.id === player.clubId ? t('player.opponentHome') : t('player.opponentAway'),
				opponentShort: nextMatch.homeId.id === player.clubId ? nextMatch.awayId.short : nextMatch.homeId.short
			}
		}

		const { shirtSoccer, portraitFace } = this.state;

		const { modalVisible } = this.state;

		const topRightActionIconStyle = {
			fontSize: '12px',
			width: '12px',
			height: '12px',
			color: 'black',
			position: 'absolute' as 'absolute',
			left: '4px',
			top: '4px',
			textAlign: 'center' as 'center'
		};

		const ignoredPlayersIdsForActions = actionLessPlayerIds || [];

		const isCaptain = player && player.id && player.id === captainId;
		const isViceCaptain = player && player.id && player.id === viceCaptainId;

		const swappedAlreadyFromPlayerArea = onSwap && swapPlayerId && player && (swappedFrom === 'starting' && player.inStarting) && swapPlayerId !== player.id;

		const gamePositionIdToLabels = [
			{ id: 14, name: t('player.goalkeeper') },
			{ id: 15, name: t('player.defender') },
			{ id: 16, name: t('player.midfielder') },
			{ id: 17, name: t('player.attacker') }
		];

		const currentPositionLabel = gamePositionIdToLabels.find((item: any) => !!(item.id === positionIndex))
		const playerName = (player && player.id && player.short) || (player && player.id && `${player.surname} ${player.forename && firstLetterUppercased(player.forename)}.`) || `${currentPositionLabel ? currentPositionLabel.name : t('general.pick')}`;

		const playerHasAction = player && player.id && ignoredPlayersIdsForActions.indexOf(player.id) === -1;
		const pointsEnabled = (player && player.points !== undefined && player.points !== null) || showPlayerValueInsteadOfPoints || showPlayerStatsPoints;
		const hasModal = !!modalEnabled;

		const playerPositionColor = getPlayerPositionHexColor(player, theme);
		const hasInactiveOverlay = swappedAlreadyFromPlayerArea || (swapPlayerId && !swappedAlreadyFromPlayerArea && isSwapAble && !isSwapAble(player));
		const hasStats = player && player.stats && player.stats.length;
		const showPlayerName = !avatarOnly;

		if (player && showPlayerStatsPoints && hasStats) {
			const weekStatsPoints = player.stats.reduce((acc: number, stat: any) => acc + stat.points, 0);
			const pointsForCaptainOrViceCaptain = isCaptain || (!captainHasPlayed && isViceCaptain);

			const weekStatsPointsFactor = pointsForCaptainOrViceCaptain ?
				(boosterWeekStatus && boosterWeekStatus.tripleCaptain ? 3 : 2)
				: 1;
			player.points = weekStatsPoints * weekStatsPointsFactor;
		}

		return (
			<PlayerStyle onClick={(e: any) => this.onPlayerClick(!hasInactiveOverlay)}>
				{player && player.id && type === PlayerType.Cycling && <PlayerBg bg={shirtCycling} inactive={hasInactiveOverlay} fallback={shirtFallback} />}
				{player && player.id && type === PlayerType.SoccerShirt && <PlayerBg onError={this.onBgLoadError} src={shirtSoccer} inactive={hasInactiveOverlay} fallback={shirtFallback} />}
				{player && player.id && type === PlayerType.SoccerPortrait && <PlayerBg onError={this.onBgLoadError} src={portraitFace} inactive={hasInactiveOverlay} fallback={portraitFaceFallBack} />}
				{player && player.id && type === PlayerType.SoccerPortrait && <ClubBadgeBg onError={this.onBgLoadError} src={clubBadge} inactive={hasInactiveOverlay} />}
				{player && player.id && type === PlayerType.SoccerPortrait && <SoccerJersey bg={soccerJersey} />}
				{
					showPlayerName ? <Badge color={badgeColor} bgColor={type === PlayerType.SoccerShirt ? playerPositionColor : badgeBgColor}>
						<span style={{ color: pointsColor }}>{playerName}</span>
					</Badge> : null
				}

				{
					pointsEnabled && hasStats && player && player.points !== null && player.points !== undefined ?
						<Points color={'#FFF'} bgColor={isCaptain || isViceCaptain ? '#ffc422' : '#00fe82'}> {player.points} </Points> : null
				}

				{
					opponentInfo ?
						<OpponentBadge bgColor={pointsBgColor} color={'#FFF'}>
							<p style={{fontSize: '10px'}}>
								{`${opponentInfo.opponentShort} (${opponentInfo.playing})`}
							</p>
						</OpponentBadge> : null
				}

				{
					(
						player && showPlayerValue &&
						<Value bgColor={pointsBgColor} color={'#FFF'} benchPlayer={benchPlayer}>
							<span>€{player.value}M</span>
						</Value>
					) || null
				}

				{((!player || (player && !player.id)) &&
					<NoPlayer onClick={onPlaceholderClick ? (e: any) => onPlaceholderClick(player) : (() => {
					})}>
						<AddIcon
							style={{ fontSize: '2em', color: badgeBgColor, cursor: 'pointer' }} />
					</NoPlayer>) || null}
				{(player && playerHasAction && onRemove &&
					<TopLeftAction className="delete" onClick={(e: any) => this.onRemoveHandler(e, player)} bgColor={theme.primaryColor}>
						<DeleteIcon />
					</TopLeftAction>) || null}
				{(player && onSwap && isSwapAble && isSwapAble(player) && !swappedAlreadyFromPlayerArea && swapPlayerId !== player.id &&
					<TopLeftAction onClick={(e: any) => this.onSwap(e, player)} bgColor={theme.primaryColor}>
						<SwapIcon style={{fontSize: '20px'}} />
					</TopLeftAction>) || null}
				{(player && onSwap && (swapPlayerId && swapPlayerId === player.id) &&
					<TopLeftAction onClick={(e: any) => this.onSwap(e, player)} bgColor={theme.primaryColor}>
						<UndoIcon />
					</TopLeftAction>) || null}

				{(player && isCaptain && showCaptainBadge &&
					<TopRightAction bgColor={theme.primaryColor}>
						<CaptainIcon style={{ fontSize: 18 }} />
					</TopRightAction>) || null}

				{(player && isViceCaptain && showCaptainBadge &&
					<TopRightAction bgColor={theme.primaryColor}>
						<ViceCaptainIcon style={{ fontSize: 18 }} />
					</TopRightAction>) || null}

					{(player && (player.ban === 1 || player.injury === 1) &&
					<BottomRightAction bgColor={theme.primaryColor}>
						{(player.ban === 1) && <RedCardIcon style={{ marginRight: "2px" }}/> }
						{(player.injury === 1) && <InjuryIcon style={{ marginRight: "2px" }}/> }
					</BottomRightAction>
					) || null}

				{
					player && positionLabel && positionLabel.length ?
						<span className="position-label"> {positionLabel} </span> : null
				}

				{
					player && player.id && club && hasModal ?
						<PlayerModal
							visible={modalVisible}
							club={club}
							swapPlayerId={swapPlayerId}
							onCaptainSelect={onCaptainSelect}
							onRemove={onRemove}
							type={type}
							isSwapAble={isSwapAble}
							portraitFaceFallBack={portraitFaceFallBack}
							portraitFace={portraitFace}
							soccerJersey={soccerJersey}
							clubBadge={clubBadge}
							onSwap={onSwap}
							shirtCycling={shirtSoccer}
							player={player}
							shirtFallback={shirtFallback}
							t={t}
							onCancel={this.onCancel} /> :
						null
				}
			</PlayerStyle>
		)
	}
}

export default Player;