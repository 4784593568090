import React, { Component } from 'react';
import { Icon } from 'antd';

import { StatsStyle } from './StatsStyle';
import { Col, Row } from '..';

import { ConfirmedSvg, UnConfirmedSvg } from '../../styles/custom-icons';

const ConfirmedIcon = (props: any) => <Icon component={ConfirmedSvg} {...props} />;
const UnConfirmedIcon = (props: any) => <Icon component={UnConfirmedSvg} {...props} />;

export interface StatsOverviewState {
}

export interface StatsOverviewProps {
	budget: number;
	totalPlayers: number;
	totalPlayersSelected: number;
	remainingFreeTransfers: number;
	minusPoints: number;
	t: any;
};

class TransfersOverview extends Component<StatsOverviewProps, StatsOverviewState> {

	render() {
		const { t } = this.props;

		return (
			<StatsStyle>
				<Row className="stat-row">
					<Col lg={16} md={16} sm={16} xs={16} className="label">{t('transfersPage.overviewBudget')}</Col>
					<Col lg={8} md={8} sm={8} xs={8} className="points">€{this.props.budget.toFixed(2)} mil</Col>
				</Row>
				<Row className="stat-row">
					<Col lg={16} md={16} sm={16} xs={16} className="label">{t('transfersPage.overviewPlayers')}</Col>
					<Col lg={8} md={8} sm={8} xs={8} className="points">{this.props.totalPlayersSelected}/{this.props.totalPlayers}</Col>
				</Row>
				<Row className="stat-row">
					<Col lg={16} md={16} sm={16} xs={16} className="label">Remaining transfers</Col>
					<Col lg={8} md={8} sm={8} xs={8} className="points">{this.props.remainingFreeTransfers < 0 ? 0 : this.props.remainingFreeTransfers}</Col>
				</Row>
				{/* <Row className="stat-row">
					<Col lg={16} md={16} sm={16} xs={16} className="label">{t('transfersPage.overviewMinusPoints')} </Col>
					<Col lg={8} md={8} sm={8} xs={8} className={`points ${this.props.minusPoints > 0 ? 'red' : 'green'}`}>{this.props.minusPoints}</Col>
				</Row> */}
			</StatsStyle>
		)
	}
}

export default TransfersOverview;