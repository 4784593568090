import styled from 'styled-components';

const PointsStatsStyle = styled.div`
		border-radius: 0;
		padding-top: 10px;
		padding-bottom: 10p

		.overview {
			.ant-col {
				padding: 10px;

				&:nth-child(even) {
					text-align: right;
				}
			}

			.label {
				padding: 5px 6px;
				display: block;
				text-align: center;
                color: #989998;
                text-transform: uppercase;
			}

			.points {
				color: ${props => props.theme.primaryColor};
				display: block;
				text-align: center;
    		    font-weight: bold;
			}
		}
`;

const RowStyle = styled.div`
    &:first-child {
        text-align: right;
        margin-right: 1.5px;

        > div {
            justify-content: flex-end;

            span {
                margin-left: 10px;
            }
        }
    }

    &:last-child {
        text-align: left;
        margin-left: 1.5px;

        > div {
            justify-content: flex-start;

            span {
                margin-right: 10px;
            }
        }
    }
`;

const PointStyle = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 3px;

    color: black;
    line-height: 1;
    font-weight: bold;

    span {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 80px;
        height: 35px;
        background-color: ${props => props.theme.primaryContrast}

        color: #000;
    }
`;

export {
    PointsStatsStyle,
    RowStyle,
    PointStyle
};
