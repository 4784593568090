import React, { Component } from "react";
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Tabs } from 'antd';

import * as userActions from '../../actions/user'
import * as playersActions from '../../actions/players'
import { StoreState, ApplicationState, PlayersState, ClubsState, Player, MatchesState, UserState } from './../../types';
import PlayerAvatar from '../../components/Player/Player';
import { Link } from './../../components/UI/Link/Link';
import Popover from '../../components/UI/Popover/Popover';
import { BasicTableStyle } from './../../components/PlayerList/PlayerListStyle';
import { SelectedPlayer, SelectedPlayerInfo } from './StatsStyles';
import { Icon } from "antd";
import { redCardSvg, yellowCardSvg } from '../../styles/custom-icons';


const RedCardIcon = (props: any) => <Icon component={redCardSvg} {...props} />;
const YellowCardIcon = (props: any) => <Icon component={yellowCardSvg} {...props} />;
const { TabPane } = Tabs;

import { Element, scroller } from 'react-scroll'

import {
	Title,
	TitleType,
	PlayerStatsList,
	PlayerType,
	Block,
	Button,
	Row,
	Col
} from './../../components';
import { Redirect } from "react-router-dom";

export interface Props {
	application: ApplicationState;
	players: PlayersState;
	clubs: ClubsState;
	matches: MatchesState;
	t: any;
	user: UserState;
}

export interface State {
	selectedPlayer: any,
	selectedPlayerStats: any,
	selectedPlayerAggregatedStats: any,
	navigateToMatch: any
}

const popoverStyle = {
	cursor: 'pointer',
}


class Stats extends Component<Props, State> {

	constructor(props: Props) {
		super(props);

		this.state = {
			selectedPlayer: null,
			selectedPlayerStats: [],
			selectedPlayerAggregatedStats: [],
			navigateToMatch: null
		};
	};

	getPlayerDetails(firstPlayer: Player) {
		playersActions.getPlayerDetails(
			this.props.application.competition.competitionFeed,
			this.props.application.competition.seasonId,
			firstPlayer.id
		)
			.then((result: any) => {
				this.setState({
					selectedPlayer: firstPlayer,
					selectedPlayerStats: result.stats || [],
					selectedPlayerAggregatedStats: result.aggregatedWeekStats || []
				});
			});
	}

	setPlayer = (player: any) => {
		playersActions.getPlayerDetails(
			this.props.application.competition.competitionFeed,
			this.props.application.competition.seasonId,
			player.playerId
		)
			.then((result: any) => {
				this.setState({
					selectedPlayer: { ...player, id: player.playerId },
					selectedPlayerStats: result.stats || [],
					selectedPlayerAggregatedStats: result.aggregatedWeekStats || []
				});

				scroller.scrollTo('player-stats', {
					duration: 1000,
					delay: 100,
					smooth: true,
					offset: 0
				})
			});
	};

	render() {
		let selectedPlayerClub = null;
		const { t, matches } = this.props;
		const { selectedPlayerAggregatedStats, navigateToMatch } = this.state;

		const sportSpecificProps: {
			soccerJersey?: string,
			clubBadge?: string,
			portraitFace?: string,
			portraitFaceFallBack?: string
		} = {};

		const selectedPlayerAggregatedStatsTotalPoints = selectedPlayerAggregatedStats
			.reduce((acc: number, item: any) => acc + item.total, 0);

		const selectedPlayer = this.state.selectedPlayer ? { ...this.state.selectedPlayer, ...this.state.selectedPlayer.generalInfo } : null;

		if (selectedPlayer && this.props.clubs.data) {
			selectedPlayerClub = this.props.clubs.data.find((club: any) => club.id === selectedPlayer.clubId);
			sportSpecificProps.soccerJersey = `${this.props.application.competition.assetsCdn}/jerseys/club_${selectedPlayer.clubId}.png`;
			sportSpecificProps.clubBadge = `${this.props.application.competition.assetsCdn}/badges/club_${selectedPlayer.clubId}.png`;
			sportSpecificProps.portraitFace = `${this.props.application.competition.assetsCdn}/players/player_${selectedPlayer.id}.png`;
			sportSpecificProps.portraitFaceFallBack = `${this.props.application.competition.assetsCdn}/players/dummy.png`;
		}

		const columns = [{
			key: 'weekId',
			title: <Popover content={t('statsPage.popoverGameWeekTableColumnPopover')} trigger="click">
				<span style={popoverStyle} >
					{t('statsPage.gameWeekColumnName')}
				</span>
			</Popover>,
			dataIndex: 'weekId',
			width: '8%',
			render: (text: string, record: any) => {
				return <b>{record.weekId}</b>
			}
		},
		{
			key: 'clubId',
			title: <Popover content={t('statsPage.popoverOpponentTableColumnPopover')} trigger="click">
				<span style={popoverStyle} >
					{t('statsPage.opponentColumnName')}
				</span>
			</Popover>,
			dataIndex: 'clubId',
			width: '10%',
			render: (text: string, record: any) => {

				const weekMatches: any[] = this.props.matches.data
					.filter((match: any) => match.weekId === record.weekId && ([match.homeId.id, match.awayId.id].includes(record.clubId)));
				let opponent = '';

				if (weekMatches.length) {
					const opponentClubId = weekMatches[0].homeId.id === record.clubId ? weekMatches[0].awayId.id : weekMatches[0].homeId.id;
					const club = this.props.clubs.data.find((item: any) => item.id === opponentClubId);
					opponent = club ? club.short : '';
				}

				return <b>{opponent}</b>
			}
		},
		{
			key: 'time',
			width: '8%',
			title: <Popover content={t('statsPage.popoverTimeTableColumnPopover')} trigger="click">
				<span style={popoverStyle} >
					{t('statsPage.timeColumnName')}
				</span>
			</Popover>,
			dataIndex: 'time',
			render: (text: string, record: any) => {
				const match = this.state.selectedPlayerStats.find((item: any) => item.match.id === record.matchId);
				let time = '';
				if (match && match.stat) {
					let parsedValue: any = {};
					try {
						parsedValue = JSON.parse(match.stat.value);
						time = parsedValue && parsedValue.time;
					} catch {
						time = 'N/A';
					}
				}
				return <b>{time}</b>
			}
		},
		{
			key: 'statGoals',
			width: '8%',
			title: <Popover content={t('statsPage.popoverGoalsTableColumnPopover')} trigger="click">
				<span style={popoverStyle} >{t('statsPage.goalsColumnName')}</span></Popover>,
			dataIndex: 'statGoals',
			render: (text: string, record: any) => {
				return <b>{record.statGoals}</b>
			}
		},
		{
			key: 'statAssists',
			width: '8%',
			title: <Popover content={t('statsPage.popoverAssistsTableColumnPopover')} trigger="click"><span style={popoverStyle} >{t('statsPage.assistsColumnName')}</span></Popover>,
			dataIndex: 'statAssists',
			render: (text: string, record: any) => {
				return <b>{record.statAssists}</b>
			}
		},
		{
			key: 'statOwnGoal',
			width: '8%',
			title: <Popover content={t('statsPage.popoverOwnGoalTableColumnPopover')} trigger="click">
				<span style={popoverStyle} >{t('statsPage.ownGoalColumnName')}</span></Popover>,
			dataIndex: 'statOwnGoal',
			render: (text: string, record: any) => {
				return <b>{record.statOwnGoal}</b>
			}
		},
		{
			key: 'statPenaltyMiss',
			width: '8%',
			title: <Popover content={t('statsPage.popoverPenaltyMissedTableColumnPopover')} trigger="click"><span style={popoverStyle} >{t('statsPage.penaltyMissedColumnName')}</span></Popover>,
			dataIndex: 'statPenaltyMiss',
			render: (text: string, record: any) => {
				return <b>{record.statPenaltyMiss}</b>
			}
		},
		{
			key: 'statPenaltySave',
			width: '8%',
			title: <Popover content={t('statsPage.popoverStoppedPenaltyTableColumnPopover')} trigger="click"><span style={popoverStyle} >{t('statsPage.stoppedPenaltyColumnName')}</span></Popover>,
			dataIndex: 'statPenaltySave',
			render: (text: string, record: any) => {
				return <b>{record.statPenaltySave}</b>
			}
		},
		{
			key: 'statYellow',
			width: '8%',
			title: <Popover content={t('statsPage.popoverYellowCardTableColumnPopover')} trigger="click"><span style={popoverStyle} ><YellowCardIcon /></span></Popover>,
			dataIndex: 'statYellow',
			render: (text: string, record: any) => {
				return <b>{record.statYellow}</b>
			}
		},
		{
			key: 'statRed',
			width: '8%',
			title: <Popover content={t('statsPage.popoverRedCardTableColumnPopover')} trigger="click"><span style={popoverStyle} ><RedCardIcon /></span></Popover>,
			dataIndex: 'statRed',
			render: (text: string, record: any) => {
				return <b>{record.statRed}</b>
			}
		},
		{
			key: 'total',
			width: '8%',
			title: <Popover content={t('statsPage.popoverPointsTableColumnPopover')} trigger="click"><span style={popoverStyle} >{t('statsPage.pointsColumnName')}</span></Popover>,
			dataIndex: 'total',
			render: (text: string, record: any) => {
				return <b>{record.total}</b>
			}
		}
		];

		const careerColumns = [
			{
				key: 'season',
				title: <Popover content={t('statsPage.seasonTitle')} trigger="click">
					<span style={popoverStyle} >
						{t('statsPage.seasonColumn')}
					</span>
				</Popover>,
				dataIndex: 'season',
				width: '10%',
				render: (text: string, record: any) => {
					return <b>{record.season}</b>
				}
			},
			{
				key: 'team',
				title: <Popover content={t('statsPage.teamTitle')} trigger="click">
					<span style={popoverStyle} >
						{t('statsPage.teamColumn')}
					</span>
				</Popover>,
				dataIndex: 'team',
				width: '10%',
				render: (text: string, record: any) => {
					return <b>{record.team}</b>
				}
			},
			{
				key: 'appearances',
				title: <Popover content={t('statsPage.appearancesTitle')} trigger="click">
					<span style={popoverStyle} >
						{t('statsPage.appearancesColumn')}
					</span>
				</Popover>,
				dataIndex: 'appearances',
				width: '10%',
				render: (text: string, record: any) => {
					return <b>{record.appearances}</b>
				}
			},
			{
				key: 'goals',
				title: <Popover content={t('statsPage.goalsTitle')} trigger="click">
					<span style={popoverStyle} >
						{t('statsPage.goalsColumn')}
					</span>
				</Popover>,
				dataIndex: 'goals',
				width: '10%',
				render: (text: string, record: any) => {
					return <b>{record.goals}</b>
				}
			},
			{
				key: 'game-minutes',
				title: <Popover content={t('statsPage.gameMinutesTitle')} trigger="click">
					<span style={popoverStyle} >
						{t('statsPage.gameMinutesColumn')}
					</span>
				</Popover>,
				dataIndex: 'game-minutes',
				width: '10%',
				render: (text: string, record: any) => {
					return <b>{record['game-minutes']}</b>
				}
			},
			{
				key: 'goal-minutes',
				title: <Popover content={t('statsPage.goalsPer90MinuteTitle')} trigger="click">
					<span style={popoverStyle} >
						{t('statsPage.goalsPer90MinuteColumn')}
					</span>
				</Popover>,
				dataIndex: 'goal-minutes',
				width: '10%',
				render: (text: string, record: any) => {
					return <b>{ Math.round((90*record['goals'])/record['game-minutes'] * 100) / 100 || 0}</b>
				}
			},
			{
				key: 'lineups',
				title: <Popover content={t('statsPage.lineupsTitle')} trigger="click">
					<span style={popoverStyle} >
						{t('statsPage.lineupsColumn')}
					</span>
				</Popover>,
				dataIndex: 'lineups',
				width: '10%',
				render: (text: string, record: any) => {
					return <b>{record.lineups}</b>
				}
			},
			{
				key: 'yellow-cards',
				title: <Popover content={t('statsPage.yellowCardsTitle')} trigger="click">
					<span style={popoverStyle} >
						{t('statsPage.yellowCardsColumn')}
					</span>
				</Popover>,
				dataIndex: 'yellow-cards',
				width: '10%',
				render: (text: string, record: any) => {
					return <b>{record['yellow-cards']}</b>
				}
			},
			{
				key: 'red-cards',
				title: <Popover content={t('statsPage.redCardsTitle')} trigger="click">
					<span style={popoverStyle} >
						{t('statsPage.redCardsColumn')}
					</span>
				</Popover>,
				dataIndex: 'red-cards',
				width: '10%',
				render: (text: string, record: any) => {
					return <b>{+record['red-cards'] + +record['2nd-yellow-cards'] || 0}</b>
				}
			},
			{
				key: 'subs-in',
				title: <Popover content={t('statsPage.subsInTitle')} trigger="click">
					<span style={popoverStyle} >
						{t('statsPage.subsInColumn')}
					</span>
				</Popover>,
				dataIndex: 'subs-in',
				width: '10%',
				render: (text: string, record: any) => {
					return <b>{record['subs-in']}</b>
				}
			}
		]
		return (
			<React.Fragment>
				{(navigateToMatch) && <Redirect
					to={{ pathname: `/match/${navigateToMatch}` }}
				/>}
				<Row style={{backgroundColor: "#FFF"}}>
					<Col md={24} sm={24} xs={24}>
						<Block>
							<Row>
								<Title type={TitleType.h2}>{t('statsPage.playersGeneralStatsTitle')}</Title>
								<PlayerStatsList
									clubs={this.props.clubs.data}
									isLoading={this.props.players.isFetching}
									hidePositions={false}
									application={this.props.application}
									assetsCdn={this.props.application.competition.assetsCdn}
									playerType={PlayerType.SoccerShirt}
									matches={matches}
									onSelect={this.setPlayer}
									action
									t={t}
									showHeader={true}
									size={10}
									user={this.props.user}
								/>
							</Row>
						</Block>
					</Col>

					<Col md={24} sm={24} xs={24}>
						{
							(selectedPlayer &&
								<Block>
									<Element name="player-stats">
										<Title type={TitleType.h2}>{selectedPlayer.name}</Title>

										<Row>
											<Tabs defaultActiveKey="1">
												<TabPane tab={t('statsPage.currentSeason')} key="1">
													<BasicTableStyle
														columns={columns}
														dataSource={selectedPlayerAggregatedStats}
														showHeader={true}
														locale={{ emptyText: t('statsPage.noPlayerStatsAvailable') }}
														loading={false}
														pagination={false}
														onRow={(record: any) => ({
															onClick: (event: any) => {
																this.setState({ navigateToMatch: record.matchId });
															},
														})}
														rowKey={(record: any, index: number) => `record-${index + 1}`}
														rowClassName={(record: object, index: number) => index % 2 ? 'cursor-pointer ant-table-row--odd' : 'cursor-pointer ant-table-row--even'}
													/>												
												</TabPane>
												{/* <TabPane tab={t('statsPage.career')} key="2">
													{this.props.user.data.role != 'premium' ?
													<Link to='/shop'>{t('shop.premiumMessage')}</Link> :
													<BasicTableStyle
														columns={careerColumns}
														dataSource={selectedPlayer.generalInfo.career}
														showHeader={true}
														locale={{ emptyText: t('statsPage.noPlayerStatsAvailable') }}
														loading={false}
														pagination={false}
														rowKey={(record: any, index: number) => `record-${index + 1}`}
														rowClassName={(record: object, index: number) => index % 2 ? 'cursor-pointer ant-table-row--odd' : 'cursor-pointer ant-table-row--even'}
													/>
													}
												</TabPane> */}
											</Tabs>
										</Row>
									</Element>
								</Block>) || null
						}
					</Col>
				</Row>
			</React.Fragment>
		);
	}
}

export function mapDispatchToProps(dispatch: Dispatch<userActions.UserAction>) {
	return {}
}

export function mapStateToProps({ application, players, clubs, matches, user }: StoreState.All) {
	return {
		application,
		players,
		matches,
		clubs,
		user
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Stats);