import styled from "styled-components";
import { Table } from "antd";

import { mediaQueries } from "../../styles/media-queries";
import { theme } from './../../styles/theme-playsports';


import "antd/lib/table/style";

const ContainerStyle = styled.div`
	
`;

const SelectGroupStyle = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 10px;

	> div {
		margin: 5px 0;
	}

	@media ${mediaQueries.tablet} {
		flex-wrap: nowrap;
		justify-content: space-between;

		> div {
			margin: 0 5px;
		}
	}
`;

const tablePagination = `
.ant-pagination {
	width: 100%;
	text-align: center;

	.ant-pagination-prev, .ant-pagination-next {
		a {
			border-radius: 0px;
		}

		&:hover:not(.ant-pagination-disabled) a {
			background-color: ${theme.primaryColor};
			border-color: ${theme.primaryColor};
			color: white;
		}
	}

	.ant-pagination-item {
		border-radius: 0px;

		&:hover:not(.ant-pagination-disabled), &.ant-pagination-item-active {
			background-color: ${theme.primaryColor};
			border-color: ${theme.primaryColor};

			a {
				font-weight: bold;
				color: #f2f2f2;
			}
		}
	}

	.ant-pagination-disabled {
		a {
			border-color: ${theme.primaryContrast};
			background: ${theme.primaryContrast};
			font-weight: bold;
			color: #ababab;
		}
	}
}
`;

const TableStyle = styled(Table)`
	.ant-table {
		color: #000;
	}

	.ant-table-thead {
		th {
			padding-top: 10px;
			padding-bottom: 10px;
			background: ${props => props.theme.primaryColor};
		}
	}

	.avatar-container {
		margin-bottom: -10px;
	}

	.ant-table-tbody {
		.ant-table-row {
			> td {
				border: none;
				padding: 7.5px;

				&:first-child {
					padding: 2.5px 10px 0 15px;
				}
			}

			&--odd {
				background-color: #f2f2f2;
			}
		}

		.cursor-pointer {
			cursor: pointer;
		}
	}

	${tablePagination}	
` as any;

const BasicTableStyle = styled(Table)`
	.ant-table {
		color: #000;
	}

	.ant-table-content {
		overflow-x: scroll;
	}

	.ant-table-thead {
		th {
			padding-top: 10px;
			padding-bottom: 10px;
			background: ${props => props.theme.primaryColor};
			color: #FFF;
			padding: 7.5px;
			&:last-child {
				text-align: right;
			}
		}

		> tr { 
			&:first-child, :last-child { 
				> th {
					border: 0;
					&:first-child, :last-child {
						border-radius: 0;
					}
				}
			}
		}
	}

	.ant-table-tbody {
		.ant-table-row {
			> td {
				border: none;
				padding: 7.5px;
				&:last-child {
					text-align: right;
				}
			}

			&--odd {
				background-color: #f2f2f2;
			}
		}

		.cursor-pointer {
			cursor: pointer;
		}
	}

	${tablePagination}
` as any;

const PlayerStyle = styled.div`
	${(props: any) =>
		props.type === "desktop" &&
		`
		display: none;
	`}
	
	@media ${mediaQueries.mobileL} {
		${(props: any) =>
			props.type === "desktop" &&
			`
			display: block;
		`}
	}

	.name {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 150px;
	}

	.mobile-name {
		white-space: nowrap;
    	overflow: hidden;
    	text-overflow: ellipsis;
    	max-width: 120px;
	}

	.player-position {
		display: block;
	}

	${(props: any) =>
		props.type === "mobile" &&
		`
		display: block;
	`}

	@media ${mediaQueries.mobileL} {
		${(props: any) =>
			props.type === "mobile" &&
			`
			display: none;
		`}
	}

	p {
		font-size: 0.9em;
		margin-bottom: 0;

		&:first-child {
			font-weight: bold;
		}

		&:last-child {
			span {
				color: ${(props: any) => props.clubColor};

				${(props: any) =>
					props.position === "gk" &&
					`
					color: ${props.theme.positionGk};
				`}

				${(props: any) =>
					props.position === "df" &&
					`
					color: ${props.theme.positionDf};
				`}

				${(props: any) =>
					props.position === "mf" &&
					`
					color: ${props.theme.positionMf};
				`}

				${(props: any) =>
					props.position === "fw" &&
					`
					color: ${props.theme.positionFw};
				`}
			}
		}

	}
` as any;

export {
	ContainerStyle,
	SelectGroupStyle,
	PlayerStyle,
	TableStyle,
	BasicTableStyle
};
