import React, { Component } from "react";
import { connect } from 'react-redux';
import { pick } from 'lodash';
import { StoreState, ClubsState, ApplicationState, UserState } from '../../types';
import {
	Team, Substitutes, PlayerType, Row, Col, Block, Spinner, Title, TitleType
} from '../../components';
import { theme } from '../../styles/theme-playsports';

import teamBackground from './../../assets/img/bg-hockey.png';
import { startingListToPositionsList } from '../../lib/helpers'
import { MatchStyles } from './MatchStyles';

import * as matchActions from '../../actions/matches'
import { cpuUsage } from "process";

const playerProps =
	['id', 'name', 'short', 'positionId', 'clubId', 'injury', 'form', 'forename', 'surname', 'points', 'pointsOverview', 'stats'];

export interface Props {
	match: any;
	clubs: ClubsState;
	user: UserState;
	application: ApplicationState;
	t: any;
}

export interface State {
	isFetching: boolean;
	match: any;
}

class MatchContainer extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			isFetching: false,
			match: { home: { players: [] }, away: { players: [] }, match: {} }
		};
	};

	componentDidMount() {
		const matchId = this.props.match.params.id;
		this.getMatchInfo(matchId)
	}

	getMatchInfo = (matchId: number) => {
		this.setState({ isFetching: true });
		matchActions.getMatchStats(matchId)
			.then(match => {
				this.setState({ isFetching: false, match });
			})
			.catch(error => {
				this.setState({ isFetching: false });
			})
	};

	formatPlayers = (players: any, fromTheStart: boolean) => {
		return players
			.filter((player: any) => {
				const firstStat = player && player.stats && player.stats[0];
				return firstStat
					&& firstStat.value
					&& (fromTheStart ? firstStat.value.in_starting : !firstStat.value.in_starting);
			})
			.map((player: any) => {
				const firstStat = player && player.stats && player.stats[0];
				return pick({ ...player, ...{ points: firstStat.points, pointsOverview: (firstStat && firstStat.value) || {} } }, playerProps)
			});
	};

	playersPoints = (players: any) => {
		return players
			.map((player: any) => player.stats.reduce((acc: number, stat: any) => acc + stat.points, 0))
			.reduce((acc: number, points: number) => acc + points, 0);
	};

	render() {
		const { application } = this.props;
		const { match, isFetching } = this.state;


		const homeStartingPlayers = this.formatPlayers(match.home.players, true);
		const homeBenchPlayers = this.formatPlayers(match.home.players, false);
		const awayStartingPlayers = this.formatPlayers(match.away.players, true);
		const awayBenchPlayers = this.formatPlayers(match.away.players, false);

		const homeTotalPoints = this.playersPoints(match.home.players);
		const awayTotalPoints = this.playersPoints(match.away.players);

		const homeStartingByPositions = startingListToPositionsList(homeStartingPlayers, application.competition.lineupPositionRows, 'football');
		const awayStartingByPositions = startingListToPositionsList(awayStartingPlayers, application.competition.lineupPositionRows, 'football');

		return <React.Fragment>
			{
				isFetching ?
					<Spinner /> : null
			}
			{
				!isFetching ?
					<MatchStyles>
						<Row>
							<Col md={12} sm={24} xs={24} className="left">
								<Block style={{backgroundColor: "#FFF"}}>
									<Title type={TitleType.h2}>
										{`${match.home.name} ${match.match.homeScore}`}
									</Title>
									<span style={{ display: 'block', fontSize: '12px', textAlign: 'center' }}>{homeTotalPoints} ptn </span>
									<Team
										widthRatio={8}
										heightRatio={7}
										clubs={this.props.clubs.data}
										bg={teamBackground}
										assetsCdn={application.competition.assetsCdn}
										showPlayerStatsPoints={true}
										selection={homeStartingByPositions}
										centerAligned={true}
										playerType={PlayerType.SoccerShirt}
										playerBadgeColor="#000"
										modalEnabled={true}
										captainId={undefined}
										playerBadgeBgColor={theme.primaryColor}
										playerPointsColor={"#000"}
										playerPointsBgColor="#555555"
										t={this.props.t} />
									<Substitutes
										selection={homeBenchPlayers}
										clubs={this.props.clubs.data}
										title="De bank"
										bgColor="#F4F8FF"
										playerType={PlayerType.SoccerShirt}
										modalEnabled={true}
										assetsCdn={application.competition.assetsCdn}
										playerBadgeColor="#000"
										playerBadgeBgColor={theme.primaryColor}
										playerPointsColor={"#000"}
										playerPointsBgColor="#fff"
										t={this.props.t}
									/>
								</Block>
							</Col>
							<Col md={12} sm={24} xs={24} className="right">
								<Block style={{backgroundColor: "#FFF"}}>
									<Title type={TitleType.h2}>
										{`${match.away.name} ${match.match.awayScore}`}
									</Title>
									<span style={{ display: 'block', fontSize: '12px', textAlign: 'center' }}>{awayTotalPoints} ptn </span>

									<Team
										widthRatio={8}
										heightRatio={7}
										clubs={this.props.clubs.data}
										bg={teamBackground}
										showPlayerStatsPoints={true}
										centerAligned={true}
										assetsCdn={application.competition.assetsCdn}
										selection={awayStartingByPositions}
										playerType={PlayerType.SoccerShirt}
										playerBadgeColor={theme.primaryColor}
										modalEnabled={true}
										captainId={undefined}
										playerBadgeBgColor={theme.primaryColor}
										playerPointsColor={"#000"}
										playerPointsBgColor="#555555"
										t={this.props.t} />
									<Substitutes
										selection={awayBenchPlayers}
										clubs={this.props.clubs.data}
										title="De bank"
										bgColor="#F4F8FF"
										playerType={PlayerType.SoccerShirt}
										modalEnabled={true}
										assetsCdn={application.competition.assetsCdn}
										playerBadgeColor={theme.primaryColor}
										playerBadgeBgColor={theme.primaryColor}
										playerPointsColor={"#000"}
										playerPointsBgColor="#fff"
										t={this.props.t}
									/>
								</Block>
							</Col>
						</Row>
					</MatchStyles >
					: null
			}
		</React.Fragment>;
	}
}

export function mapDispatchToProps() {
	return {}
}

export function mapStateToProps({ application, clubs, user }: StoreState.All) {
	return {
		clubs,
		application,
		user
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MatchContainer);