import React, { useState, useEffect } from 'react';
import { useSubscription, useMutation, gql } from "@apollo/client";

import { Comment } from './../../components/UI/Comment/Comment';
import { Button } from './../../components/UI/Button/Button';
import { List, ListItem } from './../../components/UI/List/List';

import { Avatar, Icon, Tooltip, Input, Form } from 'antd';
import ScrollableFeed from 'react-scrollable-feed'

const { TextArea } = Input;

const COMMENTS = gql`
subscription Comments($leagueId: Int) {
    comments(where: {type: {_eq: "2"}, key_id: {_eq: $leagueId}}, order_by: {created_at: asc}, limit: 200) {
      comment
      created_at
      id
      team_id
      teamname
      user_id
      username
      votes_total
      votes_users
    }
  }
`;

const ADD_COMMENT = gql`
    mutation AddComment($comment: String!, $email: String, $teamname: String, $team_id: Int, $user_id: Int, $username: String, $leagueId: Int) {
        insert_comments_one(object: {comment: $comment, email: $email, type: "2", teamname: $teamname, team_id: $team_id, user_id: $user_id, username: $username, key_id: $leagueId}) {
        comment
        id
        key_id
        }
    }
`  

const CommentList = (props: any) => {
    const [inputVal, setInputVal] = useState("");

    const [addComment] = useMutation(ADD_COMMENT);

    const { loading, error, data } = useSubscription(COMMENTS, {
      variables: {leagueId: props.league.league.id},
    });
    if (loading) return <p>Loading ...</p>;
    if (error) return <p>Error :(</p>;

    const getUserAvatarByTeamId = (teamId: any) => { 
        let team = props.league.teams.find((team: any) => { return team.id === teamId });

        if(team) {
            return team.user.avatar
        }
    }

    return (
      <div>
        <List style={{overflowY: "scroll", maxHeight: "450px" }}>
            <ScrollableFeed>
            {data.comments.map((comment: any) => (
                
                <ListItem key={comment.id} style={{ margin: "20px auto"}}>
                    <div style={{marginLeft: "60px", color: "rgba(255, 255, 255, .5)"}}>{comment.username}</div>

                    <div style={{ display: "flex", overflow: "hidden" }}>
                        <img style={{maxHeight: "35px",maxWidth: "35px",borderRadius: "50%", display: "inline-block"}}
                            src={getUserAvatarByTeamId(comment.team_id)} />
                    
                        <span style={{
                            padding: "10px 15px",
                            marginLeft: "10px",
                            color: "#FFF",
                            backgroundColor: "#373737",
                            borderRadius: "20px"
                        }}>{comment.comment}</span>
                    </div>
                </ListItem>
            ))}
            </ScrollableFeed>
        </List>

        <div style={{marginBottom: "20px"}}>
            <Input style={{margin: "20px auto"}} value={inputVal} onChange={(e) => setInputVal(e.target.value)} placeholder="Type a message..." />
            <Button type="primary" onClick={() => {
                if(inputVal.trim() != '') {
                    addComment({ variables: { comment: inputVal, email: props.user.email, teamname: props.team.name, team_id: props.team.id, user_id: props.user.id, username: props.user.firstName, leagueId: props.league.league.id } })
                        .then(() => setInputVal(""))
                        .catch((e) => {
                            setInputVal(e.message);
                        });
                }
            }}>Submit</Button>
        </div>
      </div>
    );
  };

export default CommentList;