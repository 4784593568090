import React, { Component } from 'react';
import { ContainerStyle, SelectGroupStyle, TableStyle } from './../PlayerList/PlayerListStyle';
import Select from '../../components/UI/Select/Select';
import Link from '../UI/Link/Link';
import { Match } from './../../types';
import moment from 'moment';

export interface CalendarProps {
	data: any[];
	size: number;
	isLoading?: boolean;
	showMonthsFilter?: boolean;
	showHeader?: boolean;
}

export interface CalendarState {
}

class Calendar extends Component<CalendarProps, CalendarState> {
	state = {
		filters: {
			month: -1
		}
	};

	onFilterChange = (name: string, value: string | number) => {
		const filters: any = Object.assign({}, this.state.filters, { [name]: value });
		this.setState({ filters })
	};

	matchFilter = (match: Match, filters: any) => {
		let show = true;
		if (filters.month !== -1 && filters.month !== moment(match.date).month()) {
			show = false;
		}

		return show;
	};

	render() {
		const { data, size, showHeader, isLoading, showMonthsFilter } = this.props;
		// moment.locale('nl');

		const matchesList = data
			.filter(match => this.matchFilter(match, this.state.filters));

		const months = [
			{ id: -1, name: 'Alle maanden' },
			{ id: 0, name: 'Januari' },
			{ id: 1, name: 'Februari' },
			{ id: 2, name: 'Maart' },
			{ id: 3, name: 'April' },
			{ id: 4, name: 'Mei' },
			{ id: 5, name: 'Juni' },
			{ id: 6, name: 'Juli' },
			{ id: 7, name: 'Augustus' },
			{ id: 8, name: 'September' },
			{ id: 9, name: 'Oktober' },
			{ id: 10, name: 'November' },
			{ id: 11, name: 'December' },
		];

		const columns = [
			{
				title: 'Week',
				key: 'weekId',
				dataIndex: 'weekId',
				width: '10%',
				render: (text: string, record: any) => {
					return <span>{text}</span>;
				}
			},
			{
				title: 'Race',
				key: 'feedUrl',
				dataIndex: 'feedUrl',
				width: '50%',
				render: (text: string, record: any) => {
					return <Link href={record.info} type={'external'}>{text}</Link>;
				}
			}, {
				title: 'Deadline',
				key: 'date',
				width: '20%',
				dataIndex: 'date',
				render: (text: string, record: any) => {
					return <b>{moment(text).format('ddd DD/MM [om] HH:mm')}</b>
				}
			}, {
				title: 'Type',
				key: 'stats',
				width: '20%',
				dataIndex: 'stats',
				render: (text: string, record: any) => {
					const channels = (record.channels && JSON.parse(record.channels)) || [];
					let type = '';
					if (record.type === 'flat') {
						type = 'Vlak';
					} else if (record.type === 'hills') {
						type = 'Heuvel';
					} else if (record.type === 'mountains') {
						type = 'Berg';
					} else if (record.type === 'time' && record.feedUrl.indexOf('TTT') !== -1) {
						type = 'Ploegentijdrit';
					} else if (record.type === 'time' && record.feedUrl.indexOf('ITT') !== -1) {
						type = 'Tijdrit';
					}
					return <span>{type}</span>
				}
			}
		];

		return (
			<ContainerStyle>
				{
					(showMonthsFilter &&
						<SelectGroupStyle>
							{(<Select
								block
								keyProperty={'id'}
								textProperty={'name'}
								onSelect={(value: any) => this.onFilterChange('month', value)}
								values={months}
								placeholder={months[0].name}
							/>) || null}
						</SelectGroupStyle>
					) || null
				}
				<TableStyle
					columns={columns}
					dataSource={matchesList}
					showHeader={showHeader}
					locale={{ emptyText: 'No matches found.' }}
					loading={isLoading}
					pagination={matchesList.length > size ? { pageSize: size } : false}
					rowKey="id"
					rowClassName={(record: object, index: number) => index % 2 ? 'ant-table-row--odd' : 'ant-table-row--even'}
				/>
			</ContainerStyle>
		);
	}
}

export default Calendar;
