import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Player, AbstractTeamType, Transfer } from './../../types';
import {
	Title, TitleType, PlayerList, Team, Substitutes, PlayerType, Row, Col, Block,
	FootballCalendar, Button, AbstractTeam, TransfersList, Alert, TransfersOverview, ConfirmationModal,
} from './../../components';
import { Icon, notification } from 'antd';

import teamBackground from './../../assets/img/bg-hockey.png';
import teamSponsor from "./../../assets/img/header/megaspelen-logo.png";
import { startingListToPositionsList, roundNextHalf, getNumberOfExtraClubPlayers, selectionPlayerSellValue } from './../../lib/helpers';
import { pick } from 'lodash';
import * as teamsActions from '../../actions/teams'
import moment from 'moment';
import { theme } from './../../styles/theme-playsports';

import { Element, scroller } from 'react-scroll'

export interface Props {
	match: any;
	t: any;
}

export interface State {
	notFound: boolean;
	performingTransfer: boolean;
	performingTransferReset: boolean;
	transferConfirmModalVisible: boolean
}

const noMarginOrPaddingStyle = { margin: 0, padding: 0 };

class TransfersContainer extends Component<AbstractTeamType & Props, State> {
	constructor(props: AbstractTeamType & Props) {
		super(props);

		this.state = {
			notFound: false,
			performingTransfer: false,
			performingTransferReset: false,
			transferConfirmModalVisible: false
		}
	};

	onPlaceHolderClick = (player: any) => {
		if (player && player.positionId) {
			this.props.setActivePositionFilter(player.positionId);
		}

		scroller.scrollTo('all-players', {
			duration: 1000,
			delay: 100,
			smooth: true,
			offset: 50
		})
	};

	componentDidMount() {
		if (this.props.visibleWeekId) {
			this.loadTeam();
		}
	}

	componentDidUpdate(prevProps: AbstractTeamType & Props) {
		if (this.props.visibleWeekId && (this.props.visibleWeekId !== prevProps.visibleWeekId)) {
			this.loadTeam();
		}
	}

	loadTeam = () => {
		const teamId = this.props.match.params.id;
		const playerProps =
			['id', 'name', 'short', 'positionId', 'clubId', 'value', 'ban', 'injury', 'form', 'forename', 'surname'];

		const selectionProps: any[] = [];

		return teamsActions.get(teamId, undefined, true)
			.then(result => {
				const starting = result.players
					.filter((player: any) => player.selection.positionId === 1)
					.map((player: any) => {
						const transferValue = selectionPlayerSellValue(player);
						return Object.assign({ inStarting: true }, pick(player, playerProps), pick(player.selection, selectionProps), { value: transferValue })
					});

				const bench = result.players
					.filter((player: any) => player.selection.positionId === 0)
					.map((player: any) => {
						const transferValue = selectionPlayerSellValue(player);
						return Object.assign({ inStarting: false }, pick(player, playerProps), pick(player.selection, selectionProps), { value: transferValue })
					});

				const teamName = result.team.name;

				const captainPlayer = result.players
					.find((player: any) => player.selection.captain === 1);

				const captainId = captainPlayer && captainPlayer.id;

				const pastTransfers = !result.transfers ? ([] as Transfer[]) :
					result.transfers
						.filter((transfer: any) => this.props.matches.info.deadlineWeek && (transfer.weekId < this.props.matches.info.deadlineWeek))
						.map((transfer: any) => ({ inId: transfer.inId, outId: transfer.outId, weekId: transfer.weekId }));

				const deadlineWeekTransfers = !result.transfers ? ([] as Transfer[]) :
					result.transfers
						.filter((transfer: any) => transfer.weekId === this.props.matches.info.deadlineWeek)
						.map((transfer: any) => ({ inId: transfer.inId, outId: transfer.outId, weekId: transfer.weekId, extra: transfer.extra }));


				const getPlayersValuesWithTransfers = (players: any) => {
					const playersValue = players
						.reduce((acc: any, player: any) => {
							const wasTransferred = result.transfers.find((transfer: any) => transfer.inId === player.id);

							const playerValue = wasTransferred ?
								roundNextHalf(player.value + (player.value * (this.props.application.competition.transferTaxPercentage || 0) / 100)) :
								player.value;

							return acc + playerValue;
						}, 0);
					return this.props.application.competition.budget - playersValue;
				};

				const budget = result.team.budget !== null ?
					result.team.budget : getPlayersValuesWithTransfers(result.players)
					;

				let viceCaptainId = undefined;

				const viceCaptainRecord = result.players
					.find((player: any) => player.selection.captain === 2);

				if (viceCaptainRecord) {
					viceCaptainId = viceCaptainRecord.id;
				}

				const boosters = {
					freeHit: result.team.freeHit,
					bank: result.team.bank,
					tripleCaptain: result.team.tripleCaptain,
					wildCard: result.team.wildCard
				};

				this.props.initTeamState(starting, bench, teamName, captainId, budget, undefined, undefined, undefined, result.transfers, deadlineWeekTransfers, pastTransfers, viceCaptainId, boosters);
			})
			.catch(error => {
				this.setState({ notFound: true });
			})
	};

	formatTransfers = (transfer: any) => {
		const inPlayerIdWithMeta = transfer && (
			transfer.inId && Object.keys(transfer.inId).length
		);

		const outPlayerIdWithMeta = transfer && (
			transfer.outId && Object.keys(transfer.outId).length
		);
		const formatted = {
			inPlayer: inPlayerIdWithMeta ? transfer.inId : transfer.inPlayer,
			outPlayer: outPlayerIdWithMeta ? transfer.outId : transfer.outPlayer,
			weekId: transfer && transfer.weekId,
			inId: inPlayerIdWithMeta ? transfer.inId.id : transfer.inId,
			outId: outPlayerIdWithMeta ? transfer.outId.id : transfer.outId
		};

		return formatted;
	};

	isNotExtraTransfer = (transfer: any) => {
		return !transfer.extra;
	};

	playerIsExtra = (player: Player) => {
		const teamPlayers = []
			.concat(this.props.starting as any, this.props.bench as any);
		const sameClubPlayers = teamPlayers
			.filter((teamPlayer: any) => teamPlayer && teamPlayer.clubId === player.clubId);

		return sameClubPlayers.length > this.props.application.competition.teamSameClubPlayersLimit;
	};

	onPlayerOut = (player: Player, isLineup: boolean, canTransferOutOnlyExtra: boolean, clubsWithExtraPlayers: any[]) => {
		const clubsWithExtraPlayersIds = clubsWithExtraPlayers.map((club: any) => club.id);
		if (canTransferOutOnlyExtra && !clubsWithExtraPlayersIds.includes(player.clubId)) {
			const clubNames = clubsWithExtraPlayers.map((club: any) => club.name).join(',');
			notification.warning({ message: `Your team is invalid until you transfer out a player from ${clubNames}.` });
			return;
		}

		if (isLineup) {
			this.props.removeStartingPlayer(player);
		} else {
			this.props.removeBenchPlayer(player);
		}
		const playerIsExtra = this.playerIsExtra(player);
		this.props.onTransferPlayerOut(player, false);
	};

	getClubsWithExtraPlayers = () => {
		const teamPlayers = []
			.concat(this.props.starting as any, this.props.bench as any);

		return this.props.clubs.data
			.filter((club: any) => {
				const players = teamPlayers.filter((teamPlayer: any) => teamPlayer && teamPlayer.clubId === club.id);
				return players.length > this.props.application.competition.teamSameClubPlayersLimit;
			});
	};

	onPlayerIn = (player: Player) => {
		this.props.pickPlayer(player, false);
		this.props.onTransferPlayerIn(player);
	};

	submitTransfers = (teamId: number, clubsWithExtraPlayers: any[]) => {
		if (clubsWithExtraPlayers.length) {
			const clubNames = clubsWithExtraPlayers.map((club: any) => club.name).join(',');
			notification.warning({ message: `Your team is invalid until you transfer out a player from ${clubNames}.` });
			return;
		}

		this.setState({ performingTransfer: true });
		this.props.onTransfersSubmit(teamId)
			.then(() => this.loadTeam())
			.then(() => {
				this.setState({ performingTransfer: false });
			})
	};

	resetTransfers = (teamId: number) => {
		this.setState({ performingTransferReset: true });
		this.props.onTransfersReset(teamId)
			.then(() => this.loadTeam())
			.then(() => {
				this.setState({ performingTransferReset: false });
			});
	};

	onTransferConfirmCancel = () => {
		this.setState({ transferConfirmModalVisible: false });
	};

	onTransferConfirmed() {
		const teamId = this.props.match.params.id;
		this.submitTransfers(teamId, [])
		this.setState({ transferConfirmModalVisible: false });
	}

	showTransferConfirmationModal() {
		this.setState({ transferConfirmModalVisible: true });
	}

	render() {
		const {
			application, starting, bench, budget, matches, user, initializedExternally, deadlineWeekTransfers,
			pastTransfers, draftTransfers, players, captainId, t, viceCaptainId, onViceCaptainSelect, onCaptainSelect,
			activePositionFilter, boosters
		} = this.props;
		const { transferConfirmModalVisible } = this.state;
		const allTeamPlayers = [].concat(starting as any, bench as any);
		const startingByPositions = startingListToPositionsList([].concat(starting as any, bench as any), [2, 5, 4, 4], 'football');
		const extraPlayersFromSameClub = 0;//getNumberOfExtraClubPlayers(allTeamPlayers, application.competition.teamSameClubPlayersLimit);
		const resetTransfersEnabled = false;
		const totalPlayersToPick = application.competition.lineupSize + application.competition.benchSize;
		const startingPicked = starting
			.filter(player => player && player.id);
		const benchPicked = bench
			.filter(player => player && player.id);
		const totalPlayersPicked = startingPicked.length + benchPicked.length;
		const teamId = parseInt(this.props.match.params.id);

		const team = this.props.user &&
			this.props.user.teams &&
			this.props.user.teams.find((team: any, index: number) => team.id === teamId);

		const firstPlayingWeekPassed = team && team.id && matches && matches.info && matches.info.deadlineWeek
			&& (matches.info.deadlineWeek > team.weekId);

		const notTeamOwner = team && team.userId && user.data && (team.userId !== user.data.id);

		const gameOfficiallyStarted = matches.info.deadlineWeek && matches.info.deadlineWeek > application.competition.officialStartWeek;

		const pastTransfersFormatted = pastTransfers
			.map(this.formatTransfers);


		const deadlineWeekTransfersFormatted = deadlineWeekTransfers
			.concat(draftTransfers)
			.map(this.formatTransfers);

		const deadlineWeekTransfersFormattedWithoutExtra = deadlineWeekTransfers
			.concat(draftTransfers)
			.filter(this.isNotExtraTransfer)
			.map(this.formatTransfers);

		const canSaveDraftTransfers = draftTransfers
			.filter(draftTransfer => !!draftTransfer.inId && !!draftTransfer.outId)
			.length === draftTransfers.length;

		let remainingTransfers = null;

		if (application.competition.weeklyTransfers) {
			remainingTransfers = (extraPlayersFromSameClub + application.competition.transfersAllowed) - (deadlineWeekTransfersFormattedWithoutExtra.length);
		} else {
			remainingTransfers = (extraPlayersFromSameClub + application.competition.transfersAllowed) - (deadlineWeekTransfersFormattedWithoutExtra.length + pastTransfersFormatted.length);
		}

		const canTransferOut = (remainingTransfers > 0) &&
			(deadlineWeekTransfersFormattedWithoutExtra.length < (extraPlayersFromSameClub + application.competition.transfersAllowed));

		const canTransferOutOnlyExtra = remainingTransfers === extraPlayersFromSameClub;
		const clubsWithExtraPlayers = this.getClubsWithExtraPlayers();

		const draftPlayerInIds = draftTransfers
			.map(transfer => transfer.inId)
			.filter(inId => inId !== null);

		let minusPoints = 0;

		const deadlineDate = this.props.matches && this.props.matches.info && this.props.matches.info.deadlineDate;
		const deadlineWeek = (this.props.matches && this.props.matches.info && this.props.matches.info.deadlineWeek) || 0;
		const deadlineEpochMilliseconds = deadlineDate ? moment(deadlineDate).valueOf() : 0;
		const wildCardOrFreeHitEnabled = boosters.wildCard === deadlineWeek || boosters.freeHit === deadlineWeek;

		const previousWeekTransfers = pastTransfers
			.filter((transfer: any) => {
				return transfer.weekId === (deadlineWeek - 1)
			})
			.length;

		if (!application.competition.transfersPerWeekLimit && (deadlineWeek && (deadlineWeek > application.competition.officialStartWeek)) && (team && deadlineWeek && (deadlineWeek-1 > team.weekId )) && !previousWeekTransfers) {
			remainingTransfers += 1;
		}

		if (!application.competition.transfersPerWeekLimit && remainingTransfers < 0) {
			minusPoints = remainingTransfers * -4;
		}

		return (
			<React.Fragment>
				{/* {(notTeamOwner || this.state.notFound) && <Redirect
					to={{ pathname: `/home` }}
				/>} */}
				{team && matches.info.deadlineWeek && (!gameOfficiallyStarted || wildCardOrFreeHitEnabled) && <Redirect
					to={{ pathname: `/edit/${team.id}` }}
				/>}
									<Row>
						<Col lg={24} md={24} sm={24} xs={24}>
							<img src="https://fa-scorrd.s3-eu-west-1.amazonaws.com/files/duvel.png" style={{
						width: '100%'
					}} />
						</Col>
					</Row>
				{
					(initializedExternally && <Row>
						<Col md={12} sm={12} xs={24}>
							<Block>
								<Title type={TitleType.h2}>{t('transfersPage.transfersBlockTitle')}</Title>
								<React.Fragment>
									<div style={{ textAlign: 'center' }}>
										<Title type={TitleType.h4}>{`${t('general.footballWeek')} ${matches.info.deadlineWeek} ${t('transfersPage.transfersLabel')}`}</Title>
									</div>

									<TransfersList
										data={deadlineWeekTransfersFormatted}
										showHeader={true}
										t={t}
										tax={application.competition.transferTaxPercentage}
										size={15} />

									<div style={{ textAlign: 'center', paddingTop: '5px' }}>
										{
											(draftTransfers && draftTransfers.length && canSaveDraftTransfers && team &&
												<span style={{ padding: '5px' }}>
													<Button
														onClick={(e: any) => this.showTransferConfirmationModal()}
														disabled={this.state.performingTransfer}
														type="primary"
														loading={this.state.performingTransfer}
														size="default">
														{
															(!this.state.performingTransfer &&
																<Icon type="save" theme="filled" />
															) || null
														}
														{t('transfersPage.confirmTransfers')}
													</Button>
												</span>) || null
										}

										{
											(draftTransfers && draftTransfers.length &&
												<span style={{ padding: '5px' }}>
													<Button
														onClick={(e: any) => this.props.onDraftTransfersClear()}
														type="primary"
														size="default">
														<Icon type="close-circle" theme="filled" />
														{t('transfersPage.clearTransfers')}
													</Button>
												</span>) || null
										}

										{
											(resetTransfersEnabled && deadlineWeekTransfers && deadlineWeekTransfers.length && team &&
												<span style={{ padding: '5px' }}>
													<Button
														disabled={this.state.performingTransferReset}
														loading={this.state.performingTransferReset}
														type="primary"
														onClick={(e: any) => this.resetTransfers(team.id)}
														size="default">
														{
															(!this.state.performingTransferReset
																&& <Icon type="delete" theme="filled" />
															) || null
														}
														{t('transfersPage.resetTransfers')}
													</Button>
												</span>) || null
										}
									</div>
								</React.Fragment>
								<div style={{ marginBottom: '15px' }}>
									<TransfersOverview budget={budget}
										totalPlayers={totalPlayersToPick}
										remainingFreeTransfers={remainingTransfers}
										minusPoints={minusPoints}
										t={t}
										totalPlayersSelected={totalPlayersPicked} />
								</div>
							</Block>

							<Block>
								<Title type={TitleType.h2}>{t('general.footballLineup')}</Title>

								<Team widthRatio={8}
									heightRatio={7}
									clubs={this.props.clubs.data}
									bg={teamBackground}
									captainId={captainId}
									selection={startingByPositions}
									assetsCdn={application.competition.assetsCdn}
									playerType={PlayerType.SoccerShirt}
									playerBadgeColor={theme.primaryColor}
									playerBadgeBgColor={theme.primaryColor}
									modalEnabled={true}
									showCaptainBadge={true}
									showPlayerValue={true}
									showPlayerValueInsteadOfPoints={true}
									onRemove={canTransferOut && ((player: any) => this.onPlayerOut(player, true, false, []))}
									onPlaceholderClick={this.onPlaceHolderClick}
									actionLessPlayerIds={draftPlayerInIds}
									playerPointsColor="#000"
									playerPointsBgColor="#555555"
									t={this.props.t} />
							</Block>
						</Col>
						<Col md={12} sm={12} xs={24}>
							<Block>
								<Title type={TitleType.h2}>{t('general.footballAllPlayers')}</Title>

								<Element name="all-players">
									<PlayerList
										clubs={this.props.clubs.data}
										matches={matches.data}
										deadlineWeek={deadlineWeek}
										isLoading={this.props.players.isFetching}
										hidePositions={false}
										assetsCdn={application.competition.assetsCdn}
										activePositionFilter={activePositionFilter}
										isPickAble={(player: Player) => this.props.isPickAble(player, false, true)}
										playerType={PlayerType.SoccerShirt}
										actionLabel={t('transfersPage.transferButtonLabel')}
										data={players.data}
										playerTax={application.competition.transferTaxPercentage}
										onPick={this.onPlayerIn}
										action
										showHeader={false}
										t={this.props.t}
										size={10}
									/>
								</Element>
							</Block>

							{
								matches.data && matches.data.length && deadlineWeek ? <Block style={{marginTop: '10px'}}>
									<Title type={TitleType.h2}>{t('general.footballCalendar')}</Title>
									<FootballCalendar
										data={matches.data}
										size={30}
										t={t}
										weekId={deadlineWeek}
										showHeader={false}
										assetsCdn={application.competition.assetsCdn}
										isLoading={matches.isFetching}
										application={this.props.application} />
								</Block> : null
							}
						</Col>
					</Row>) || null
				}
				<ConfirmationModal
					visible={transferConfirmModalVisible}
					t={t}
					onCancel={(e: any) => this.onTransferConfirmCancel()}
					onConfirm={(e: any) => this.onTransferConfirmed()}
					title={t('transfersPage.transferConfirmationTitle')}
					text={t('transfersPage.transferConfirmationMessage')} />
			</React.Fragment>
		);
	}
}

const WrappedComponent = AbstractTeam(TransfersContainer, { type: 'football' });


export default WrappedComponent;
