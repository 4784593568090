import React, { Component } from "react";
import { connect } from 'react-redux';
import { Icon } from 'antd';
import { Layout } from "./../../components";
import Link from '../UI/Link/Link';

import { StoreState, UserState } from './../../types';

import { FooterStyle } from "./FooterStyle";

type FooterProps = {
	user: UserState
}

class Footer extends Component<FooterProps> {
	state = {
		showChat: false
	};

	openChat = (e: any) => {
		e.preventDefault();

		if ((window as any).$crisp && (window as any).$crisp.is("chat:closed")) {
			(window as any).$crisp.push(['do', 'chat:open']);
		}

	};

	render() {

		return (
			<FooterStyle>
				<ul>
					<li><Link to={'/privacy'}> <Icon type="book" /> Privacy policy</Link></li>
					<li><Link to={`/cookie`}> <Icon type="book" /> Cookie policy</Link></li>
					<li><Link to={`/contact`} onClick={this.openChat}> <Icon type="wechat" /> Contact</Link></li>
				</ul>
				<ul>
					<li>&copy;2020 SneakersGames NV<p style={{fontSize: '10px'}}>BE 0640.875.931 - Hemelrijkstraat 27, 9051 Gent</p></li>
				</ul>
			</FooterStyle>
		);
	}
}
export const mapDispatchToProps = {
};

export function mapStateToProps({ user }: StoreState.All) {
	return {
		user,
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
