import styled from "../../styles/styled-components";
import { mediaQueries } from "../../styles/media-queries";

const PrivacyStyles = styled.div`
    .rules {
        padding: 40px;

        h2 {
            font-size: 24px;
            color: rgba(255,255,255,0.6);
        }
    
        h3 {
            font-size: 21px;
            color: rgba(255,255,255,0.6);
        }
    
        h4, h5 {
            font-size: 18px;
            color: rgba(255,255,255,0.6);
        }
    }
`;

export {
	PrivacyStyles
};