import styled from "styled-components";
import {Button} from 'antd';

import 'antd/lib/button/style';

export const ButtonStyle = styled(Button)`
	border: none !important;
	border-radius: 0px;

	&.ant-btn-primary {
		color: #FFF;
		background-color: red;
	}

    &.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
		padding-left: 40px;
		
		&.ant-btn-sm {
			padding-left: 21.5px;

			span {
				margin-left: 5px;
			}
		}
    }

    &.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon {
        margin-left: 0;
    }
`;