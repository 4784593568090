import { PLAYERS_LOADING_STARTED, PLAYERS_FETCH_FAILED, PLAYERS_FETCHED_WITH_SUCCESS } from './actions-types';
import { Player } from './../types'
import http from './../lib/http';
import { Dispatch } from "redux";
import { players } from '../cache';

export interface LoadPlayers {
	type: PLAYERS_LOADING_STARTED
}

export interface PlayersFetchedWithSuccess {
	type: PLAYERS_FETCHED_WITH_SUCCESS;
	data: Player[];
}

export interface PlayersFetchFailed {
	type: PLAYERS_FETCH_FAILED;
}

export type PlayersAction = LoadPlayers | PlayersFetchedWithSuccess | PlayersFetchFailed;

export function loadPlayers(): LoadPlayers {
	return {
		type: PLAYERS_LOADING_STARTED
	}
}

export function playersFetchedWithSucces(data: any[]): PlayersFetchedWithSuccess {
	return {
		type: PLAYERS_FETCHED_WITH_SUCCESS,
		data
	}
}

export function playersFetchFailed(): PlayersFetchFailed {
	return {
		type: PLAYERS_FETCH_FAILED
	}
}

export function fetchPlayers(competitionFeed: string, seasonId: number) {
	return function (dispatch: Dispatch<PlayersAction>) {
		dispatch(loadPlayers());
		//dispatch(playersFetchedWithSucces(players));

		return http.get(`players`, { competitionFeed, seasonId })
			.then((result: any) => {
				dispatch(playersFetchedWithSucces(result.players));
			})
			.catch((error: any) => {
				dispatch(playersFetchFailed());
			});
	};
}

export function getPlayerDetails(competitionFeed: string, seasonId: number, playerId: number) {
	return http.get(`player/${playerId}`, { competitionFeed, seasonId, withAggregatedWeekStats: 1 });
}

/*

competitionFeed: vm.competitionInfo.competitionFeed,
        seasonId: vm.competitionInfo.seasonId,
        pageNumber: 1,
        pageRecords: 10

function fetchPlayers() {
      const payload = Object.assign({}, vm.filters);

      payload.sorters = vm.filterColumns
        .map(sortColumn => {
          let column = sortColumn;
          let direction = 'ASC'
          if (sortColumn.indexOf('-') !== -1) {
            column = sortColumn.split('-')[1];
            direction = 'DESC';
          }
          return `${column} ${direction}`;
        })
        .join(',');

      if (vm.searchPlayer.value.length) {
        payload.name = vm.searchPlayer.value;
      }

      if (vm.currentDisplayWeek.id) {
        payload.weekId = vm.currentDisplayWeek.id;
      }

      if (vm.filterItem.club.Club.id) {
        payload.clubId = vm.filterItem.club.Club.id;
      }

      if (vm.filterItem.selectedPosition.id > 0) {
        payload.positionId = vm.filterItem.selectedPosition.id;
      }

      payload.playerValue = vm.filterItem.budget.budget;

      return playerService.stats(payload)
        .then(function (result) {
          vm.players = result.data;
          vm.totalRecords = result.totalRecords;
        })
    }
*/


export function getPlayersAggregatedStats(competitionFeed: string, seasonId: number, pageNumber: number, pageRecords: number, sorters?: string,
	name?: string, weekId?: number, clubId?: number, positionId?: number, playerValue?: number) {
	return http.get(`players-stats`, { competitionFeed, seasonId, pageNumber, pageRecords, sorters, name, weekId, clubId, positionId, playerValue });
}
