import { SET_SELECTED_COMPETITION_FEED } from './actions-types';

export interface SetSelectedCompetitionFeed {
	type: SET_SELECTED_COMPETITION_FEED;
	competitionFeed: string;
}

export type PlayersAction = SetSelectedCompetitionFeed;

export function setSelectedCompetitionFeed(competitionFeed: string): SetSelectedCompetitionFeed {
	return {
		type: SET_SELECTED_COMPETITION_FEED,
		competitionFeed
	}
}