import styled from "../../styles/styled-components";
import { mediaQueries, sizes } from "../../styles/media-queries";

const SaveLineupButton = styled.div`
	text-align: center;
	margin: 10px 0px;

	@media ${mediaQueries.tabletL} {
		text-align: right;
	}

	.ant-btn {
		width: 100%;
	}
`;


const DeadlineBar = styled.div`
	font-family: "Circular Std","Helvetica Neue",Helvetica,Arial,sans-serif;
	background-color: ${props => props.theme.primaryColor};
	text-align: center;

	p {
		color: #FFF;
		padding: 5px;

		.deadline-date {
			font-weight: bold;
		}
	}
`;


const BoostersStyle = styled.div`
	.booster {
		text-align: center;
		padding: 40px 10px;
	}

	.booster-type {
		text-align: center;
		color: rgba(255,255,255,0.6);
	}
`;

export {
	SaveLineupButton,
	DeadlineBar,
	BoostersStyle
};