import styled from "../../styles/styled-components";
import { mediaQueries } from "../../styles/media-queries";
import { H2, H1 } from "../../styles/global";

const ForgotStyles = styled.div`
	text-align: center;

	.forgot-form {
		@media ${mediaQueries.tablet} {
			padding: 0px 40px;		
		}
	}
`;

export {
	ForgotStyles,
};