import styled from "../../styles/styled-components";
import { mediaQueries } from "../../styles/media-queries";
import { H2, H1 } from "../../styles/global";

const RankingPrizesStyles = styled.div`
	.image-prizes-widget {
		width: 100%;
		max-width: 300px;
	}

	.ant-col {
		text-align: center;
	}

	&:nth-child(odd) {
		background: #E1ECFF;
	}

	.prize-row {
		padding: 20px 0 20px 0;

		.prize-body {
			padding-left: 40px!important;
    	padding-top: 15px;
			padding-right: 15px;
			
			h2 {
				text-align: left;
			}
		}
	}

	ul, p {
    text-align:left;
	}

	ul {
		display: inline-block;
	}
`;

const PrizesStyles = styled.div`
	h1 {
		background: ${props => props.theme.primaryContrast};
		padding: 5px 5px 5px 10px;
		color: #000;
	}
`;

export {
	RankingPrizesStyles,
	PrizesStyles
};