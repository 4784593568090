import React, { Component } from 'react';
import { Tooltip } from 'antd';
import { ContainerStyle, BasicTableStyle } from './../PlayerList/PlayerListStyle';

class PricingTable extends Component {
	state = {};

	render() {
		const data = [
			{feature: {title: "Custom Fantasy Team", text: ""}, free: true, premium: true},
			// {feature: {title: "Boosters", text: ""}, free: true, premium: true},
			{feature: {title: "Auto subs", text: ""}, free: true, premium: true},
			{feature: {title: "Transfers", text: ""}, free: true, premium: true},
			{feature: {title: "Create your Own Mini-League", text: "Anyone can join a mini-league but only a premium member has the power to create a competition. Each mini-league competition is provided with an in-game chat."}, free: false, premium: true},
			{feature: {title: "Multiple Team Formations", text: "Become a tactical genius through new formations 3-4-3, 4-4-2, 4-2-4, 5-4-1, 5-3-2"}, free: false, premium: true},
			{feature: {title: "Premium 👑", text: "Every Player with Premium is given a 👑 after their name. This way everyone knows that you are a fantasy King or Queen!"}, free: false, premium: true},
			// {feature: {title: "Advanced stats", text: "Op de Statspagina krijg je extra filtermogelijkheden tijdens je scouting. Denk aan: hoeveel % gekozen, historische data, pickorde bij zijn trainer, reële transferwaarde, maar ook assists, kaarten, gestopte penalty's, vorm, etc."}, free: false, premium: true},
			{feature: {title: "Power subs", text: "Everyone has 'auto subs', a handy system that puts bench-sitters in your team if a starting player didn't take action. As a Premium player you have an extra. With a Power Sub you can manually swap a bench player (who has not yet played) into your starting 11 during the gameweek, for example instead of a player who played but perhaps scored too little."}, free: false, premium: true},
			{feature: {title: "Spying on the Opposition", text: "View the teams in the general classification and in your mini-league. And not only the teams from the previous match day, you can also see the lineups for the next match day."}, free: false, premium: true},
			// {feature: {title: "Add some swag", text: "Upload je custom teamlogo om er extra uit te springen in de rangschikkingen en op je teampagina."}, free: false, premium: true},
			// {feature: {title: "Updates tijdens het seizoen", text: "We blijven werken om Fan Arena Fantasy te verbeteren. Op onze roadmap staat o.a. een draft en auction game mode."}, free: false, premium: true},
		];

		const withMetaData = data.map((item: any, index: number) =>
			Object.assign({}, item, { rank: index + 1 }));

		const columns = [{
			title: 'Feature',
			key: 'feature',
			dataIndex: 'feature',
			width: '60%',
			render: (text: string, record: any) => {
				let color = record.free ? '#000' : '#ff2e4a'
				return (
					<Tooltip title={record.feature.text}>
						<span style={{color: color}}>{record.feature.title}</span>
					</Tooltip>
				);
			},
		}, {
			title: 'Free',
			key: 'free',
			dataIndex: 'free',
			width: '20%',
			render: (option: boolean, record: any) => {
				return option ? `✅` : `❌`;
			}
		}, {
			title: 'Premium',
			key: 'premium',
			dataIndex: 'premium',
			width: '20%',
			render: (option: boolean, record: any) => {
				return option ? `✅` : `❌`;
			}
		}];

		return (
			<ContainerStyle>
				<BasicTableStyle
					columns={columns}
					rowKey="rank"
					dataSource={withMetaData}
					pagination={false}
					rowClassName={(record: object, index: number) => index % 2 ? 'ant-table-row--odd' : 'ant-table-row--even'}
				/>
			</ContainerStyle>
		);
	}
}

export default PricingTable;
