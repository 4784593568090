import React, {Component} from 'react';
import {ContainerStyle, TableStyle} from './../PlayerList/PlayerListStyle';
import {firstLetterUppercased} from './../../lib/helpers';

export interface TransfersListProps {
	data: any;
	size: number;
	isLoading?: boolean;
	showHeader?: boolean;
	showWeek?: boolean;
	tax?: number | undefined;
	t: any;
}

export interface TransfersListState {
}

class TransfersList extends Component<TransfersListProps, TransfersListState> {
	state = {};

	render() {
		const {data, size, showHeader, isLoading, showWeek, t} = this.props;
		const columns = [{
			title: '#',
			key: 'number',
			dataIndex: 'number',
			width: '20%',
			render: (text: string, record: any, index: number) => {
				return <b>{index + 1}</b>
			}
		}, {
			key: 'outPlayer',
			title: t('team.transferOut'),
			width: showWeek ? '30%' : '40%',
			dataIndex: 'outPlayer',
			render: (text: string, record: any) => {
				const playerName = (record.outPlayer && `${record.outPlayer.surname} ${record.outPlayer.forename && firstLetterUppercased(record.outPlayer.forename)}.`) || '';
				return <React.Fragment>
					<span style={{display: 'block', fontSize: '12px'}}>
						{playerName}
						</span>
				</React.Fragment>;
			}
		}, {
			key: 'inPlayer',
			title: t('team.transferIn'),
			width: showWeek ? '30%' : '40%',
			dataIndex: 'inPlayer',
			render: (text: string, record: any) => {
				const playerName = (record.inPlayer && `${record.inPlayer.surname} ${record.inPlayer.forename && firstLetterUppercased(record.inPlayer.forename)}.`) || '';

				return <React.Fragment>
					<span style={{fontSize: '12px'}}>
						{playerName}
					</span>
				</React.Fragment>;
			}
		}];

		if (showWeek) {
			columns.push({
				key: 'weekId',
				title: t('general.footballWeek'),
				width: '20%',
				dataIndex: 'weekId',
				render: (text: string, team: any) => {
					return <b>{text}</b>
				}
			});
		}

		return (
			<ContainerStyle>
				<TableStyle
					columns={columns}
					dataSource={data}
					showHeader={showHeader}
					locale={{emptyText: t('transfersPage.noTransfersTableMessage')}}
					loading={isLoading}
					pagination={data.length > size ? {pageSize: size} : false}
					rowKey={(record: any, index: number) => `record-${index + 1}`}
					rowClassName={(record: object, index: number) => index % 2 ? 'ant-table-row--odd' : 'ant-table-row--even'}
				/>
			</ContainerStyle>
		);
	}
}

export default TransfersList;
