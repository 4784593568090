import styled from "styled-components";
import { Table } from "antd";

import { mediaQueries } from "../../styles/media-queries";
import { theme } from './../../styles/theme-playsports';

import "antd/lib/table/style";

const tablePagination = `
.ant-pagination {
	width: 100%;
	text-align: center;

	.ant-pagination-prev, .ant-pagination-next {
		a {
			border-radius: 0px;
		}

		&:hover:not(.ant-pagination-disabled) a {
			background-color: ${theme.primaryColor};
			border-color: ${theme.primaryColor};
			color: white;
		}
	}

	.ant-pagination-item {
		border-radius: 0px;

		&:hover:not(.ant-pagination-disabled), &.ant-pagination-item-active {
			background-color: ${theme.primaryColor};
			border-color: ${theme.primaryColor};

			a {
				font-weight: bold;
				color: #f2f2f2;
			}
		}
	}

	.ant-pagination-disabled {
		a {
			border-color: ${theme.primaryContrast};
			background: ${theme.primaryContrast};
			font-weight: bold;
			color: #ababab;
		}
	}
}
`;

const ContainerStyle = styled.div`
`;

const BasicTableStyle = styled(Table)`
	.ant-table {
		color: #000;
	}

	.ant-table-content {
		overflow-x: scroll;
	}

	.ant-table-thead {
		th {
			padding-top: 10px;
			padding-bottom: 10px;
			background: ${props => props.theme.primaryColor};
			color: #000;
			padding: 7.5px;
			&:last-child {
				text-align: right;
			}
		}

		> tr { 
			&:first-child, :last-child { 
				> th {
					border: 0;
					&:first-child, :last-child {
						border-radius: 0;
					}
				}
			}
		}
	}

	.ant-table-tbody {
		.ant-table-row {
			background-color: #f2f2f2;
			> td {
				background-color: #f2f2f2;
				border: none;
				padding: 7.5px;
				&:last-child {
					text-align: right;
				}
			}

			&--odd, &:hover {
				background-color: #f2f2f2;
			}
		}

		.cursor-pointer {
			cursor: pointer;
		}
	}

	${tablePagination}
` as any;

export {
	ContainerStyle,
	BasicTableStyle
};