import React, { Component } from 'react';
import { Icon } from 'antd';

import { PointsStatsStyle, RowStyle, PointStyle } from './PointsStatsStyle';
import { Col, Row } from '..';

import { ConfirmedSvg, UnConfirmedSvg } from '../../styles/custom-icons';

const ConfirmedIcon = (props: any) => <Icon component={ConfirmedSvg} {...props} />;
const UnConfirmedIcon = (props: any) => <Icon component={UnConfirmedSvg} {...props} />;

export interface StatsOverviewState {
}

export interface StatsOverviewProps {
	visibleWeekPoints: number | string;
	weekWinnerPoints?: number;
	isPublic?: boolean;
	weekAveragePoints?: number;
	t: any;
};

class Stats extends Component<StatsOverviewProps, StatsOverviewState> {

	render() {
		const { t, isPublic } = this.props;

		return (
			<PointsStatsStyle>
				<Row className="overview">
					<Col lg={8} md={8} sm={8} xs={8}>
						<span className="points">{isNaN(this.props.weekAveragePoints as any) ? '-' : Math.floor(this.props.weekAveragePoints as number)}</span>
						<span className="label">{t('pointsPage.averagePoints')}</span>
					</Col>

					<Col lg={8} md={8} sm={8} xs={8}>
						<span className="points">{this.props.visibleWeekPoints}</span>
						<span className="label">{isPublic ? t('pointsPage.publicTeamPoints') : t('pointsPage.yourPoints')}</span>
					</Col>

					<Col lg={8} md={8} sm={8} xs={8}>
						<span className="points">{this.props.weekWinnerPoints}</span>
						<span className="label">{t('pointsPage.weekWinnerPoints')}</span>
					</Col>
				</Row>
			</PointsStatsStyle>
		)
	}
}

export default Stats;