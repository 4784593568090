import React, { Component } from "react";
import { Icon, notification } from 'antd';
import moment from 'moment';
import lockr from 'lockr';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { pick } from 'lodash';
import { Link } from 'react-router-dom';

import * as userActions from '../../actions/user'
import * as teamsActions from '../../actions/teams';
import { Row, Col, Block, Title, TitleType, Button, PricingTable } from '../../components';

import { StoreState, UserState, ApplicationState } from '../../types';

interface Props {
	location: any,
	onSetProfileData: any
	fetchUserTeams: any;
	user: UserState;
	match: any;
	application: ApplicationState;
	t: any;
}

export interface State {
	newPaymentResult: any,
	isCreatingAPayment: boolean,
}

class Shop extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			newPaymentResult: null,
			isCreatingAPayment: false
		};
	};

	componentDidUpdate(prevProps: Props, prevState: State) {
		if ((this.state.newPaymentResult !== prevState.newPaymentResult) && this.state.newPaymentResult.provider.mollie) {
			window.location.href = this.state.newPaymentResult.provider.mollie._links.checkout.href;
		}
    }
    
    initiatePayment = () => {
		const redirectURL = `https://${window.location.hostname}/shop`;

        this.setState({ isCreatingAPayment: true });
        
		teamsActions.createPayment(
			[],
			this.props.application.competition.competitionFeed,
			this.props.application.competition.seasonId,
			[0],
            redirectURL
		)
			.then((paymentResult: any) => {
				if (paymentResult && paymentResult.order && paymentResult.order.amount && (window as any).fbq) {
					(window as any).fbq('track', 'Purchase', { value: (paymentResult.order.amount / 100), currency: 'EUR' });
				}
				this.setState({ newPaymentResult: paymentResult, isCreatingAPayment: false });
			})
			.catch(() => {
				notification.warning({ message: 'Payment failed. Please contact us in the chat.' });
			});
	};

	render() {
		const { isCreatingAPayment } = this.state;
		const { user, t } = this.props;

		return (
			<React.Fragment>
				<Row>
					<Col md={24} sm={24} xs={24}>
						<Block style={{backgroundColor: "#FFF"}}>
							<Title type={TitleType.h2}>{t('menu.shop')}</Title>
							<Row className="onboarding">
								<Col md={24} style={{padding: "20px 40px 0"}}>
									<p style={{marginBottom: "0px"}}>
									Becoming a <strong>Premium Fantasy player</strong> will allow you to become a <strong>tactical genius</strong> with 6 different formations to choose from. You will be able to use Power Subs which will help give you that <strong>extra edge</strong> over the rest and the ability to create a mini-league for over five friends to battle it out for European Gold. Do you have what it takes?
									</p>
								</Col>
							</Row>

							{(user.data.role !== 'premium') ? 
								<div style={{textAlign: "center", padding: "10px"}}>
									<Button
										onClick={this.initiatePayment}
										loading={isCreatingAPayment}
										type="primary"
										size="default"
										style={{ margin: '20px auto 40px', width: '220px' }}>
										<Icon type="credit-card" theme="filled" />
										{t('shop.buy')}
									</Button>
									<h4>{t('shop.premiumPricing')}</h4>
									<h5>{t('shop.premiumTagline')}</h5>
								</div>
								: <div style={{textAlign: "center", padding: "10px"}}><h4>{t('shop.premiumMemberMessage')}</h4></div>
							}
							<PricingTable></PricingTable>
						</Block>
					</Col>
				</Row>
			</React.Fragment>
		);
	}
}

export const mapDispatchToProps = {
	onSetProfileData: (data: any) => (dispatch: any) => dispatch(userActions.setProfileData(data)),
	fetchUserTeams: userActions.fetchTeams
};

export function mapStateToProps({ user, application, matches }: StoreState.All) {
	return {
		user,
		application
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Shop);