import React, { Component } from "react";
import lockr from 'lockr';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { ThemeProvider } from "./styles/styled-components";
import { theme } from "./styles/theme-scorrd";
// import 'moment/locale/nl';
import CookieConsent, { Cookies } from "react-cookie-consent";
import { withTranslation, WithTranslation } from 'react-i18next';
import { withCookies } from 'react-cookie';
import jwtDecode, { JwtPayload } from "jwt-decode";

import config from './config';

lockr.prefix = config.LOCKR_PREFIX as string;

import { Header, Layout, Spinner, Footer } from "./components";
import {
	Home,
	NewTeam,
	EditTeam,
	Stats,
	Rules,
	Join,
	Rankings,
	Forgot,
	PasswordReset,
	UserProfile,
	Terms,
	Register,
	Deadlines,
	Prizes,
	Points,
	Match,
	Team,
	Transfers,
	Leagues,
	Shop,
	Thanks,
	Privacy,
	Cookie,
	Club,
	ScorrdCallback
} from "./pages";
import { GlobalStyle } from "./styles/global";

import { StoreState, UserState, ApplicationState, Competition } from "./types";
import { connect } from "react-redux";
import { StatsStyle } from "./components/Stats/StatsStyle";

function loggedIn(): boolean {
	return !!lockr.get('token');
}

type AppProps = {
	user: UserState;
	application: ApplicationState;
	cookies: any;
}

class App extends React.PureComponent<WithTranslation & AppProps> {
	constructor(props: WithTranslation & AppProps) {
		super(props);
		
		const token: string = this.props.cookies.get('fa_jwt');

		if(token) {
			lockr.set('token', token);

			const user = jwtDecode<any>(token); // Returns with the JwtPayload type
			lockr.set('user', {id: user.user_id, firstName: user.first_name, lastName: user.last_name, role: 'free'});
		} else if(this.props.cookies && !token) {
			window.location.href = 'https://scorrd.com/users/sign-in';
		}
		
	}

	componentDidMount () {
		// @ts-ignore
		window.$crisp = [];
		// @ts-ignore
		window.CRISP_WEBSITE_ID = "fff5c37d-3d06-45bb-87de-c0351b6749e4";

		(function() {
			var s = document.createElement("script");

			s.src = "https://client.crisp.chat/l.js";
			// @ts-ignore
			s.async = 1;
			document.getElementsByTagName("head")[0].appendChild(s);
		})();
	}

	render() {
		const activeCompetitionsFeedsList = this.props.application.competitionConfig &&
			this.props.application.competitionConfig.activeCompetitions &&
			this.props.application.competitionConfig.activeCompetitions.length ? this.props.application.competitionConfig.activeCompetitions : [];

		const definedCompetitions = this.props.application.competitionConfig &&
			this.props.application.competitionConfig.competitions &&
			this.props.application.competitionConfig.competitions.length ? this.props.application.competitionConfig.competitions : [];

		const competitions = activeCompetitionsFeedsList
			.map((competitionFeed: string) => definedCompetitions.find((competition: Competition) => competition.competitionFeed === competitionFeed));

		// @ts-ignore
		const PrivateRoute = ({ component: Component, ...rest }) => (
			<Route {...rest} render={props => (
				loggedIn() ? (<Component {...props} {...{ t: this.props.t }} />) : (<Redirect to={{ pathname: '/rules', state: { from: props.location } }} />)
			)} />
		);

		// @ts-ignore
		const PublicOnlyRoute = ({ component: Component, ...rest }) => (
			<Route {...rest} render={props => (
				!loggedIn() ? (<Component {...props} {...{ t: this.props.t }} />) : (<Redirect to={{ pathname: '/rules', state: { from: props.location } }} />)
			)} />
		);

		// @ts-ignore
		const UniversalRoute = ({ component: Component, ...rest }) => (
			<Route {...rest} render={props => ((<Component {...props} {...{ t: this.props.t }} />))} />
		);

		return (
			<ThemeProvider theme={theme}>
				<Router>
					<React.Fragment>
						<GlobalStyle />
						<div style={{ minHeight: '100vh', margin: '0 auto -120px' }}>
							<Header {...{ t: this.props.t, cookies: this.props.cookies }} />
							<Spinner />
							<Layout style={{background: 'transparent'}}>
								<UniversalRoute exact path="/" component={NewTeam} />
								{/* <UniversalRoute path="/home" component={Home} /> */}
								<UniversalRoute path="/men" component={Home} exact />
								<UniversalRoute path="/women" component={Home} exact />
								<PublicOnlyRoute path="/register" component={Register} />
								{/* <PrivateRoute path="/new" component={NewTeam} />
								<PrivateRoute path="/leagues" component={Leagues} />
								<PrivateRoute path="/points/:id" component={Points} />
								<PrivateRoute path="/public/:id" component={Points} />
								<PrivateRoute path="/team/:id" component={Team} />
								<PrivateRoute path="/transfers/:id" component={Transfers} />
								<PrivateRoute path="/edit/:id" component={EditTeam} /> */}
								<PrivateRoute path="/profile" component={UserProfile} />
								{/* <PrivateRoute path="/join/:code" component={Join} /> */}
								<UniversalRoute path="/rules" component={Rules} />
								{/* <UniversalRoute path="/deadlines" component={Deadlines} /> */}
								<UniversalRoute path="/terms" component={Terms} />
								{/* <UniversalRoute path="/stats" component={Stats} /> */}
								{/* <UniversalRoute path="/match/:id" component={Match} /> */}
								<UniversalRoute path="/prizes" component={Prizes} />
								<PublicOnlyRoute path="/forgot" component={Forgot} />
								<PublicOnlyRoute path="/reset-password/:resetToken" component={PasswordReset} />
								{/* <UniversalRoute path="/rankings" component={Rankings} /> */}
								<PrivateRoute path="/shop" component={Shop} />
								<PrivateRoute path="/thanks" component={Thanks} />
								<UniversalRoute path="/cookie" component={Cookie} />
								<UniversalRoute path="/privacy" component={Privacy} />
								{
									competitions.map((competition) => {
										return competition && <Route path={`/${competition.slug}`}
												key={`route-group-${competition.competitionFeed}`}
												render={({ match: { url } }) => (
													<React.Fragment>
														{/* <UniversalRoute path={`${url}/home`} component={Home} exact /> */}
														<PrivateRoute path={`${url}/points/:id`} component={Points} />
														<PrivateRoute path={`${url}/public/:id`} component={Points} />
														<PrivateRoute path={`${url}/team/:id`} component={Team} />
														<PrivateRoute path={`${url}/transfers/:id`} component={Transfers} />
														<PrivateRoute path={`${url}/edit/:id`} component={EditTeam} />
														<UniversalRoute path={`${url}/join/:code`} component={Join} />
														<UniversalRoute path={`${url}/match/:id`} component={Match} />
														<PrivateRoute path={`${url}/new`} component={NewTeam} />
														<UniversalRoute path={`${url}/deadlines`} component={Deadlines} />
														<PrivateRoute path={`${url}/leagues`} component={Leagues} />
														<UniversalRoute path={`${url}/stats`} component={Stats} />
														<UniversalRoute path={`${url}/rankings`} component={Rankings} />
													</React.Fragment>
												)}
											>
											</Route>
									})
								}
							</Layout>
							{/* <Layout style={{background: 'transparent'}}><Footer/></Layout> */}
							<div style={{ height: '120px' }}></div>
						</div>
					</React.Fragment>
				</Router>
			</ThemeProvider>
		);
	}
}

export function mapStateToProps({ user, application, matches }: StoreState.All) {
	return {
		user,
		application
	}
}

export default connect(mapStateToProps)(withCookies(withTranslation('translation')(App)));