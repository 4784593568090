import React, { Component } from "react";
import { Row, Col } from 'antd';
import { Redirect } from "react-router-dom";
import lockr from 'lockr';
import { connect } from 'react-redux';
import { StoreState, UserState, ApplicationState } from '../../types';

export interface Props {
	location: any,
	match: any;
	user: UserState;
	application: ApplicationState;
}

class Join extends Component<Props> {

	componentDidMount() {
		if (!this.props.user.authenticated) {
			this.setState({ redirectToHome: true });
		}

		if (this.props.match.params.code) {
			lockr.set(`inviteCode_${this.props.application.competition.slug}`, this.props.match.params.code);
		}
	};

	render() {
		let redirectToHome = false;
		let redirectToNewGame = false;
		let redirectToTeamsLeague = false;

		const selectedCompetitionTeams = this.props.user.teams
			.filter((team: any) => team.competitionFeed === this.props.application.competition.competitionFeed);

		const team = selectedCompetitionTeams[0];

		if (this.props.user.teamsFetched && this.props.match.params.code) {
			if (team) {
				redirectToTeamsLeague = true;
			} else {
				redirectToNewGame = true;
			}
		} else {
			redirectToHome = true;
		}

		if(!this.props.user.authenticated) {
			redirectToHome = true;
		}

		return (
			<Row>
				{/* SHOULD REDIRECT TO SCORRD
				{(this.props.user.teamsFetched || !this.props.user.authenticated) && redirectToHome && <Redirect
					to={{ pathname: `/${this.props.application.competition.slug}/home` }}
				/>} */}

				{this.props.user.teamsFetched && redirectToNewGame && <Redirect
					to={{ pathname: `/${this.props.application.competition.slug}/new` }}
				/>}

				{this.props.user.teamsFetched && redirectToTeamsLeague && <Redirect
					to={{ pathname: `/${this.props.application.competition.slug}/leagues` }}
				/>}
			</Row>
		);
	}
}

export const mapDispatchToProps = {};

export function mapStateToProps({ user, application }: StoreState.All) {
	return {
		user,
		application
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Join);
