import React, { Component } from 'react';
import { Icon } from 'antd';

import { StatsStyle } from './StatsStyle';
import { Col, Row } from '..';

import { ConfirmedSvg, UnConfirmedSvg } from '../../styles/custom-icons';

const ConfirmedIcon = (props: any) => <Icon component={ConfirmedSvg} {...props} />;
const UnConfirmedIcon = (props: any) => <Icon component={UnConfirmedSvg} {...props} />;

export interface StatsOverviewState {
}

export interface StatsOverviewProps {
	budget: number;
	totalPlayers: number;
	totalPlayersSelected: number;
	t: any;
};

class NewGameStats extends Component<StatsOverviewProps, StatsOverviewState> {

	render() {
		const { t } = this.props;
		return (
			<StatsStyle>
				<Row className="stat-row">
					<Col lg={12} md={12} sm={12} xs={12} className="label">{t('transfersPage.overviewBudget')}</Col>
					<Col lg={12} md={12} sm={12} xs={12} className="points">€{this.props.budget.toFixed(2)} mil</Col>
				</Row>
				<Row className="stat-row">
					<Col lg={12} md={12} sm={12} xs={12} className="label">{t('transfersPage.overviewPlayers')}</Col>
					<Col lg={12} md={12} sm={12} xs={12} className="points">{this.props.totalPlayersSelected}/{this.props.totalPlayers}</Col>
				</Row>
			</StatsStyle>
		)
	}
}

export default NewGameStats;