import React, { Component } from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { RouteComponentProps } from "react-router";
import { TransProps } from 'react-i18next';
import i18next from 'i18next';
import { pick } from 'lodash';
import lockr from 'lockr';
import { useTranslation } from "react-i18next";
import { Radio } from 'antd';

import { HeaderStyle, Logo, Nav, Hamburger, HeaderBackground, TopHeaderStyle, MobileTabInfoStyle } from "./HeaderStyle";
import { ReactComponent as FanArenaLogo} from '../../assets/img/header/logo.svg';
import { withRouter } from 'react-router-dom';
import { Layout, Row, Col } from "./../../components";

import * as userActions from '../../actions/user'
import * as playersActions from '../../actions/players'
import * as clubsActions from '../../actions/clubs'
import * as matchesActions from '../../actions/matches'
import * as teamsActions from '../../actions/teams'
import * as applicationActions from '../../actions/application'
import { StoreState, UserState, ApplicationState, MatchesState, Competition } from './../../types';

export const staticPagesTitleMap: { [key: string]: string } = {
	'/stats': 'STATS',
	'/rankings': 'KLASSEMENT',
	'/home': 'HOME',
	'/login': 'LOGIN',
	'/register': 'REGISTER',
	'/rules': 'SPELREGELS',
	'/deadlines': 'WEDSTRIJDEN',
	'/new': 'NIEUW TEAM',
	'/profile': 'MIJN PROFIEL',
};

type HeaderProps = RouteComponentProps & TransProps & {
	user: UserState;
	application: ApplicationState;
	matches: MatchesState;
	fetchPlayers: any;
	fetchClubs: any;
	fetchMatchesInfo: any;
	fetchMatches: any;
	fetchUserTeamsAndLeagues: any;
	fetchTeamLeagues: any;
	onSetProfileData: any;
	selectCompetition: any;
	logout: any;
	match: any;
	t: i18next.TFunction;
	cookies: any;
}

class Header extends Component<HeaderProps> {

	state = {
		windowWidth: window.innerWidth,
		menuToggled: false,
		subscriptions: 0,
		pageTitle: '',
		showTeams: false
	};

	componentDidMount() {
		const urlCompetition = this.props.application.competitionConfig.competitions.filter((competition) => { 
			return this.props.location.pathname.split('/')[1] === competition.slug
		})[0];

		if(urlCompetition && urlCompetition.competitionFeed) {
			this.props.selectCompetition(urlCompetition.competitionFeed)
		}

		this.fetchSelectedCompetitionExtraData();

		if (this.props.user.data.id) {
			this.props.fetchUserTeamsAndLeagues(
				this.props.application.competitionConfig.activeCompetitions
			);

			userActions.profile()
				.then((result) => {
					const user = pick(result, ['id', 'firstName', 'lastName', 'city', 'street', 'streetNr', 'postCode', 'email', 'registerInfo', 'avatar', 'role']);
					lockr.set('user', user);
					this.props.onSetProfileData(user);

					if (result && result.email && result.email.length && (window as any).$crisp && (window as any).$crisp.push) {
						(window as any).$crisp.push(["set", "user:email", result.email]);
						// name: `${result.firstName} ${result.lastName}`,
					}
				});
		}

		if (!this.props.user.authenticated && this.props.cookies.get('fa_jwt')) {
			const token: string = this.props.cookies.get('fa_jwt');
			lockr.set('token', token);

			this.props.fetchUserTeamsAndLeagues(
				this.props.application.competitionConfig.activeCompetitions
			);

			userActions.profile()
				.then((result) => {
					const user = pick(result, ['id', 'firstName', 'lastName', 'city', 'street', 'streetNr', 'postCode', 'email', 'registerInfo', 'avatar', 'role']);
					lockr.set('user', user);
					this.props.onSetProfileData(user);

					if (result && result.email && result.email.length && (window as any).$crisp && (window as any).$crisp.push) {
						(window as any).$crisp.push(["set", "user:email", result.email]);
						// name: `${result.firstName} ${result.lastName}`,
					}
				});			
		}

		// NEEDS SETTING CONFIG
		// teamsActions.getSubscriptions(
		// 	this.props.application.competition.competitionFeed,
		// 	this.props.application.competition.seasonId
		// )
		// 	.then(result => {
		// 		this.setState({ subscriptions: result.total });
		// 	})

		this._checkCurrentPageTitle();
		window.addEventListener('resize', this.onWindowResize);
	}

	fetchSelectedCompetitionExtraData() {
		this.props.fetchPlayers(
			this.props.application.competition.competitionFeed,
			this.props.application.competition.seasonId
		);

		this.props.fetchClubs(
			this.props.application.competition.competitionFeed,
			this.props.application.competition.seasonId
		);

		this.props.fetchMatchesInfo(
			this.props.application.competition.competitionFeed,
			this.props.application.competition.seasonId
		);

		this.props.fetchMatches(
			this.props.application.competition.competitionFeed,
			this.props.application.competition.seasonId
		);
	}

	// gameChanged(competition: Competition) {
	// 	this.fetchSelectedCompetitionExtraData();
	// }

	componentDidUpdate(prevProps: HeaderProps) {
		if (this.props.location !== prevProps.location) {
			this._checkCurrentPageTitle();
		}

		if (this.props.application.competition.competitionFeed !== prevProps.application.competition.competitionFeed) {
			this.fetchSelectedCompetitionExtraData();
 			this.props.history.push(`/${this.props.application.competition.slug}/new`);
		}
	}

	_checkCurrentPageTitle = () => {
		if (staticPagesTitleMap[this.props.location.pathname]) {
			this.setState({ pageTitle: staticPagesTitleMap[this.props.location.pathname] });
		} else if (this.props.location.pathname.includes('my-teams/team/')) {
			const teamId = this.props.location.pathname
				.split('/')
				.find((chunk: any) => !!parseInt(chunk));

			const team = teamId && this.props.user.teams.find((team: any) => team.id === parseInt(teamId));

			if (team) {
				this.setState({ pageTitle: team.name });
			}
		} else {
			this.setState({ pageTitle: '' });
		}
	};

	navOpen: boolean = false;

	_onPressHamburger = (ev: any) => {
		const body = document.getElementsByTagName("body")[0];

		if (this.navOpen) {
			body.style.transform = "";
		} else {
			body.style.transform = "translateX(80vw)";
		}

		this.navOpen = !this.navOpen;

		this._openSubMenu(ev);
	};

	_openSubMenu = (ev: any) => {
		this.setState({ menuToggled: !this.state.menuToggled })
	};

	_onShowTeamsToggle = (event: any) => {
		event.stopPropagation();
		event.preventDefault();
		this.setState({ showTeams: !this.state.showTeams });
	};

	onMenuItemNavigate = (ev: any) => {
		if (this.state.windowWidth < 768) {
			this._onPressHamburger(ev);
		}
	};

	onWindowResize = () => {
		this.setState({ windowWidth: window.innerWidth });
	};

	onLogOut = (e: any) => {
		this.props.cookies.remove('fa_jwt');
		this.props.logout();
	};

	onHelpChatToggle = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		this.onMenuItemNavigate(e);

		if ((window as any).Tawk_API) {
			(window as any).Tawk_API.maximize();
		}
	}

	onChange = (e:any) => {
		this.props.selectCompetition(e.target.value)
		lockr.rm('cachedNewTeamChanges');
	}

	render() {
		const { user, matches, location, t } = this.props;
		const userTeam = user.teams
			.filter((team: any) => team.competitionFeed === this.props.application.competition.competitionFeed)[0];
		const showTransfers = userTeam && matches.info.deadlineWeek && (matches.info.deadlineWeek > userTeam.weekId);
		const showPoints = userTeam && matches.info.deadlineWeek; //  && (matches.info.deadlineWeek > userTeam.weekId);
		const isActive = (match: string) => location.pathname.indexOf(match) !== -1;
		const gameOver = !matches.info.deadlineWeek;
		const gameInProgress = !!matches.info.deadlineWeek;

		let allMenuItems: string[] = ['stats', 'rules'];
		let menuTotalItems = 3;

		allMenuItems.push('rankings');
		menuTotalItems += 1;

		if (user.authenticated) {
			allMenuItems.push('shop');
			allMenuItems.push('profile');
			allMenuItems.push('logout');
			menuTotalItems += 3;
		}

		if (user.authenticated && userTeam) {
			allMenuItems.push('leagues');
			menuTotalItems += 1;
		}

		let visibleMenuItems: string[] = ([] as string[]).concat(allMenuItems);
		let collapsedMenuItems: string[] = [];
		const menuItemSize = 80;
		const wildCardOrFreeHitEnabled = matches.info.deadlineWeek && userTeam && (matches.info.deadlineWeek === userTeam.freeHit || matches.info.deadlineWeek === userTeam.wildCard);

		const insertToMenuAtPosition = (positionIndex: number, item: string) => {
			if (allMenuItems.indexOf(item) === -1) {
				allMenuItems.splice(positionIndex, 0, item);
				visibleMenuItems = ([] as string[]).concat(allMenuItems);
				menuTotalItems += 1;
			}
		};

		if ((user.authenticated && showPoints) || gameOver) {
			insertToMenuAtPosition(1, 'points');
		} else if (user.authenticated && !userTeam) {
			insertToMenuAtPosition(1, 'new');
		}

		if (user.authenticated && userTeam && gameInProgress) {
			if (showPoints) {
				insertToMenuAtPosition(2, 'team');
			} else {
				insertToMenuAtPosition(1, 'team');
			}
		}

		if (wildCardOrFreeHitEnabled) {
			insertToMenuAtPosition(3, 'edit');
		}

		if (user.authenticated && userTeam && !showTransfers && gameInProgress) {
			if (showTransfers) {
				insertToMenuAtPosition(3, 'edit');
			} else {
				insertToMenuAtPosition(2, 'edit');
			}
		}

		if (user.authenticated && showTransfers && gameInProgress && !wildCardOrFreeHitEnabled) {
			if (showTransfers) {
				insertToMenuAtPosition(4, 'transfers');
			} else {
				insertToMenuAtPosition(3, 'transfers');
			}
		}

		const containerWidth = this.state.windowWidth - (this.state.windowWidth * 10 / 100);
		const availableMenuSpace = containerWidth - 20;

		const result = Math.floor(availableMenuSpace / menuItemSize);
		const rank = result < menuTotalItems ? result - 1 : result;

		visibleMenuItems = allMenuItems.slice(0, rank);
		collapsedMenuItems = allMenuItems.slice(rank, menuTotalItems);

		const isCollapsed = (menuItem: string) => collapsedMenuItems.indexOf(menuItem) !== -1;
		const isVisible = (menuItem: string) => visibleMenuItems.indexOf(menuItem) !== -1;

		const gameSlug = this.props.application.competition.slug;
		return (
			<React.Fragment>
				<HeaderBackground>
					<Layout style={{ background: 'transparent' }}>
						<HeaderStyle>
							<Nav>
								<ul>
									{/* <li className={`${isActive('home') ? 'active' : ''}`}>
										<Link to={`/home`}>{t('menu.home')}</Link></li> */}
											
									{((userTeam && isVisible('points')) &&
										<li className={`${isActive('points') ? 'active' : ''}`}>
											<Link to={`/${gameSlug}/points/${userTeam.id}`}>{t('menu.points')}</Link></li>) || null}

									{(isVisible('team') &&
										<li className={`${isActive('team') ? 'active' : ''}`}>
											<Link to={`/${gameSlug}/team/${userTeam.id}`}>{t('menu.team')}</Link></li>) || null}

									{(isVisible('edit') &&
										<li className={`${isActive('edit') ? 'active' : ''}`}>
											<Link to={`/${gameSlug}/edit/${userTeam.id}`}>{t('menu.transfers')}</Link>
										</li>) || null}

									{(isVisible('new') &&
										<li className={`${isActive('new') ? 'active' : ''}`}>
											<Link to={`/${gameSlug}/new`}>{t('menu.team')}</Link></li>) || null}

									{(isVisible('transfers') &&
										<li className={`${isActive('transfers') ? 'active' : ''}`}>
											<Link to={`/${gameSlug}/transfers/${userTeam.id}`}>{t('menu.transfers')}</Link>
										</li>) || null}

									{(isVisible('stats') &&
										<li className={`${isActive('stats') ? 'active' : ''}`}>
											<Link to={`/${gameSlug}/stats`}>{t('menu.stats')}</Link></li>) || null}

									{(isVisible('leagues') &&
										<li className={`${isActive('leagues') ? 'active' : ''}`}>
											<Link to={`/${gameSlug}/leagues`}>{t('menu.minicompetition')}</Link></li>) || null}

									{(isVisible('rankings') &&
										<li className={`${isActive('rankings') ? 'active' : ''}`}>
											<Link to={`/${gameSlug}/rankings`}>{t('menu.rankings')}</Link></li>) || null}

									{(isVisible('rules') &&
										<li className={`${isActive('rules') ? 'active' : ''}`}>
											<Link to="/rules">{t('menu.rules')}</Link>
										</li>) || null}

									{(isVisible('shop') &&
										<li className={`${isActive('shop') ? 'active' : ''}`}>
											<Link to="/shop">{t('menu.shop')}</Link>
										</li>) || null}

									{/* {(isVisible('logout') &&
										<li className={`${isActive('logout') ? 'active' : ''}`}>
											<Link onClick={this.onLogOut} to="/home">{t('menu.logout')}</Link>
										</li>) || null} */}

										<Radio.Group defaultValue={this.props.application.competition.competitionFeed} buttonStyle="solid" onChange={this.onChange}>
											{
												this.props.application.competitionConfig.competitions.map((competition: Competition) => {
													return <Radio.Button key={competition.competitionFeed} value={competition.competitionFeed}>{competition.slug}</Radio.Button>
												})
											}
										</Radio.Group>
									{(
										collapsedMenuItems.length &&
										<li className={`has-children ${this.state.menuToggled ? 'open' : ''}`}>
											<a onClick={this._openSubMenu}>{t('menu.menu')}</a>
											<ul>
												{/* {(isCollapsed('home') &&
													<li className={`${isActive('home') ? 'active' : ''}`}>
														<Link onClick={this._openSubMenu} to="/home">{t('menu.home')}</Link></li>) || null} */}

												{(isCollapsed('points') &&
													<li className={`${isActive('points') ? 'active' : ''}`}>
														<Link onClick={this._openSubMenu} to={`/${gameSlug}/points/${userTeam.id}`}>{t('menu.points')}</Link></li>) || null}

												{(isCollapsed('team') &&
													<li className={`${isActive('team') ? 'active' : ''}`}>
														<Link onClick={this._openSubMenu} to={`/${gameSlug}/team/${userTeam.id}`}>{t('menu.team')}</Link></li>) || null}

												{(isCollapsed('edit') &&
													<li className={`${isActive('edit') ? 'active' : ''}`}>
														<Link onClick={this._openSubMenu} to={`/${gameSlug}/edit/${userTeam.id}`}>{t('menu.transfers')}</Link>
													</li>) || null}

												{(isCollapsed('new') &&
													<li className={`${isActive('new') ? 'active' : ''}`}>
														<Link onClick={this._openSubMenu} to={`/${gameSlug}/new`}>{t('menu.team')}</Link></li>) || null}

												{(isCollapsed('transfers') &&
													<li className={`${isActive('transfers') ? 'active' : ''}`}>
														<Link onClick={this._openSubMenu} to={`/${gameSlug}/transfers/${userTeam.id}`}>{t('menu.transfers')}</Link>
													</li>) || null}

												{(isCollapsed('stats') &&
													<li className={`${isActive('stats') ? 'active' : ''}`}>
														<Link onClick={this._openSubMenu} to={`/${gameSlug}/stats`}>{t('menu.stats')}</Link></li>) || null}

												{(isCollapsed('leagues') &&
													<li className={`${isActive('leagues') ? 'active' : ''}`}>
														<Link onClick={this._openSubMenu} to={`/${gameSlug}/leagues`}>{t('menu.minicompetition')}</Link></li>) || null}

												{(isCollapsed('rankings') &&
													<li className={`${isActive('rankings') ? 'active' : ''}`}>
														<Link onClick={this._openSubMenu} to={`/${gameSlug}/rankings`}>{t('menu.rankings')}</Link></li>) || null}

												{(isCollapsed('rules') &&
													<li className={`${isActive('rules') ? 'active' : ''}`}>
														<Link onClick={this._openSubMenu} to="/rules">{t('menu.rules')}</Link>
													</li>) || null}

												{(isCollapsed('shop') &&
													<li className={`${isActive('shop') ? 'active' : ''}`}>
														<Link onClick={this._openSubMenu} to="/shop">{t('menu.shop')}</Link>
													</li>) || null}

												{/* {(isCollapsed('logout') &&
													<li className={`${isActive('logout') ? 'active' : ''}`}>
														<Link onClick={this.onLogOut} to="/home">{t('menu.logout')}</Link>
													</li>) || null} */}
											</ul>
										</li>) || null}
								</ul>
							</Nav>
						</HeaderStyle>
					</Layout>
				</HeaderBackground>
			</React.Fragment>
		);
	}
}

export const mapDispatchToProps = {
	fetchPlayers: playersActions.fetchPlayers,
	fetchClubs: clubsActions.fetchClubs,
	fetchMatchesInfo: matchesActions.fetchMatchesInfo,
	fetchMatches: matchesActions.fetchMatches,
	fetchUserTeamsAndLeagues: userActions.fetchTeams,
	fetchTeamLeagues: userActions.fetchUserTeamLeaguesAndPersist,
	selectCompetition: (competitionFeed: string) => (dispatch: any) => dispatch(applicationActions.setSelectedCompetitionFeed(competitionFeed)),
	logout: userActions.logOutUser,
	onSetProfileData: (data: any) => (dispatch: any) => dispatch(userActions.setProfileData(data)),
};

export function mapStateToProps({ user, application, matches }: StoreState.All) {
	return {
		user,
		application,
		matches
	}
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Header)
);
