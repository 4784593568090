import React, { Component } from 'react';
import { Icon } from 'antd';

import { PlayerModalStyle } from './../PlayerModal/PlayerModalStyle';
import { Player, Club } from '../../types';
import { ModalProps } from "antd/lib/modal";
import { PlayerBg, PlayerStyle, ClubBadgeBg, SoccerJersey } from '../Player/PlayerStyle';
import { Row, Col, PlayerType } from '..';
import { DeleteButtonSvg, CaptainButtonSvg, SwapButtonSvg, RollBackSvg, ViceCaptainButtonSvg } from '../../styles/custom-icons';
import { BasicTableStyle } from '../PlayerList/PlayerListStyle';

const ExitIcon = (props: any) => <Icon component={DeleteButtonSvg} {...props} />;

export interface TransfersModalState {
}

export interface TransfersModalProps {
	visible: boolean;
	onCancel: any;
	transfers?: any[];
	t: any;
}

class TransfersModal extends Component<TransfersModalProps, TransfersModalState> {
	render() {

		const { t, transfers } = this.props;

		const title = <div className={'custom-title-container'}>
			<ExitIcon onClick={this.props.onCancel} />
		</div>;

		const columns = [
			{
				key: 'id',
				width: '10%',
				title: '#',
				dataIndex: 'id',
				render: (text: string, record: any, index: number) => {
					return <b>{index + 1}</b>
				}
			},
			{
				key: 'outId',
				width: '45%',
				title: t('team.transferOut'),
				dataIndex: 'outId',
				render: (text: string, record: any) => {
					return <b>{record.outPlayer && record.outPlayer.name}</b>
				}
			},
			{
				key: 'inId',
				width: '45%',
				title: t('team.transferIn'),
				dataIndex: 'inId',
				render: (text: string, record: any) => {
					return <b>{record.inPlayer && record.inPlayer.name}</b>
				}
			}
		];


		return (
			<PlayerModalStyle
				title={title}
				closable={false}
				visible={this.props.visible}
				onCancel={this.props.onCancel}
				footer={[]}
			>
				<Row>
					<BasicTableStyle
						columns={columns}
						dataSource={transfers}
						showHeader={true}
						locale={{ emptyText: 'No stats available.' }}
						loading={false}
						pagination={false}
						rowKey={(record: any, index: number) => `record-${index + 1}`}
						rowClassName={(record: object, index: number) => index % 2 ? 'ant-table-row--odd' : 'ant-table-row--even'}
					/>
				</Row>
			</PlayerModalStyle>
		)
	}
}


export default TransfersModal;