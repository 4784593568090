//import 'babel-polyfill';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'airbnb-js-shims';

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import "./styles/index.css";
import './i18n';

import App from './App';
import rootReducer from './reducers';
import thunkMiddleware from 'redux-thunk';
import {createStore, applyMiddleware} from 'redux';
import config from './config';
import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'

import { CookiesProvider } from 'react-cookie';

const store = createStore(rootReducer, applyMiddleware(thunkMiddleware));
const bugsnagClient = bugsnag(config.BUGSNAG_KEY);
bugsnagClient.use(bugsnagReact, React);
const ErrorBoundary = bugsnagClient.getPlugin('react')

ReactDOM.render(
	<ErrorBoundary>
		<CookiesProvider>
			<Provider store={store}>
				<App/>
			</Provider>
		</CookiesProvider>
	</ErrorBoundary>
	, document.getElementById('root'));

import Button from './components/UI/Button/Button';
import Select from './components/UI/Select/Select';
import Input from './components/UI/Input/Input';
import Team from "./components/Team/Team";
import PlayerList from './components/PlayerList/PlayerList';
import Substitutes from './components/Substitutes/Substitutes';
import MatchDaySelector from './components/MatchDaySelector/MatchDaySelector';
import Title from './components/UI/Title/Title';
import {List, ListItem} from './components/UI/List/List';
import Header from './components/Header/Header';
import Stats from './components/Stats/Stats';

export {
	Button,
	Select,
	Team,
	MatchDaySelector,
	PlayerList,
	Input,
	Title,
	Substitutes,
	List,
	ListItem,
	Header,
	Stats
};