import React, { Component, createRef } from "react";
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Element, scroller } from 'react-scroll'
import { Icon, notification, Divider } from 'antd';


import * as userActions from '../../actions/user';
import * as teamsActions from '../../actions/teams';
import { StoreState, ApplicationState, UserState, Team, Coupon } from '../../types';
import PlayerPortrait from '../PlayerPortrait/PlayerPortrait';
import { ContainerStyle, SelectGroupStyle, BasicTableStyle } from '../PlayerList/PlayerListStyle';
import Link from '../UI/Link/Link';

import { Title, TitleType, PlayerList, PlayerType, Block, Button, Row, Col, Checkbox, Input } from '..';

export interface Props {
	application: ApplicationState;
	user: UserState;
	fetchUserTeamsAndLeagues: any;
}

export interface State {
	teamsToPay: Team[],
	teamIdsSelectedForPayment: number[],
	newPaymentResult: any,
	isCreatingAPayment: boolean,
	isInProcessOfPaymentCancel: boolean,
	validatedCoupons: Coupon[],
	couponSlots: string[]
}

class Payments extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			teamsToPay: props.user.teams
				.filter(team => !team.paid),
			teamIdsSelectedForPayment: props.user.teams
				.filter(team => !team.paid)
				.map(team => team.id),
			newPaymentResult: null,
			isCreatingAPayment: false,
			validatedCoupons: [],
			couponSlots: [],
			isInProcessOfPaymentCancel: false
		};
	};

	componentDidUpdate(prevProps: Props, prevState: State) {
		if ((this.props.user.teams.length !== prevProps.user.teams.length) && this.props.user.teams.length) {
			this.setState({
				teamsToPay: this.props.user.teams
					.filter(team => !team.paid),
				teamIdsSelectedForPayment: this.props.user.teams
					.filter(team => !team.paid)
					.map(team => team.id),
			});
		}
		if ((this.state.newPaymentResult !== prevState.newPaymentResult) && this.state.newPaymentResult.provider.mollie) {
			window.location.href = this.state.newPaymentResult.provider.mollie._links.checkout.href;
		}
	}

	onTeamPayToggle = (teamId: number, status: boolean) => {
		if (status) {
			this.setState({ teamIdsSelectedForPayment: [...this.state.teamIdsSelectedForPayment, teamId] });
		} else {
			this.setState({ teamIdsSelectedForPayment: this.state.teamIdsSelectedForPayment.filter(id => id !== teamId), validatedCoupons: [], couponSlots: [] });
		}
	}

	initiatePayment = () => {
		const { teamIdsSelectedForPayment, validatedCoupons } = this.state;
		const redirectURL = `https://${window.location.hostname}/my-teams`;

		this.setState({ isCreatingAPayment: true });

		const couponCodes = validatedCoupons.map((coupon: Coupon) => coupon.code);

		teamsActions.createPayment(
			couponCodes,
			this.props.application.competition.competitionFeed,
			this.props.application.competition.seasonId,
			teamIdsSelectedForPayment,
			redirectURL
		)
			.then((paymentResult: any) => {
				if (paymentResult && paymentResult.order && paymentResult.order.amount && (window as any).fbq) {
					(window as any).fbq('track', 'Purchase', { value: (paymentResult.order.amount / 100), currency: 'EUR' });
				}
				this.setState({ newPaymentResult: paymentResult, isCreatingAPayment: false });
			})
			.catch(() => {
				notification.warning({ message: 'Betaling mislukt. Contacteer ons via de chat.' });
			});
	};

	cancelPayment = (paymentId: number) => {
		this.setState({ isInProcessOfPaymentCancel: true });

		return teamsActions
			.cancelPayment(paymentId)
			.then(() => this.props.fetchUserTeamsAndLeagues(this.props.application.competitionConfig.activeCompetitions))
			.then(() => {
				this.setState({ isInProcessOfPaymentCancel: false });
			})
	};

	addCoupon = () => {
		if ((this.state.couponSlots.length - this.state.validatedCoupons.length) === 1) {
			notification.warning({ message: 'Je kan slechts één kortingscode per keer gebruiken.' });
			return;
		}

		const alreadyAddedADiscountCoupon = !!this.state.validatedCoupons.find((coupon: Coupon) => coupon.type === 'discount1+1');

		if (alreadyAddedADiscountCoupon) {
			notification.warning({ message: 'Je kan slechts één 1+1-kortingscode per account gebruiken.' });
			return;
		}

		const teamOfTwoGroups = Math.floor(this.state.teamIdsSelectedForPayment.length / 2);

		if ((this.state.validatedCoupons.length === teamOfTwoGroups)) {
			notification.warning({ message: 'Je moet minstens twee teams samen afrekenen (waarvan één betaald) wanneer je de 1+1-kortingscode wilt gebruiken.' });
			return;
		}

		const slots = this.state.couponSlots.concat(['']);
		this.setState({ couponSlots: slots });
	};

	removeCoupon = (removableIndex: number) => {
		const removeHandler = (item: any, index: number) => index !== removableIndex;
		const validatedCoupons = this.state.validatedCoupons.filter(removeHandler);
		const couponSlots = this.state.couponSlots.filter(removeHandler);

		this.setState({ validatedCoupons, couponSlots });
	};

	onCouponSlotValueChange = (value: string, couponIndex: number) => {
		this.setState({
			couponSlots: this.state.couponSlots
				.map((couponSlotValue: string, couponSlotsIndex: number) => {
					if (couponIndex === couponSlotsIndex) {
						return value;
					}
					return couponSlotValue;
				})
		});

		if (value.length > 5 && value.length < 12) {
			teamsActions.validateCoupon(value, this.props.application.competition.competitionFeed)
				.then(validateResult => {
					if (validateResult && validateResult.coupon && validateResult.coupon.id) {

						const validatedBefore = this.state.validatedCoupons
							.find((coupon: Coupon) => coupon.id === validateResult.coupon.id);

						if (validatedBefore) {
							return;
						}

						const validatedCoupons = [
							...this.state.validatedCoupons,
							{
								id: validateResult.coupon.id,
								code: validateResult.coupon.code,
								competitionFeed: validateResult.coupon.competitionFeed,
								type: validateResult.coupon.type,
								value: validateResult.coupon.value,
							}
						];

						this.setState({ validatedCoupons })
					}
				});
		}
	};

	getActivePaymentsList = () => {
		const userTeams = this.props.user && this.props.user.teams && this.props.user.teams || [];

		const pendingPaymentTeams = userTeams
			.filter((team: any) => !parseInt(team.paid) && team.paymentId);

		const activePaymentsMap: any = {};
		let activePaymentList: ({ paymentId: number } & { teams: any[] })[] = [];

		pendingPaymentTeams.forEach((team: any) => {
			const hasActivePayment = activePaymentsMap[team.paymentId];
			if (hasActivePayment) {
				activePaymentsMap[team.paymentId].push(team);
			} else {
				activePaymentsMap[team.paymentId] = [team];
			}
		});

		activePaymentList = Object
			.keys(activePaymentsMap)
			.map((paymentId: string) => Object.assign({}, { paymentId: parseInt(paymentId) }, { teams: activePaymentsMap[paymentId] }));

		return activePaymentList;
	}

	render() {
		const { teamsToPay, teamIdsSelectedForPayment, isCreatingAPayment, newPaymentResult, couponSlots, validatedCoupons, isInProcessOfPaymentCancel } = this.state;
		const { application, user } = this.props;
		const activePaymentsList = this.getActivePaymentsList();

		const teamPrice = (teamsToPay && teamsToPay.length && teamsToPay[0].price) || 7;

		const totalTeamsSelectedForPayment = teamIdsSelectedForPayment.length;
		const totalValidatedCoupons = validatedCoupons.length;

		const teamOfTwoGroups = Math.floor(totalTeamsSelectedForPayment / 2);
		const notInGroupTeam = totalTeamsSelectedForPayment % 2;
		const amountToPay = (notInGroupTeam * teamPrice) + (teamOfTwoGroups * (teamPrice * 2)) - (totalValidatedCoupons * teamPrice);

		const columns = [{
			key: 'name',
			title: 'Ploeg',
			dataIndex: 'name',
			width: '70%',
			render: (text: string, record: any) => {
				return <b>
					<Link to={`team/${record.id}`}>{record.name}</Link>
				</b>
			}
		}, {
			key: 'pay',
			title: 'Betaal',
			width: '30%',
			dataIndex: 'paid',
			render: (text: string, record: any) => {
				const checked = teamIdsSelectedForPayment.includes(record.id);
				return <Checkbox checked={checked} onChange={(e: any) => this.onTeamPayToggle(record.id, e.target.checked)} />;
			}
		}
		];

		const couponActionButtonsProps = { fontSize: '20px', marginLeft: '5px' };

		return (
			<React.Fragment>
				<Row>
					<Col md={24} sm={24} xs={24}>
						<Block>
							<Title type={TitleType.h3}>BETALINGEN</Title>
							<BasicTableStyle
								columns={columns}
								dataSource={teamsToPay}
								showHeader={true}
								locale={{ emptyText: 'Alle teams zijn betaald.' }}
								loading={false}
								pagination={false}
								rowKey={(record: any, index: number) => `record-${index + 1}`}
								rowClassName={(record: object, index: number) => index % 2 ? 'ant-table-row--odd' : 'ant-table-row--even'}
							/>
							{
								teamsToPay && teamsToPay.length ?
									<React.Fragment>
										<Divider />
										<p style={{ textAlign: 'right' }}>
											<span>Totaal te betalen: <b> €{amountToPay} </b> </span>
										</p>
									</React.Fragment> : null
							}

						</Block>
					</Col>
					{
						activePaymentsList.length ?
							<React.Fragment>
								<Col md={24} sm={24} xs={24}>
									<Block>
										<p style={{ textAlign: 'justify' }}>Je hebt een niet-afgeronde betaling. Annuleer deze betaling om opnieuw te proberen, en om een eventueel gebruikte kortingscode opnieuw te activeren.</p>
										<div style={{ textAlign: 'center' }}>
											<Button
												onClick={(e: any) => this.cancelPayment(activePaymentsList[0].paymentId)}
												loading={isInProcessOfPaymentCancel}
												type="primary"
												style={{ margin: '0 auto', width: '150px' }}>
												Annuleer
												</Button>
										</div>
									</Block>
								</Col>
							</React.Fragment> :
							null
					}

					{
						!activePaymentsList.length ?
							<React.Fragment>
								<Col md={12} sm={24} xs={24}>
									<Block>
										{
											couponSlots.map((coupon, index) => {
												const couponIsValid = validatedCoupons
													.find((validatedCoupon: Coupon, validatedCouponIndex: number) =>
														validatedCoupon.code === coupon && validatedCouponIndex === index
													);

												const takeCouponInConsideration = coupon && coupon.length > 5;

												return <React.Fragment key={`index-${index}`}>
													<Col md={18} sm={18} xs={18}>
														<Input
															onChange={(e: any) => this.onCouponSlotValueChange(e.target.value, index)}
															disabled={!!couponIsValid}
															value={coupon}
														/>
													</Col>
													{
														<Col md={6} sm={6} xs={6} style={{ height: '20px', paddingTop: '10px' }}>
															{
																couponIsValid &&
																<Icon
																	type="check-circle"
																	style={{ ...couponActionButtonsProps, color: 'green' }}
																	theme="filled" />
															}
															{
																!couponIsValid && takeCouponInConsideration &&
																<Icon
																	type="warning"
																	style={{ ...couponActionButtonsProps, color: 'red' }}
																	theme="filled" />
															}
															<Icon
																type="delete"
																style={{ ...couponActionButtonsProps }}
																onClick={() => this.removeCoupon(index)}
																theme="filled" />
														</Col>
													}
												</React.Fragment>
											})
										}
										{
											teamsToPay && teamsToPay.length ?
												<Col md={24} sm={24} xs={24}>
													<Button
														onClick={this.addCoupon}
														type="default"
														style={{ display: 'block', width: '220px', margin: '0 auto' }}>
														<Icon type="tag" theme="filled" />
														Kortingscode toevoegen
									</Button>
												</Col> : null
										}
									</Block>
								</Col>
								<Col md={12} sm={24} xs={24}>
									<Block>

										{
											!newPaymentResult && teamIdsSelectedForPayment.length ?
												<Col md={24} sm={24} xs={24} style={{ textAlign: 'center' }}>
													<Button
														onClick={this.initiatePayment}
														loading={isCreatingAPayment}
														type="primary"
														style={{ margin: '0 auto', width: '220px' }}>
														<Icon type="credit-card" theme="filled" />
														Betaal
										</Button>
												</Col> : null
										}
									</Block>
								</Col>
							</React.Fragment>
							: null
					}
				</Row>
			</React.Fragment>
		);
	}
}

export const mapDispatchToProps = {
	fetchUserTeamsAndLeagues: userActions.fetchTeams,
};

export function mapStateToProps({ application, user }: StoreState.All) {
	return {
		application,
		user
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Payments);