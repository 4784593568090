import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { Player, AbstractTeamType, UserState, Boosters } from './../../types';
import {
	Title, TitleType, Team, Substitutes, PlayerType, Row, Col, Block,
	Alert, Button, AbstractTeam, MatchDaySelector, Calendar, LeaguesList, FootballCalendar, ConfirmationModal
} from './../../components';
import { Icon, Tooltip} from 'antd';
import { theme } from './../../styles/theme-playsports';
import lockr from 'lockr';

import teamBackground from './../../assets/img/bg-hockey.png';
import { startingListToPositionsList, getPublisherLogoURL } from './../../lib/helpers';
import { BoosterTypes } from './../../lib/constants';
import { pick } from 'lodash';
import * as teamsActions from '../../actions/teams'
import { SaveLineupButton, DeadlineBar, BoostersStyle } from './TeamStyles';
import moment from "moment";

import { TripleCaptSvg, WildcardSvg, BenchSvg, DaySvg } from './../../styles/custom-icons';
const CaptIcon = (props: any) => <Icon component={TripleCaptSvg} {...props} />;
const DayIcon = (props: any) => <Icon component={DaySvg} {...props} />;
const BenchIcon = (props: any) => <Icon component={BenchSvg} {...props} />;
const WildcardIcon = (props: any) => <Icon component={WildcardSvg} {...props} />;

export interface Props {
	match: any;
	user: UserState;
	t: any;
}

export interface State {
	notFound: boolean;
	navigateToLeagues: boolean;
	navigateToTeamEdit: boolean;
	selectedLeagueId: number | null;
	boosterModalVisible: boolean,
	boosterTypeToActivate: string | null
}

class TeamContainer extends Component<AbstractTeamType & Props, State> {
	constructor(props: AbstractTeamType & Props) {
		super(props);

		this.state = {
			notFound: false,
			navigateToLeagues: false,
			navigateToTeamEdit: false,
			selectedLeagueId: null,
			boosterModalVisible: false,
			boosterTypeToActivate: null
		}
	};

	componentDidMount() {
		if (this.props.visibleWeekId) {
			this.getTeamInfo(this.props.visibleWeekId);
		}
	}

	componentDidUpdate(prevProps: AbstractTeamType & Props) {
		if (
			this.props.visibleWeekId && (
				(this.props.visibleWeekId !== prevProps.visibleWeekId) ||
				(this.props.match.params.id !== prevProps.match.params.id)
			)
		) {
			this.getTeamInfo(this.props.visibleWeekId);
		}
	}

	getTeamInfo = (weekId: number) => {
		const teamId = this.props.match.params.id;
		const playerProps =
			['id', 'name', 'short', 'positionId', 'clubId', 'value', 'ban', 'injury', 'form', 'forename', 'surname'];
		const selectionProps: any[] = [];
		Promise.all([teamsActions.get(teamId, weekId)])
			.then(([result]) => {
				const starting = result.players
					.filter((player: any) => player.selection.positionId === 1)
					.map((player: any) => {
						const displayWeekMatches = this.props.matches.data
							.filter((match: any) => match.weekId === weekId && ([match.homeId.id, match.awayId.id].includes(player.clubId)));

						return Object.assign({ inStarting: true, upcomingMatches: displayWeekMatches }, pick(player, playerProps), pick(player.selection, selectionProps))
					});

				const bench = result.players
					.filter((player: any) => player.selection.positionId === 0)
					.map((player: any) => {
						const displayWeekMatches = this.props.matches.data
							.filter((match: any) => match.weekId === weekId && ([match.homeId.id, match.awayId.id].includes(player.clubId)));

						return Object.assign({ inStarting: false, upcomingMatches: displayWeekMatches }, pick(player, playerProps), pick(player.selection, selectionProps))
					});

				const teamName = result.team.name;

				const captainPlayer = result.players
					.find((player: any) => player.selection.captain === 1);

				const captainId = captainPlayer && captainPlayer.id;

				const viceCaptainPlayer = result.players
					.find((player: any) => player && player.selection && player.selection.captain === 2);

				const viceCaptainId = viceCaptainPlayer && viceCaptainPlayer.id;

				const budget = result.players
					.reduce((acc: any, player: any) => acc - player.value, this.props.application.competition.budget);

				const boosters = {
					freeHit: result.team.freeHit,
					bank: result.team.bank,
					tripleCaptain: result.team.tripleCaptain,
					wildCard: result.team.wildCard
				};

				this.props.loadAllMatches();
				this.props.initTeamState(starting, bench, teamName, captainId, budget, undefined, undefined, undefined, [], [], [], viceCaptainId, boosters);

			})
			.catch(error => {
				this.setState({ notFound: true });
			})
	};

	goToTeamEdit = () => {
		this.setState({ navigateToTeamEdit: true });
	};

	onBoosterConfirmCancel = () => {
		this.setState({ boosterModalVisible: false });
	};

	onBoosterActivate() {
		const teamId = this.props.match.params.id;
		this.setState({ boosterModalVisible: false });

		if (this.state.boosterTypeToActivate && this.props.visibleWeekId) {
			teamsActions.activateBooster(teamId, this.state.boosterTypeToActivate)
				.then((result: any) => {
					if (this.state.boosterTypeToActivate === BoosterTypes.FREE_HIT || this.state.boosterTypeToActivate === BoosterTypes.WILDCARD) {
						this.props.reloadUserTeams();
					}
					if (this.props.visibleWeekId) {
						this.setState({ boosterTypeToActivate: null });
						this.getTeamInfo(this.props.visibleWeekId)
					}
				})
		}
	}

	playBooster(boosterType: string) {
		this.setState({ boosterTypeToActivate: boosterType, boosterModalVisible: true });
	}

	render() {
		const {
			application, starting, bench, captainId, viceCaptainId, matches, user, visibleWeekId, initializedExternally,
			leagues, t, boosters } = this.props;
		const { boosterModalVisible, navigateToTeamEdit } = this.state;

		const startingByPositions = startingListToPositionsList(starting, application.competition.lineupPositionRows, 'football');
		const startingPicked = starting
			.filter(player => !!player);

		const teamId = parseInt(this.props.match.params.id);

		const team = this.props.user &&
			this.props.user.teams &&
			this.props.user.teams.find((team: any, index: number) => team.id === teamId);

		const notTeamOwner = team && team.userId && user.data && (team.userId !== user.data.id);

		const leaguesWithNumbers = leagues
			.map((league, index) => Object.assign({}, league, { recordNumber: index + 1 }));

		const teamActionButtonStyle = { marginTop: '15px', width: '100%', maxWidth: '630px' };
		const deadlineWeek = matches.info && matches.info.deadlineWeek;
		const deadlineDate = matches.info && matches.info.deadlineDate;

		const firstTeamGameWeek = deadlineWeek && team && (team.weekId === deadlineWeek || deadlineWeek <= this.props.application.competition.officialStartWeek);
		const match = matches.data.find(match => match.weekId === visibleWeekId);

		const hasUsedAnyBoosterInDeadlineWeek = boosters.bank === visibleWeekId || boosters.tripleCaptain === visibleWeekId ||
			boosters.freeHit === visibleWeekId || boosters.wildCard === visibleWeekId;

		return (
			<React.Fragment>
				{team && starting && starting.length === 0 && <Redirect 
					to={{ pathname: `/new` }}
				/>}
				{team && starting && starting.length !==0 && lockr.get(`inviteCode_${this.props.application.competition.slug}`) && <Redirect
					to={{ pathname: `/${this.props.application.competition.slug}/leagues` }}
				/>}
				{team && navigateToTeamEdit && <Redirect
					to={{ pathname: `/edit/${team.id}` }}
				/>}
				{team && notTeamOwner && <Redirect
					to={{ pathname: `/transfers/${team.id}` }}
				/>}
				{
					(visibleWeekId && deadlineWeek && deadlineDate &&
						<Row><Col lg={24} md={24} sm={24} xs={24}>
							<Block>
								<DeadlineBar>
									<p>{`${t('general.footballWeek')} ${deadlineWeek} deadline:`} <span className="deadline-date">{moment.utc(deadlineDate).local().format('ddd DD MMMM HH:mm')}</span></p>
								</DeadlineBar>
							</Block>
						</Col></Row>)
					|| null
				}

					<Row>
						<Col lg={24} md={24} sm={24} xs={24}>
							<img src="https://fa-scorrd.s3-eu-west-1.amazonaws.com/files/duvel.png" style={{
						width: '100%'
					}} />
						</Col>
					</Row>
				{
					(team && initializedExternally && <Row style={{backgroundColor: "#FFF"}}>

						<Col lg={12} md={12} sm={24} xs={24}>
							<Block>
								<Title type={TitleType.h2}>{t('general.footballLineup')}</Title>
								 
									<div style={{textAlign: "center", padding: "10px"}}>
										<p>
										<Tooltip title={
											(user.data.role !== 'premium') ? "Swap out your players to use the new formations - 3-4-3, 4-3-3, 4-4-2, 4-2-4, 5-4-1, 5-3-2" : "Unlock additional formations (4-4-2, 4-2-4, 3-4-3, 5-3-2, 5-4-1) with a 👑 PREMIUM membership"
										}>
											<span><Link to='/shop'>New Formations</Link></span>
										</Tooltip> - <Tooltip title="With a Power Sub you can manually swap a bench player (who has not yet played) into your starting 11 during the gameweek">
											<span><Link to='/shop'>Power Subs</Link></span>
										</Tooltip> -  <Tooltip title="View the teams in the general classification and in your mini-league. And not only the teams from the previous match day, you can also see the lineups for the next match day.">
											<span><Link to='/shop'>Spy on the Opposition</Link></span>
										</Tooltip>
										</p>
										{(user.data.role !== 'premium') && <p><Link to='/shop'>👑 <span style={{fontWeight: "bold", textDecoration: "underline"}}>Become PREMIUM</span> 👑</Link></p> }
									</div>
								
								<Team
									widthRatio={8}
									heightRatio={7}
									clubs={this.props.clubs.data}
									bg={teamBackground}
									centerAligned={true}
									captainId={captainId}
									modalEnabled={true}
									selection={startingByPositions}
									assetsCdn={application.competition.assetsCdn}
									playerType={PlayerType.SoccerShirt}
									onCaptainSelect={this.props.onCaptainSelect}
									onSwap={this.props.onPlayerSwap}
									showCaptainBadge={true}
									isSwapAble={this.props.isSwapAble}
									swapPlayerId={this.props.swapPlayerId}
									swappedFrom={this.props.swappedFrom}
									playerBadgeColor={theme.primaryColor}
									playerBadgeBgColor={theme.primaryColor}
									playerPointsColor="#000"
									playerPointsBgColor="#555555"
									t={this.props.t} />
								<Substitutes
									selection={bench}
									clubs={this.props.clubs.data}
									title="De bank"
									bgColor="#1D5A25"
									modalEnabled={true}
									showPositionNumber={true}
									playerType={PlayerType.SoccerShirt}
									assetsCdn={application.competition.assetsCdn}
									onSwap={this.props.onPlayerSwap}
									isSwapAble={this.props.isSwapAble}
									swapPlayerId={this.props.swapPlayerId}
									swappedFrom={this.props.swappedFrom}
									playerBadgeColor={theme.primaryColor}
									playerBadgeBgColor={theme.primaryColor}
									playerPointsColor="#000"
									playerPointsBgColor="#555555"
									t={this.props.t}
								/>

								{
									team && visibleWeekId && (<SaveLineupButton>
										<Button
											type="primary"
											onClick={(e: any) => this.props.onTeamSelectionsUpdate(team.id, visibleWeekId)}
											size="large">
											<Icon type="save" theme="filled" />
											{t('team.saveTeam')}
										</Button>
									</SaveLineupButton>) || null
								}
							</Block>
						</Col>

						<Col md={12} lg={12} sm={24} xs={24}>
							{
								this.props.matches.data && this.props.matches.data.length && visibleWeekId ?
									<Block>
										<Title type={TitleType.h2}>{t('general.footballCalendar')}</Title>
										<FootballCalendar
											data={this.props.matches.data}
											size={30}
											t={this.props.t}
											weekId={visibleWeekId}
											showHeader={false}
											assetsCdn={this.props.application.competition.assetsCdn}
											isLoading={this.props.matches.isFetching}
											application={this.props.application} />
									</Block> : null
							}
						</Col>
					</Row>) || null
				}
				<ConfirmationModal
					visible={boosterModalVisible}
					t={this.props.t}
					onCancel={(e: any) => this.onBoosterConfirmCancel()}
					onConfirm={(e: any) => this.onBoosterActivate()}
					title={t('general.activateBooster')}
					text={`${t('team.boosterConfirmPrefix')} <b> ${t(`team.${this.state.boosterTypeToActivate}Booster`)} </b> ${t('team.boosterConfirmSuffix')} ${deadlineWeek}?`} />

			</React.Fragment>
		);
	}
}

const WrappedComponent = AbstractTeam(TeamContainer, { type: 'football' });


export default WrappedComponent;