import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { Player, AbstractTeamType, Match, BoostersWeekStatus } from './../../types';
import {
	Title, TitleType, Team, Substitutes, PlayerType, Row, Col, Block, Button,
	AbstractTeam, MatchDaySelector, FootballCalendar, Stats, PointsStats
} from './../../components';
import { Icon } from 'antd';
import { theme } from './../../styles/theme-playsports';

import teamBackground from './../../assets/img/bg-hockey.png';
import { startingListToPositionsList } from './../../lib/helpers';
import { SaveLineupButton } from '../Team/TeamStyles';

import { pick } from 'lodash';
import * as teamsActions from '../../actions/teams'
import { PowerSubSvg } from '../../styles/custom-icons';

const PowerSubIcon = (props: any) => <Icon component={PowerSubSvg} {...props} />;

export interface Props {
	match: any;
	t: any
}

export interface State {
	notFound: boolean;
	navigateToLeagues: boolean;
	selectedLeagueId: number | null;
}

class TeamContainer extends Component<AbstractTeamType & Props, State> {
	constructor(props: AbstractTeamType & Props) {
		super(props);

		this.state = {
			notFound: false,
			navigateToLeagues: false,
			selectedLeagueId: null,
		}
	};

	componentDidMount() {
		if (this.props.visibleWeekId) {
			this.getTeamInfo(this.props.visibleWeekId);
		} else if (!this.props.matches.info.deadlineWeek && this.props.matches.info.displayWeek) {
			this.getTeamInfo(this.props.matches.info.displayWeek);
		}
	}

	componentDidUpdate(prevProps: AbstractTeamType & Props) {
		if (this.props.visibleWeekId && (this.props.visibleWeekId !== prevProps.visibleWeekId)) {
			this.getTeamInfo(this.props.visibleWeekId);
		}

		if (!this.props.matches.info.deadlineWeek && this.props.matches.info.displayWeek && (this.props.matches.info.displayWeek !== prevProps.matches.info.displayWeek)) {
			this.getTeamInfo(this.props.matches.info.displayWeek);
		}
	}

	parsePlayerPointsValue = (value: string) => {
		try {
			return JSON.parse(value);
		} catch (error) {
			return {};
		}
	};

	getTeamInfo = (weekId: number) => {
		const pointsWeekId = this.props.matches.info.deadlineWeek === weekId && this.props.user.data.role !== 'premium' ? weekId - 1 : weekId;
		const teamId = this.props.match.params.id;
		const playerProps =
			['id', 'name', 'short', 'positionId', 'clubId', 'value', 'ban', 'injury', 'form', 'forename', 'surname', 'stats'];
		const selectionProps: any[] = ['points'];

		Promise.all([
			teamsActions.points(teamId, pointsWeekId)
		])
			.then(([result]) => {
				const pointsConfirmation: any[] = [];
				const weekStat = result.weekStat.find((stat: any) => stat.weekId === pointsWeekId);
				const weekConfirmation = pointsConfirmation.find((item: any) => item.weekId === pointsWeekId);
				const weekPointsConfirmed = weekConfirmation && weekConfirmation.confirmed;
				const provisionalPoints = result.players
					.filter((player: any) => player.selection.positionId === 1)
					.reduce((acc: number, player: any) => {
						const stats = player && player.stats ? player.stats : [];
						const statsTotalPoints = stats
							.reduce((statsAcc: number, item: any) => statsAcc + item.points, 0);

						const points = player.selection.captain ? statsTotalPoints * 2 : statsTotalPoints;
						return acc + points;
					}, 0);

				const teamPointsInfo = {
					generalPoints: result.team.points !== null ? result.team.points : '-',
					generalRank: result.team.rank !== null ? result.team.rank : '-',
					visibleWeekPoints: (weekStat && weekStat.points) || '-',
					visibleWeekRank: (weekStat && weekStat.rank) || '-',
					weekPointsConfirmed: !!weekPointsConfirmed,
					provisionalPoints: provisionalPoints,
					totalTransfers: result.transfers.length,
					weekWinnerPoints: result.weekWinner && result.weekWinner.points !== null ? result.weekWinner.points : '-',
					transfers: result.transfers.map((transfer: any) => {
						const outPlayer = this.props.players.data.find(item => item.id === transfer.outId);
						const inPlayer = this.props.players.data.find(item => item.id === transfer.inId);
						return { ...transfer, outPlayer, inPlayer };
					}),
					weekAveragePoints: result.weekAveragePoints !== null ? parseFloat(result.weekAveragePoints).toFixed(2) : '-'
				};

				const startedThisWeek = { started: result.team.weekId <= pointsWeekId, weekId: result.team.weekId };

				const starting = result.players
					.filter((player: any) => player.selection.positionId === 1)
					.map((player: any) => {
						const playerStats = player.stats && player.stats[0];
						const pointsOverview = playerStats && playerStats.value ? this.parsePlayerPointsValue(playerStats.value) : {};

						const displayWeekMatches = this.props.matches.data
							.filter((match: any) => match.weekId === pointsWeekId && ([match.homeId.id, match.awayId.id].includes(player.clubId)));

						return Object.assign({ inStarting: true, upcomingMatches: displayWeekMatches }, { pointsOverview }, pick(player, playerProps), pick(player.selection, selectionProps))
					});

				const bench = result.players
					.filter((player: any) => player.selection.positionId === 0)
					.map((player: any) => {
						const playerStats = player.stats && player.stats[0];
						const pointsOverview = playerStats && playerStats.value ? this.parsePlayerPointsValue(playerStats.value) : {};

						const displayWeekMatches = this.props.matches.data
							.filter((match: any) => match.weekId === pointsWeekId && ([match.homeId.id, match.awayId.id].includes(player.clubId)));


						return Object.assign({ inStarting: false, upcomingMatches: displayWeekMatches }, { pointsOverview }, pick(player, playerProps), pick(player.selection, selectionProps))
					})
					.sort((firstItem: any, secondItem: any) => {
						if (firstItem.positionId === 1) {
							return -1;
						} else {
							return 0;
						}
					});
				const teamName = result.team.name;

				const captainPlayer = result.players
					.find((player: any) => player.selection.captain === 1);

				const captainId = captainPlayer && captainPlayer.id;

				const viceCaptainPlayer = result.players
					.find((player: any) => player && player.selection && player.selection.captain === 2);

				const viceCaptainId = viceCaptainPlayer && viceCaptainPlayer.id;

				const budget = result.players
					.reduce((acc: any, player: any) => acc - player.value, this.props.application.competition.budget);

				const boosters = {
					freeHit: result.team.freeHit,
					bank: result.team.bank,
					tripleCaptain: result.team.tripleCaptain,
					wildCard: result.team.wildCard
				};

				const isTeamOwner = !!(result.team.userId === this.props.user.data.id);

				this.props.loadAllMatches();
				this.props.initTeamState(starting, bench, teamName, captainId, budget, undefined, pointsWeekId, teamPointsInfo, [], [], [], undefined, boosters, isTeamOwner);
			})
			.catch(error => {
				this.setState({ notFound: true });
			})
	};

	render() {
		const {
			application, starting, bench, matches, visibleWeekId, initializedExternally,
			teamPointsInfo, captainId, viceCaptainId, t, teamName, boosters, isTeamOwner
		} = this.props;
		const startingByPositions = startingListToPositionsList(starting, application.competition.lineupPositionRows, 'football');

		const deadlineWeek = matches.info && matches.info.deadlineWeek;
		const displayWeek = matches.info && matches.info.displayWeek

		const loggedInUserTeam = this.props.user && this.props.user.teams && this.props.user.teams.filter((team: any) => team.competitionFeed === this.props.application.competition.competitionFeed)[0];
		const teamOwner = loggedInUserTeam && (loggedInUserTeam.id === parseInt(this.props.match.params.id));
		const lastGameWeek = deadlineWeek ? deadlineWeek : (displayWeek ? displayWeek + 1 : displayWeek);
		const userRegisterInfo = (this.props.user && this.props.user.data && this.props.user.data.registerInfo) || '';
		const isPublicRoute = this.props.match.path.indexOf('public') !== -1;

		const boosterWeekStatus: BoostersWeekStatus = {
			bank: boosters.bank === visibleWeekId,
			tripleCaptain: boosters.tripleCaptain === visibleWeekId,
			freeHit: boosters.freeHit === visibleWeekId,
			wildCard: boosters.wildCard === visibleWeekId
		};

		const captainSelection = starting.find(player => player && player.id === captainId);
		const captainBench = bench.find(player => player && player.id === captainId);
		const captainHasPlayed = !!((captainSelection && captainSelection.pointsOverview && captainSelection.pointsOverview.time) || (boosterWeekStatus && boosterWeekStatus.bank && captainBench && captainBench.pointsOverview && captainBench.pointsOverview.time));

		const isPowerSubEnabled = !!(teamOwner && this.props.user.data.role === 'premium');

		return (
			<React.Fragment>
				{
					(initializedExternally && visibleWeekId && lastGameWeek &&
						<Row style={{ margin: "5px", backgroundColor: "#FFF" }}>
							<Col md={24}>
								<Block>
									<MatchDaySelector
										day={visibleWeekId}
										t={this.props.t}
										max={this.props.user.data.role == 'premium' ? lastGameWeek : lastGameWeek - 1}
										min={1}
										name={''}
										onNext={(e: any) => this.props.onDayChange('next')}
										onPrev={(e: any) => this.props.onDayChange('prev')} />
								</Block>
							</Col>
						</Row>)
					|| null
				}
									<Row>
						<Col lg={24} md={24} sm={24} xs={24}>
							<img src="https://fa-scorrd.s3-eu-west-1.amazonaws.com/files/duvel.png" style={{
						width: '100%'
					}} />
						</Col>
					</Row>
				{
					(initializedExternally && <Row style={{ margin: 0, backgroundColor: "#FFF" }}>
						<Col lg={12} md={12} sm={24} xs={24}>
							<Block style={{marginTop: '10px'}}>
								{
									// @ts-ignore
									<Title type={TitleType.h2}> {t('pointsPage.statsBlockTitle')} {isPublicRoute ? `"${teamName}"` : ''}</Title>
								}
								<PointsStats
									visibleWeekPoints={teamPointsInfo.visibleWeekPoints}
									t={this.props.t}
									isPublic={isPublicRoute}
									weekWinnerPoints={teamPointsInfo.weekWinnerPoints}
									weekAveragePoints={teamPointsInfo.weekAveragePoints}
								/>
							</Block>

							<Block style={{marginTop: '10px'}}>
								<Title type={TitleType.h2}>{t('general.footballLineup')}</Title>
								<Team widthRatio={8}
									heightRatio={7}
									clubs={this.props.clubs.data}
									bg={teamBackground}
									captainId={captainId}
									captainHasPlayed={captainHasPlayed}
									centerAligned={true}
									selection={startingByPositions}
									assetsCdn={application.competition.assetsCdn}
									onSwap={this.props.onPlayerSwap}
									isSwapAble={(isPowerSubEnabled) ? this.props.isSwapAble : false}
									swapPlayerId={this.props.swapPlayerId}
									swappedFrom={this.props.swappedFrom}
									boosterWeekStatus={boosterWeekStatus}
									showPlayerStatsPoints={true}
									showCaptainBadge={true}
									modalEnabled={true}
									playerType={PlayerType.SoccerShirt}
									playerBadgeColor={theme.primaryColor}
									playerBadgeBgColor={theme.primaryColor}
									playerPointsColor="#000"
									t={this.props.t}
									playerPointsBgColor="#555555" />
								<Substitutes
									selection={bench}
									clubs={this.props.clubs.data}
									title="De bank"
									bgColor="#F0F3F8"
									playerType={PlayerType.SoccerShirt}
									assetsCdn={application.competition.assetsCdn}
									onSwap={this.props.onPlayerSwap}
									isSwapAble={(isPowerSubEnabled) ? this.props.isSwapAble : false}
									swapPlayerId={this.props.swapPlayerId}
									swappedFrom={this.props.swappedFrom}
									playerBadgeColor={theme.primaryColor}
									modalEnabled={true}
									showCaptainBadge={true}
									captainId={captainId}
									playerBadgeBgColor={theme.primaryColor}
									playerPointsColor="#000"
									t={this.props.t}
									playerPointsBgColor="#555555"
								/>

								{
									isPowerSubEnabled && (<SaveLineupButton>
										<Button
											type="primary"
											onClick={(e: any) => this.props.onTeamSelectionsUpdate(loggedInUserTeam.id, visibleWeekId)}
											size="large">
											<PowerSubIcon />
											{t('pointsPage.powerSub')}
										</Button>
									</SaveLineupButton>) || null
								}
							</Block>
						</Col>

						<Col lg={12} md={12} sm={24} xs={24}>
							{
								<Block style={{marginTop: '10px'}}>
									<Title type={TitleType.h2}>{t('pointsPage.overviewBlockTitle')}</Title>
									<Stats 
										application={application}
										matches={matches}
										generalPoints={teamPointsInfo.generalPoints}
										generalRank={teamPointsInfo.generalRank}
										visibleWeekPoints={teamPointsInfo.visibleWeekPoints}
										weekId={visibleWeekId}
										totalTransfers={teamPointsInfo.totalTransfers}
										boosterWeekStatus={boosterWeekStatus}
										visibleWeekRank={teamPointsInfo.visibleWeekRank}
										weekPointsConfirmed={teamPointsInfo.weekPointsConfirmed}
										provisionalPoints={teamPointsInfo.provisionalPoints}
										t={this.props.t}
										transfers={teamPointsInfo.transfers}
										weekWinnerPoints={teamPointsInfo.weekWinnerPoints}
									/>
								</Block>
							}

							{
								matches.data && matches.data.length && visibleWeekId ? <Block style={{marginTop: '10px'}}>
									<Title type={TitleType.h2}>{t('general.footballCalendar')}</Title>
									<FootballCalendar
										data={matches.data}
										size={30}
										t={t}
										weekId={visibleWeekId}
										showHeader={false}
										assetsCdn={application.competition.assetsCdn}
										isLoading={matches.isFetching}
										application={this.props.application} />
								</Block> : null
							}
						</Col>
					</Row>) || null
				}
			</React.Fragment>
		);
	}
}

const WrappedComponent = AbstractTeam(TeamContainer, { type: 'football', mode: 'points' });


export default WrappedComponent;