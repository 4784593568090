import styled from "../../styles/styled-components";
import { mediaQueries } from "../../styles/media-queries";
import { H2, H1 } from "../../styles/global";

const HomeStyle = styled.div`
	background-color: #FFF;
	
	.ant-collapse {
		border-radius: 0;
		border: none;
		
		.ant-collapse-header {
			background-color: ${props => props.theme.primaryContrast};
		}
	}

	.points-confirm {
		.label {
			text-transform: none;
			color: #000;
			text-align: left;
			padding-top: 0px;
    		padding-bottom: 0px;
		}

		.result {
			text-align: right;

			&.confirmed {
				color : green;
			}

			&.unconfirmed {
				color: red;
			}
		}


	}

	${H2} {
		text-align: center;
		margin: 40px 0;
	}

	.onboarding {
		padding: 20px;
		img {
			max-height: 200px;
		}
	}

	.title {
		font-size: 18px;
		margin: 20px 0;
		text-align: center;
		color: rgba(255, 255, 255, 0.6);
	}

	.publishers {
		margin-bottom: 10px;
		display: block;
		text-align: center;
		img {
			max-width: 110px;
			max-height:25px;
		}
		@media ${mediaQueries.tabletL} {
			display: none;
		}
	}

	.home-header {
		font-Family: "Circular Std","Helvetica Neue",Helvetica,Arial,sans-serif; 
		color: #344055; 
		text-transform: uppercase; 
		margin: 0; 

		@media ${mediaQueries.tablet} {
			padding-bottom: 25px; 			
		}
	
	}

	.register-form {
		padding: 20px 40px;
	}

	.how-to-play {
		.step {
			float: left;
			padding: 0px 40px 40px 20px;
			height: 100px;
		}
	}
`;

const Cover = styled.div`
	text-align: center;
	position: relative;
	height: auto;
	background-color: black;

	img {
		min-width: 100%;
	}

	.overlay {
		display: flex;
		flex-direction: column;
		align-items: center;

		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 20px 10px;

		.bottom {
			flex: 1;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: center;
		}

		${H1} {
			color: white;
			
			@media ${mediaQueries.tablet} {
			}

			@media ${mediaQueries.desktop} {
				margin-top: 40px;
			}
		}

		${H1}, p {
			text-shadow: 2px 2px 20px #000000;
			text-align: center;
		}

		p {
			margin: 20px 0 0;


			@media ${mediaQueries.tablet} {
			}
		}

		button {
			height: auto;
		}

		button:hover {
			color: #000;
		}
	}
`;

const CardStyle = styled.div`
	margin-bottom: 40px;
	background-color: #F4F8FF;	
	overflow: hidden;

	img {
		width: 50px;
	}

	.ant-card-meta-title {
		display: inline-block;
		background-color: white;
		padding: 5px;
		color: ${props => props.theme.primaryColor};
		font-weight: bold;
		margin: 10px 0 10px;
	}

	.ant-card-body {
		text-align: justify;

		@media ${mediaQueries.mobileL} {
			height: 140px;
		}

		@media (min-width: 576px) {
			height: 240px;
		}

		@media (min-width: 768px) {
			height: 320px;
		}

		@media (min-width: 1024px) {
			height: 240px;
		}

		padding: 10px;
	}
`;

export {
	HomeStyle,
	Cover,
	CardStyle
};