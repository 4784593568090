import styled from "./styled-components";
import { mediaQueries } from "./media-queries";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
	body {
		background-color: #f7f8fc !important;
		// ::selection {
		// 	color: #03fe77;
		// }
		// ::-moz-selection {
		// 	color: #03fe77;
		// }
	}

	.cursor-pointer {
		cursor: pointer;
	}

	.cookie-cancel-button {
    	background: none !important;
    	text-decoration: underline;
	}

	.possible-formations {
		p {
			max-width: 300px;
			margin: 0 auto;
			text-align: center;
			font-weight: bold;

			@media ${mediaQueries.tablet} {
				max-width: 400px;
			}
		}
	}

	.selected-item {
		-webkit-box-shadow: 0px 0px 11px -2px rgba(0,0,0,0.75);
   		-moz-box-shadow: 0px 0px 11px -2px rgba(0,0,0,0.75);
    	box-shadow: 0px 0px 11px -2px rgba(0,0,0,0.75);
	}
`;

const PageStyle = styled.div`
`;

const H1 = styled.h2`
	font-weight: bold;
`;

const H2 = styled.h2`
	font-weight: bold;
`;

const H3 = styled.h2`
	margin: 5px;
`;

export {
	GlobalStyle,
	PageStyle,
	H1,
	H2,
	H3
};
