const theme = {
	primaryColor: "#ff2e4a",
	primaryContrast: '#F8F8F8',
	positionGk: '#4ab969',
	positionDf: '#2cb3c7',
	positionMf: '#ffc333',
	positionFw: '#fb6b5b',
	secondaryColor: '#000'
};

export {
	theme
};