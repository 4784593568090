import styled from 'styled-components';
import { Select } from 'antd';

import 'antd/lib/select/style';
import { MySelectProps } from './Select';

const SelectStyle = styled(Select)`
    width: 200px;

    ${(props: MySelectProps) => props.block === true && `
        width: 100%;
    `}

    &.ant-select-open .ant-select-selection, .ant-select-selection:focus {
        border: none;
        box-shadow: none;
    }

    .ant-select-selection {
        border-radius: 0;

        background: ${props => props.theme.primaryContrast} url(/assets/img/dropdown-action.svg) right 15px center no-repeat;

        .ant-select-selection__placeholder {
            color: #000000;
        }
    }

    &.ant-select-disabled .ant-select-selection {
        background: #F9F9F9 url(/assets/img/dropdown-action-disabled.svg) right 15px center no-repeat;
        
        .ant-select-selection__placeholder {
            color: #ababab;
        }
    }
` as any;

const selectTopMarginOnSmallerScreens = (component: any) => styled(component)`
	&.ant-select {
		@media (max-width: 768px) {
			margin-top: 10px;
		}
	}
`;

const OptionStyle = styled(Select.Option)``;

export {
	SelectStyle,
	OptionStyle,
	selectTopMarginOnSmallerScreens
};