import { FootballPositionIds } from './constants';

interface RowToPosition {
	rowNumber: number;
	positionId: number
}

function startingListToPositionsList(starting: any, rowConfig: number[], type?: string, rowToPositionsList?: RowToPosition[]) {
	const result = [];

	if (type === 'football' && !rowToPositionsList) {
		const GoalKeeper = starting.filter((player: any) => player && player.positionId === FootballPositionIds.Goalkeeper);
		const Defenders = starting.filter((player: any) => player && player.positionId === FootballPositionIds.Defender);
		const Midfielders = starting.filter((player: any) => player && player.positionId === FootballPositionIds.Midfielder);
		const Attackers = starting.filter((player: any) => player && player.positionId === FootballPositionIds.Forward);

		result.push(GoalKeeper, Defenders, Midfielders, Attackers);
	} else {
		const rows = rowConfig.length;
		for (let row = 0; row < rows; row++) {
			const rowsPositionsMapList = rowToPositionsList && rowToPositionsList.length ? rowToPositionsList : [];
			const rowPosition = rowsPositionsMapList.find((item: RowToPosition) => item.rowNumber === (row + 1));
			const totalPickedSoFar: number = result
				.map(items => items.length)
				.reduce((acc, item) => acc + item, 0);

			const lastPickedIndex: number = totalPickedSoFar;
			const sliceFrom: number = row === 0 ? 0 : lastPickedIndex;
			const sliceTo: number = row === 0 ? rowConfig[row] : lastPickedIndex + rowConfig[row];
			const items: any[] = starting
				.slice(sliceFrom, sliceTo)
				.map((item: any) => {
					if (rowPosition) {
						const positionObj = { positionId: rowPosition.positionId };
						return item === null ? positionObj : { ...item, ...positionObj };
					} else {
						return item;
					}
				});
			result.push(items);
		}
	}
	return result;
}

function getPlayerPositionHexColor(player: any, theme: any) {
	const defaultHex = '#000';

	if (!player) {
		return defaultHex;
	}

	if (player.positionId === 14) {
		return theme.positionGk;
	} else if (player.positionId === 15) {
		return theme.positionDf;
	} else if (player.positionId === 16) {
		return theme.positionMf;
	} else if (player.positionId === 17) {
		return theme.positionFw;
	} else {
		return defaultHex;
	}
}

function firstLetterUppercased(string: string) {
	return string.charAt(0).toUpperCase();
}

function roundNextHalf(number: number) {
	const integerPart = Math.floor(number);
	const decimalPart = number - integerPart;

	if (decimalPart === 0) {
		return integerPart;
	} else if (decimalPart <= 0.5) {
		return integerPart + 0.5;
	} else {
		return integerPart + 1;
	}
}

function getPublisherLogoURL(publisher: string) {
	if (['nieuwsblad', 'gazet', 'gentenaar', 'belang'].includes(publisher)) {
		return `https://megaspelen.s3.eu-west-3.amazonaws.com/mediahuis_logos/${publisher}.jpg`;
	} else {
		return `https://megaspelen.s3.eu-west-3.amazonaws.com/mediahuis_logos/nieuwsblad.jpg`;
	}
}

const rankingMapping = {
	'11': 'general',
	'12': 'week',
	'13': 'periode-1',
	'14': 'periode-2',
	'15': 'periode-0',
	'16': 'league-week'
};

function getImageUrl(fileName: string) {
	return `https://fanarena-admin.s3-eu-west-1.amazonaws.com/${fileName}`
}

function getPrizes(prizesWithTranslations: any) {
	const prizesMap = {} as any;

	const prizesList = prizesWithTranslations.map((prizeData: any) => {
		const translatedPrize = prizeData.translations.find((translation: any) => translation.languageCode === 'nl');
		let imageURL = null;

		if (prizeData.image && prizeData.image.fileName) {
			imageURL = getImageUrl(prizeData.image.fileName);
		}
		return Object.assign(
			{ firstRank: prizeData.firstRank, lastRank: prizeData.lastRank, },
			translatedPrize || {},
			{ sort: prizeData.sort, image: imageURL, ranking: prizeData.ranking }
		);
	});

	const rankingsValues = Object.keys(rankingMapping);
	const prizesGrouped = rankingsValues
		.map(rankingValue => {
			//@ts-ignore
			const key: any = rankingMapping[rankingValue as any];
			return {
				[key]: prizesList
					.filter((prizeItem: any) => prizeItem.ranking === rankingValue)
					.sort((firstPrize: any, secondPrize: any) => firstPrize.sort - secondPrize.sort)
			};
		})
		.reduce((acc, item) => ({ ...acc, ...item }), {});

	return prizesGrouped;
}

function getNumberOfExtraClubPlayers(players: any, teamSameClubPlayersLimit: any) {
	const clubsIds: any[] = [];
	let extraPlayers = 0;

	players
		.forEach((player: any) => {
			if (player && !clubsIds.includes(player.clubId)) {
				clubsIds.push(player.clubId);
			}
		});

	clubsIds
		.forEach(clubId => {
			const totalClubPlayers = players.filter((player: any) => player && player.clubId === clubId).length;
			if (totalClubPlayers > teamSameClubPlayersLimit) {
				extraPlayers += totalClubPlayers - teamSameClubPlayersLimit;
			}
		});
	return extraPlayers;
}

function roundDownDecimal(number: any) {
	const scale = 1;
	if (!('' + number).includes('e')) {
		//@ts-ignore
		return +(Math.floor(number + 'e+' + scale) + 'e-' + scale);
	} else {
		const arr = ('' + number).split('e');
		let sig = '';
		if (+arr[1] + scale > 0) {
			sig = '+';
		}
		//@ts-ignore
		return +(Math.floor(+arr[0] + 'e' + sig + (+arr[1] + scale)) + 'e-' + scale);
	}
};

function selectionPlayerSellValue(player: any) {
	const currentPlayer = Object.assign({}, player);
	const currentPlayerValue = currentPlayer.value;
	const selectionValue = player.selection.value;
	//@ts-ignore
	const difference = parseFloat(currentPlayerValue - selectionValue);
	let playerSellValue = null;

	if (selectionValue >= currentPlayerValue) {
		playerSellValue = currentPlayerValue;
	} else {
		//@ts-ignore
		const profit = roundDownDecimal(((difference * 50) / 100));
		playerSellValue = selectionValue + profit;
	}
	return parseFloat((playerSellValue).toFixed(2));;
}

export {
	startingListToPositionsList,
	firstLetterUppercased,
	roundNextHalf,
	getPlayerPositionHexColor,
	getPublisherLogoURL,
	getPrizes,
	getNumberOfExtraClubPlayers,
	selectionPlayerSellValue
}