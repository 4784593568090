import React, { Component } from "react";
import parseHTML from 'html-react-parser';
import { connect } from 'react-redux';
import { Block, Row, Col, Title, TitleType } from '../../components';
import * as userActions from '../../actions/user'
import { Divider } from 'antd';
import { StoreState, ApplicationState } from '../../types';
import { RankingPrizesStyles, PrizesStyles } from './PrizesStyles';
import { getPrizes } from './../../lib/helpers';

type PrizesProps = {
	application: ApplicationState;
}

type RankingPrizesProps = {
	prizes: any;
}

class RankingPrizes extends Component<RankingPrizesProps> {

	render() {
		return this.props.prizes && this.props.prizes.length ?
			this.props.prizes.map((prize: any, index: number) => {
				return <RankingPrizesStyles key={`prize-${index}`}>
					<Row className="prize-row" style={{ margin: 0 }}>
						<React.Fragment>
							<Col md={12} xs={24}>
								<img src={prize.image} className="image-prizes-widget" />
							</Col>
							<Col md={12} xs={24} className="prize-body">
								<h2>{prize.title}</h2>
								{parseHTML(prize.body)}
							</Col>
						</React.Fragment>
					</Row>
				</RankingPrizesStyles>;
			})

			: null
	}
}

class Prizes extends Component<PrizesProps> {
	state = {
		prizesMap: {} as { general: string, week: string, mountain: string, sprint: string, 'periode-1': string, 'periode-2': string },
		loaded: false
	}

	componentDidMount() {
		userActions.getPrizes(this.props.application.competition.competitionFeed)
			.then((result: any) => {
				this.setState({ prizesMap: getPrizes(result), loaded: true });
			});
	}

	render() {
		const blockStyle = { backgroundColor: '#F4F8FF', marginBottom: '15px', paddingTop: 0 };

		return this.state.loaded ? <PrizesStyles>
			<Row>
				<Col span={24}>
					<Block style={blockStyle}>
						<Title type={TitleType.h3}>Algemene rangschikking</Title>
						<RankingPrizes prizes={this.state.prizesMap.general} />
					</Block>
					<Block style={blockStyle}>
						<Title type={TitleType.h3}>Speeldagrangschikking</Title>
						<RankingPrizes prizes={this.state.prizesMap.week} />
					</Block>
					<Block style={blockStyle}>
						<Title type={TitleType.h3}>PERIODE - SPEELDAG 7 TEM 30</Title>
						<RankingPrizes prizes={this.state.prizesMap['periode-1']} />
					</Block>
					<Block style={blockStyle}>
						<Title type={TitleType.h3}>PERIODE - PLAY-OFFS</Title>
						<RankingPrizes prizes={this.state.prizesMap['periode-2']} />
					</Block>
				</Col>
			</Row>
		</PrizesStyles> : null

	}
}

export function mapStateToProps({ application }: StoreState.All) {
	return {
		application
	}
}

export default connect(mapStateToProps, {})(Prizes);
