import React, { Component } from "react";
import moment from 'moment';
import { Redirect } from "react-router-dom";
import lockr from 'lockr';
import { Player, AbstractTeamType } from './../../types';
import {
	Title, TitleType, PlayerList, Team, Substitutes, PlayerType, Row, Col, Block,
	TeamOverview, Select, Button, Input, AbstractTeam, NewGameStats
} from './../../components';
import { Icon, notification } from 'antd';
import { theme } from './../../styles/theme-playsports';

import teamBackground from './../../assets/img/bg-hockey.png';
import teamSponsor from '../../assets/img/header/megaspelen-logo.png';
import { startingListToPositionsList, getPublisherLogoURL } from './../../lib/helpers'
import { NewTeamStyles } from './NewTeamStyles';
import * as teamsActions from '../../actions/teams'

import { Element, scroller } from 'react-scroll'

export interface Props {
	t: any;
	matches: any;
	user: any;
};

export interface State {
	redirectToPayments: boolean;
	hasPlayers: boolean;
}

class NewTeamContainer extends Component<AbstractTeamType & Props, State> {
	constructor(props: AbstractTeamType & Props) {
		super(props);

		this.state = {
			redirectToPayments: false,
			hasPlayers: false
		};
	};

	componentDidMount() {
		this.props.activateCacheChanges();

		const cachedChanges = lockr.get('cachedNewTeamChanges') as { starting: any, bench: any, teamName: string, captainId: number, budget: number };

		if (cachedChanges) {
			this.props.initTeamState(
				cachedChanges.starting,
				cachedChanges.bench,
				cachedChanges.teamName,
				cachedChanges.captainId,
				cachedChanges.budget
			);
		}

		if(this.props.user.teams.filter((team: any) => team.competitionFeed === this.props.application.competition.competitionFeed)[0]) {
			this.loadTeam();
		}
	}

	componentDidUpdate(prevProps: AbstractTeamType & Props) {
		if(this.props.user.teams.filter((team: any) => team.competitionFeed === this.props.application.competition.competitionFeed)[0] && (this.props.user.teams !== prevProps.user.teams || this.props.matches.weeks !== prevProps.matches.weeks)) {
			this.loadTeam();
		}
	}

	loadTeam = () => {
		const team = this.props.user.teams.filter((team: any) => team.competitionFeed === this.props.application.competition.competitionFeed)[0];
		return teamsActions.get(team.id, undefined, true).then(result => {
			this.props.setTeamName(result.team.name);
			this.setState({ hasPlayers: (result.players.length !== 0) }); 
		})
	}

	onPlaceHolderClick = (player: any) => {
		if (player && player.positionId) {
			this.props.setActivePositionFilter(player.positionId);
		}
		scroller.scrollTo('all-players', {
			duration: 1000,
			delay: 100,
			smooth: true,
			offset: 50
		})
	};

	onTeamSave = (e: any) => {
		if (this.props.savingTeamPending) {
			return false;
		}

		this.props.onTeamSave()
			.then(() => {
				// location.reload();
				this.setState({ redirectToPayments: true });
			})
			.catch(() => {
			});
	};

	onTeamReset = (team: any) => {
		if (this.props.savingTeamPending) {
			return false;
		}

		this.props.onTeamReset(team)
			.then(() => {
				this.setState({ redirectToPayments: true });
			})
			.catch(() => {
			});
	};

	render() {
		const { application, starting, bench, captainId, viceCaptainId, teamName, budget, savingTeamPending, t, activePositionFilter, matches } = this.props;
		const { redirectToPayments, hasPlayers } = this.state;

		const startingByPositions = startingListToPositionsList([].concat(starting as any, bench as any), [2, 5, 4, 4], 'football');
		const totalPlayersToPick = application.competition.lineupSize + application.competition.benchSize;
		const startingPicked = starting
			.filter(player => !!player.id);
		const benchPicked = bench
			.filter(player => !!player.id);
		const totalPlayersPicked = startingPicked.length + benchPicked.length;
		const team = this.props.user && this.props.user.teams && this.props.user.teams.filter((team: any) => team.competitionFeed === this.props.application.competition.competitionFeed)[0];
		
		return (
			<NewTeamStyles>
				{(team && team.id && hasPlayers) && <Redirect
					to={{ pathname: `/${application.competition.slug}/team/${team.id}` }}
				/>}
									<Row>
						<Col lg={24} md={24} sm={24} xs={24}>
							<img src="https://fa-scorrd.s3-eu-west-1.amazonaws.com/files/duvel.png" style={{
						width: '100%'
					}} />
						</Col>
					</Row>
				<Row>
					<Col md={12} sm={24} xs={24} className="left">
						<Block>
							<Title type={TitleType.h2}>{t('general.footballLineup')}</Title>

							<NewGameStats budget={budget}
								totalPlayers={totalPlayersToPick}
								t={t}
								totalPlayersSelected={totalPlayersPicked} />

							<Input
								onChange={this.props.onTeamNameChange}
								style={{ maxWidth: '100%', backgroundColor: '#fff' }}
								placeholder={t('team.newTeamChooseTeamNameInputPlaceholder')}
								value={teamName}
							/>

							<Team
								widthRatio={8}
								heightRatio={7}
								clubs={this.props.clubs.data}
								bg={teamBackground}
								assetsCdn={application.competition.assetsCdn}
								selection={startingByPositions}
								showCaptainBadge={true}
								showPlayerValue={true}
								playerType={PlayerType.SoccerShirt}
								playerBadgeColor="#000"
								captainId={captainId}
								playerBadgeBgColor={theme.primaryColor}
								onPlaceholderClick={this.onPlaceHolderClick}
								showPlayerValueInsteadOfPoints={true}
								onCaptainSelect={this.props.onCaptainSelect}
								onRemove={(player: Player) => this.props.removePlayer(player)}
								modalEnabled={true}
								playerPointsColor={"#000"}
								playerPointsBgColor="#555555"
								t={this.props.t} />
						</Block>

						<Row>
							{(team && !hasPlayers && 
								<Button
									onClick={(e: any) => this.onTeamReset(team)}
									type="primary"
									disabled={savingTeamPending}
									loading={savingTeamPending}
									style={{ width: '100%', maxWidth: '100%', margin: "10px 0" }}
									size="large">
									<Icon type="save" theme="filled" />
									{t('team.saveTeam')}
								</Button>
								) || <Button
									onClick={this.onTeamSave}
									type="primary"
									disabled={savingTeamPending}
									loading={savingTeamPending}
									style={{ width: '100%', maxWidth: '100%', margin: "10px 0" }}
									size="large">
									<Icon type="save" theme="filled" />
									{t('team.saveTeam')}
								</Button>
							}
						</Row>
					</Col>
					<Col md={12} sm={24} xs={24} className="right">
						<Block>
							<Title type={TitleType.h2}>{t('general.footballAllPlayers')}</Title>
							<Element name="all-players">
								<PlayerList
									clubs={this.props.clubs.data}
									matches={matches.data}
									deadlineWeek={matches.info.deadlineWeek}
									isLoading={this.props.players.isFetching}
									hidePositions={false}
									activePositionFilter={activePositionFilter}
									assetsCdn={application.competition.assetsCdn}
									isPickAble={this.props.isPickAble}
									playerType={PlayerType.SoccerShirt}
									data={this.props.players.data}
									onPick={this.props.pickPlayer}
									action
									t={this.props.t}
									showHeader={false}
									size={10}
								/>
							</Element>
						</Block>
					</Col>
				</Row>
			</NewTeamStyles >
		);
	}
}

const WrappedComponent = AbstractTeam(NewTeamContainer, { type: 'football' });


export default WrappedComponent;