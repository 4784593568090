import React, { Component } from 'react';
import { Icon } from 'antd';

import { PlayerModalStyle, PointsOverViewTable } from './PlayerModalStyle';
import { Player, Club } from './../../types';
import { theme } from "../../styles/theme-playsports";
import { getPlayerPositionHexColor } from './../../lib/helpers';
import { Link } from '../UI/Link/Link';
import { ModalProps } from "antd/lib/modal";
import { PlayerBg, PlayerStyle, ClubBadgeBg, SoccerJersey } from './../Player/PlayerStyle';
import { Row, Col, PlayerType } from './../../components';
import { DeleteButtonSvg, CaptainButtonSvg, SwapButtonSvg, RollBackSvg, ViceCaptainButtonSvg } from './../../styles/custom-icons';
import { firstLetterUppercased } from './../../lib/helpers';

const ExitIcon = (props: any) => <Icon component={DeleteButtonSvg} {...props} />;
const CaptainIcon = (props: any) => <Icon component={CaptainButtonSvg} {...props} />;
const ViceCaptainIcon = (props: any) => <Icon component={ViceCaptainButtonSvg} {...props} />;
const DeleteIcon = (props: any) => <Icon component={DeleteButtonSvg} {...props} />;
const SwapIcon = (props: any) => <Icon component={SwapButtonSvg} {...props} />;
const UndoIcon = (props: any) => <Icon component={RollBackSvg} {...props} />;

const PlayerActionsPoints: any = {
	starting: { 14: 1, 15: 1, 16: 1, 17: 1 },
	teamPoints: { 14: 0, 15: 0, 16: 0, 17: 0 },
	goal: { 14: 10, 15: 6, 16: 5, 17: 4 },
	assist: { 14: 2, 15: 2, 16: 2, 17: 2 },
	penaltyGoals: { 14: 3, 15: 3, 16: 3, 17: 3 },
	strokeGoals: { 14: 2, 15: 2, 16: 2, 17: 2 },
	strokeMissed: { 14: -1, 15: -1, 16: -1, 17: -1 },
	strokeSaved: { 14: 3, 15: 0, 16: 0, 17: 0 },
	cleanSheet: { 14: 4, 15: 3, 16: 1, 17: 0 },
	goalTaken: { 14: 2, 15: 1, 16: 0, 17: 0 },
	greenCard: { 14: -1, 15: -1, 16: -1, 17: -1 },
	yellowCard: { 14: -3, 15: -3, 16: -3, 17: -3 },
	redCard: { 14: -8, 15: -8, 16: -8, 17: -8 },
	bonus: {},
	mvp: {},
	mvpRound: {}
};

export interface PlayerModalState {
}

export interface PlayerModalProps {
	visible: boolean;
	player: Player;
	club: Club;
	shirtCycling?: string;
	portraitFace?: string;
	portraitFaceFallBack?: string;
	clubBadge?: string;
	onCancel: any;
	onCaptainSelect?: any;
	onViceCaptainSelect?: any;
	onRemove?: any;
	isSwapAble?: any;
	onSwap?: any;
	type?: PlayerType;
	shirtFallback?: string;
	swapPlayerId?: number | null;
	soccerJersey?: string;
	t: any;
}

class PlayerModal extends Component<PlayerModalProps, PlayerModalState> {

	onRemove = (event: any) => {
		this.props.onRemove(this.props.player);
		this.props.onCancel(event);
	};

	onCaptainSelect = (event: any) => {
		this.props.onCaptainSelect(this.props.player);
		this.props.onCancel(event);
	}

	onViceCaptainSelect = (event: any) => {
		this.props.onViceCaptainSelect(this.props.player);
		this.props.onCancel(event);
	}

	onSwap = (event: any) => {
		this.props.onSwap(this.props.player);
		this.props.onCancel(event);
	}

	getPointsOverviewList = (player: any) => {
		const pointsOverview: any = [];

		Object.keys(PlayerActionsPoints)
			.map((actionName: string) => {
				switch (actionName) {
					case 'starting': {
						const starting = (player.pointsOverview.in_starting) ? 1 : 0;

						pointsOverview.push({ action: this.props.t('player.startingLineup'), quantity: starting, points: starting * PlayerActionsPoints[actionName][player.positionId] });
						break;
					};

					case 'teamPoints': {
						const teamPoints = player.pointsOverview && player.pointsOverview.team_points;

						if (teamPoints) {
							pointsOverview.push({ action: this.props.t('player.teamPoints'), quantity: player.pointsOverview.result, points: teamPoints});
						}
						break;
					};

					case 'goal': {
						const goals = player.pointsOverview.goals || [];
						pointsOverview.push({ action: this.props.t('player.goalLabel'), quantity: goals.length, points: goals.length * PlayerActionsPoints[actionName][player.positionId] });
						break;
					};

					case 'penaltyGoals': {
						const goals = player.pointsOverview.penalty_goals || [];
						pointsOverview.push({ action: this.props.t('player.penaltyGoalLabel'), quantity: goals.length, points: goals.length * PlayerActionsPoints[actionName][player.positionId] });
						break;
					};

					case 'strokeGoals': {
						const goals = player.pointsOverview.stroke_goals || [];
						pointsOverview.push({ action: this.props.t('player.strokeGoalLabel'), quantity: goals.length, points: goals.length * PlayerActionsPoints[actionName][player.positionId] });
						break;
					};

					case 'assist': {
						const assists = player.pointsOverview.assists || [];
						pointsOverview.push({ action: this.props.t('player.assistLabel'), quantity: assists.length, points: assists.length * PlayerActionsPoints[actionName][player.positionId] });
						break;
					};

					case 'strokeMissed': {
						const penaltyMissed = player.pointsOverview.stroke_missed || [];
						pointsOverview.push({ action: this.props.t('player.missedPenaltyLabel'), quantity: penaltyMissed.length, points: penaltyMissed.length * PlayerActionsPoints[actionName][player.positionId] });
						break;
					};

					case 'strokeSaved': {
						const stoppedPenalty = player.pointsOverview.stroke_saved || [];
						pointsOverview.push({ action: this.props.t('player.stoppedPenaltyLabel'), quantity: stoppedPenalty.length, points: stoppedPenalty.length * PlayerActionsPoints[actionName][player.positionId] });
						break;
					};

					case 'cleanSheet': {
						const cleanSheet = player.pointsOverview.conceeded ?
							((player.pointsOverview.conceeded.length === 0 && (player.positionId === 15 || player.positionId === 16 || (player.positionId === 14 && player.pointsOverview.in_starting))) ? 1 : 0) : 0
						pointsOverview.push({ action: this.props.t('player.cleanSheetLabel'), quantity: cleanSheet, points: cleanSheet * PlayerActionsPoints[actionName][player.positionId] });
						break;
					};

					case 'goalTaken': {
						const goalsTaken = player.pointsOverview.conceeded ? player.pointsOverview.conceeded.length : 0;
						console.log(PlayerActionsPoints[actionName][player.positionId]);

						pointsOverview.push({ action: this.props.t('player.goalTakenLabel'), quantity: goalsTaken, points: (goalsTaken >= 5) ? -2 : (goalsTaken >= 3) ? -1 : 0 * PlayerActionsPoints[actionName][player.positionId] });
						break;
					};

					case 'greenCard': {
						const greenCards = player.pointsOverview.green_cards || [];
						pointsOverview.push({ action: this.props.t('player.greenCardLabel'), quantity: greenCards.length, points: greenCards.length * PlayerActionsPoints[actionName][player.positionId] });
						break;
					};

					case 'yellowCard': {
						const yellowCards = player.pointsOverview.yellow_cards || [];
						pointsOverview.push({ action: this.props.t('player.yellowCardLabel'), quantity: yellowCards.length, points: yellowCards.length * PlayerActionsPoints[actionName][player.positionId] });
						break;
					};

					case 'redCard': {
						const redCards = player.pointsOverview.red_cards || [];
						pointsOverview.push({ action: this.props.t('player.redCardLabel'), quantity: redCards.length, points: redCards.length * PlayerActionsPoints[actionName][player.positionId] });
						break;
					};

					case 'bonus': {
						const bonus = (player.pointsOverview.subjective.firstBestPlayer) ? 3 : 
							(player.pointsOverview.subjective.secondBestPlayer) ? 2 : 
								(player.pointsOverview.subjective.thirdBestPlayer) ? 1 : false;
						bonus ? pointsOverview.push({ action: this.props.t('player.bonusLabel'), quantity: 1, points: bonus }) : null
						
						break;
					};

					case 'mvp': {
						const bonus = player.pointsOverview.subjective.mvpOfThePress || false;
						bonus ? pointsOverview.push({ action: this.props.t('player.mvpPressLabel'), quantity: 1, points: 3 }) : null
						
						break;
					};

					case 'mvpRound': {
						const bonus = player.pointsOverview.subjective.mvpOfTheRound || false;
						bonus ? pointsOverview.push({ action: this.props.t('player.mvpRoundLabel'), quantity: 1, points: 5 }) : null
						
						break;
					};
				}
			});

		return pointsOverview;
	};

	render() {
		const { player, shirtCycling, club, onCaptainSelect, onRemove, onSwap, swapPlayerId, shirtFallback, isSwapAble, type, portraitFace,
			portraitFaceFallBack, clubBadge, soccerJersey, onViceCaptainSelect, t } = this.props;

		const PositionLabels: any = {
			14: t('player.goalkeeper'),
			15: t('player.defender'),
			16: t('player.midfielder'),
			17: t('player.attacker')
		};
		const playerName = (player && `${player.surname} ${player.forename && firstLetterUppercased(player.forename)}.`);

		const playerPositionColor = getPlayerPositionHexColor(player, theme);

		const showPointsOverview = player && player.pointsOverview && player.points !== null && player.points !== undefined;
		let pointsOverview: any = [];

		if (showPointsOverview) {
			pointsOverview = this.getPointsOverviewList(player);
		}

		const title = <span>{playerName}</span>;

		let totalActions = 0;

		if (onCaptainSelect) {
			totalActions += 1;
		}

		if (onViceCaptainSelect) {
			totalActions += 1;
		}

		if (onSwap && (player.id !== swapPlayerId)) {
			totalActions += 1;
		}

		if (onSwap && (player.id === swapPlayerId)) {
			totalActions += 1;
		}

		if (onRemove) {
			totalActions += 1;
		}

		const actionColumnSize = 24 / totalActions;

		return (
			<PlayerModalStyle
				title={title}
				visible={this.props.visible}
				onCancel={this.props.onCancel}
				footer={[]}
			>
				<Row className="player-header">
					<Col md={8} sm={8} xs={8}>
						<PlayerStyle className="player-avatar">
							{PlayerType.SoccerShirt === type ? <PlayerBg style={{ position: 'inherit', maxWidth: '90%' }} src={shirtCycling} fallback={shirtFallback} /> : null}
							{PlayerType.SoccerPortrait === type ? <PlayerBg src={portraitFace} /> : null}
							{PlayerType.SoccerPortrait === type ? <ClubBadgeBg src={clubBadge} /> : null}
							{PlayerType.SoccerPortrait === type ? <SoccerJersey bg={soccerJersey} /> : null}
						</PlayerStyle>
					</Col>
					<Col md={16} sm={16} xs={16}>
						<p className={'surname'}>{player.forename} {player.surname}</p>
						<p className={'club'}>{club.name}</p>
						<p className={'position'} style={{ color: playerPositionColor }}>{PositionLabels[player.positionId]}</p>

						{
							showPointsOverview ?
								<span className={'points'}>
									<span className={'value'}>{player.points}</span>
									<span className={'label'}>{t('general.points')}</span>
								</span> :
								null
						}
					</Col>
				</Row>
				<Row>
					{
						onCaptainSelect ?
							<Col md={actionColumnSize} sm={actionColumnSize} xs={actionColumnSize}>
								<div className="action" onClick={this.onCaptainSelect}>
									<CaptainIcon />
									{t('player.captainBadgeLabel')}
								</div>
							</Col> :
							null
					}
					{
						onViceCaptainSelect ?
							<Col md={actionColumnSize} sm={actionColumnSize} xs={actionColumnSize}>
								<div className="action" onClick={this.onViceCaptainSelect}>
									<ViceCaptainIcon />
									{t('player.viceCaptainBadgeLabel')}
								</div>
							</Col> :
							null
					}

					{
						onSwap && isSwapAble && isSwapAble(player) && (player.id !== swapPlayerId) ?
							<Col md={actionColumnSize} sm={actionColumnSize} xs={actionColumnSize}>
								<div className="action" onClick={this.onSwap}>
									<SwapIcon />
									{t('player.swapBadgeLabel')}
								</div>
							</Col> :
							null
					}

					{
						onSwap && (player.id === swapPlayerId) ?
							<Col md={actionColumnSize} sm={actionColumnSize} xs={actionColumnSize}>
								<div className="action" onClick={this.onSwap}>
									<UndoIcon />
									{t('player.undoBadgeLabel')}
								</div>
							</Col> :
							null
					}

					{
						onRemove && !swapPlayerId ?
							<Col md={actionColumnSize} sm={actionColumnSize} xs={actionColumnSize}>
								<div className="action" onClick={this.onRemove}>
									<DeleteIcon />
									{t('player.removeBadgeLabel')}
								</div>
							</Col> :
							null
					}
				</Row>
				{
					showPointsOverview ?
						<PointsOverViewTable>
							<thead>
								<tr>
									<th>{t('player.breakDownTableActionColumnName')}</th>
									<th>{t('player.breakDownTableQuantityColumnName')}</th>
									<th>{t('player.breakDownTablePointsColumnName')}</th>
								</tr>
							</thead>
							<tbody>
								{
									pointsOverview.map((category: any, index: number) =>
										<tr key={`overview-${index}`}>
											<td>{category.action}</td>
											<td>{category.quantity}</td>
											<td>{category.points}</td>
										</tr>
									)
								}
							</tbody>
						</PointsOverViewTable> : null
				}
			</PlayerModalStyle>
		)
	}
}


export default PlayerModal;