import { ApplicationState, Competition } from '../types/index';
import { SET_SELECTED_COMPETITION_FEED } from '../actions/actions-types';
import config from './../config';

const competitionSetup: { initialCompetitionFeed: string; activeCompetitions: string[] } = config.COMPETITION_SETUP;

const sampleCompetition = { competitionFeed: '', seasonId: 0, budget: 0, assetsCdn: '', lineupSize: 0, benchSize: 0, gameMode: '', weeklyTransfers: true, lineupPositionRows: [], transfersAllowed: 0, teamSameClubPlayersLimit: 0, slug: '', officialStartWeek: 0 };

const competition = config.COMPETITIONS
	.find((item: Competition) => item.competitionFeed === competitionSetup.initialCompetitionFeed);

const defaultState = {
	competition: competition || config.COMPETITIONS[0] || sampleCompetition,
	competitionConfig: {
		...competitionSetup,
		competitions: config.COMPETITIONS
	}
};

export function applicationReducer(state: ApplicationState = defaultState, action: any) {
	switch (action.type) {
		case SET_SELECTED_COMPETITION_FEED:
			const currentlySelectedCompetition = { ...state.competition };
			const foundCompetition = state.competitionConfig.competitions
				.find((item: Competition) => item.competitionFeed === action.competitionFeed);
			return { ...state, competition: foundCompetition || currentlySelectedCompetition };
	}
	return state;
}