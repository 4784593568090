import styled from 'styled-components';
import { Layout } from 'antd';

import 'antd/lib/layout/style';
import { mediaQueries } from '../../../styles/media-queries';

export const LayoutStyle = styled(Layout)`
		/* padding: 20px;

		@media ${mediaQueries.tablet} {
			padding: 15px 32px;
		} */
		
    &.ant-layout {
		// background: #FFF;
		margin: 0 auto;
		width: 100%
		
		@media (min-width: 992px) {
			width: 80%;
		}
		
		@media (min-width: 1200px) {
			width: 80%;
		}
    }
`;
