import styled from "styled-components";
import { Table } from "antd";

import { mediaQueries } from "../../styles/media-queries";
import { theme } from '../../styles/theme-playsports';


import "antd/lib/table/style";

const ContainerStyle = styled.div`
	
`;

const SelectGroupStyle = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 10px;

	> div {
		margin: 5px 0;
	}

	@media ${mediaQueries.tablet} {
		flex-wrap: nowrap;
		justify-content: space-between;

		> div {
			margin: 0 5px;
		}
	}
`;

const tablePagination = `
.ant-pagination {
	width: 100%;
	text-align: center;

	.ant-pagination-prev, .ant-pagination-next {
		a {
			border-radius: 0px;
		}

		&:hover:not(.ant-pagination-disabled) a {
			background-color: ${theme.primaryColor};
			border-color: ${theme.primaryColor};
		}
	}

	.ant-pagination-item {
		border-radius: 0px;

		&:hover:not(.ant-pagination-disabled), &.ant-pagination-item-active {
			background-color: ${theme.primaryColor};
			border-color: ${theme.primaryColor};

			a {
				font-weight: bold;
				color: #000;
			}
		}
	}

	.ant-pagination-disabled {
		a {
			border-color: ${theme.primaryContrast};
			background: ${theme.primaryContrast};
			font-weight: bold;
			color: #ababab;
		}
	}
}
`;

const TableStyle = styled(Table)`

	.ant-table-content {
		overflow-x: scroll;
	}

	.ant-table-thead {
		th {
			padding-top: 10px;
			padding-bottom: 10px;
			background: ${props => props.theme.primaryColor};
			color: #000;
			border-radius: 0px !important;
			padding: 7.5px;

			&:last-child {
				text-align: right;
			}
		
			&.ant-table-column-has-sorters {
				border-bottom: none;
				
				&:hover {
					background: ${props => props.theme.primaryColor} !important;
				}
			}

			&.ant-table-column-sort {
				background: ${props => props.theme.primaryColor} !important;
			}

			&.ant-table-column-has-actions {
				background-clip: initial;
			}
		}
	}

	.avatar-container {
		margin-bottom: -10px;
	}

	.ant-table-tbody {
		.ant-table-row {
			background-color: #FFF;

			> td {
				color: #000;
				border: none;
				padding: 7.5px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;

				&:first-child {
					padding: 2.5px 10px 0 15px;
				}

				&:last-child {
					text-align: right;
				}
			}

			&--odd {
				background-color: #f2f2f2;
			}
		}

		.cursor-pointer {
			cursor: pointer;
		}
	}

	.ant-table-row {
		cursor: pointer;
	}

	${tablePagination}	
` as any;

const PlayerStyle = styled.div`
	${(props: any) =>
		props.type === "desktop" &&
		`
		display: none;
	`}
	
	@media ${mediaQueries.mobileL} {
		${(props: any) =>
		props.type === "desktop" &&
		`
			display: block;
		`}
	}

	.name {
		color: #000;
		white-space: nowrap;
    	overflow: hidden;
    	text-overflow: ellipsis;
    	max-width: 150px;
	}

	.player-position {
		display: block;
	}

	${(props: any) =>
		props.type === "mobile" &&
		`
		display: block;
	`}

	@media ${mediaQueries.mobileL} {
		${(props: any) =>
		props.type === "mobile" &&
		`
			display: none;
		`}
	}

	p {
		margin-bottom: 0;

		&:first-child {
			font-weight: bold;
		}

		&:last-child {
			color: ${(props: any) => props.clubColor};

			span {
				${(props: any) =>
		props.position === "gk" &&
		`
					color: ${props.theme.positionGk};
				`}

				${(props: any) =>
		props.position === "df" &&
		`
					color: ${props.theme.positionDf};
				`}

				${(props: any) =>
		props.position === "mf" &&
		`
					color: ${props.theme.positionMf};
				`}

				${(props: any) =>
		props.position === "fw" &&
		`
					color: ${props.theme.positionFw};
				`}
			}
		}

	}
` as any;

export {
	ContainerStyle,
	SelectGroupStyle,
	PlayerStyle,
	TableStyle
};
