import styled, { keyframes } from "../../styles/styled-components";
import { mediaQueries, sizes } from "../../styles/media-queries";

import dropdownSvg from "../../assets/img/dropdown-action.svg";
import topHeaderPNG from "../../assets/img/header/top-header-big.png";
import topHeaderMobile from "../../assets/img/header/top-header.png";

const slideIn = keyframes`
	0% { opacity: 0; top: -5px; }
	30% { opacity: 1; top: -5px; }
	100% { opacity: 1; top:  0px; } 
`;

const HeaderBackground = styled.div`	
	display: flex;
	align-items: center;
	/* padding: 0 15px; */
	/* height: 110px; */
` as any;

const HeaderStyle = styled.header`
	display: flex;
	align-items: center;
	padding: 0 15px;
	height: 70px;
	margin: 10px auto 10px;

	@media ${mediaQueries.mobileM} {
		background-position: center bottom -60px;
	}

	@media ${mediaQueries.tablet} {
		padding: 0 25px;
		background-position: center bottom -90px;
	}

	@media ${mediaQueries.desktop} {
		background-position: center bottom -170px;
	}

	@media ${mediaQueries.desktopL} {
		background-position: center bottom -220px;
	}

	@media (max-width: 1001px) {
		margin-top: 100px;	
	}
` as any;

const Logo = styled.div`
	/* display: flex;
	align-items: center; */
	margin: auto 40px;
	display: none;

	@media ${mediaQueries.tabletL} {
		margin-top: 50px;
		display: inherit;
	}

	a > img {
		@media ${mediaQueries.tabletL} {
			display: inherit;
			margin-top: -60px;
			height: 80px;
		}
	}

	.title {		
		@media ${mediaQueries.tabletL} {
			padding-left: 20px;
			padding-top: 10px;
		}

		h1 {
			line-height: 1;
			margin-bottom: 0;

			@media ${mediaQueries.tabletL} {
				margin-bottom: 5px;
			}
		}

		.sponsor {
			display: flex;
			align-items: center;

			h4 {
				margin-right: 10px;

				@media ${mediaQueries.tabletL} {
				}
			}			

			img {
				height: 30px;
				width: 50px;
			}
		}
	}
`;

const Nav = styled.nav`
	flex: 1;
	top: 0;
	left: -80vw;
	z-index: 9;

	justify-content: center;

	display: block;
	position: static;
	width: auto;
	height: auto;
	background-color: transparent;
	border-right: 0;

	font-size: 14px;

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		flex-direction: column;

		li.has-children > a {
			&:after {
				content: '';
				width: 12px;
				height: 12px;
				background-image: url(${dropdownSvg});
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center center;
				margin-left: 5px;
				margin-top: 2px;
			}
		}
		
		li.has-children > ul {
				display: none;
		}
		
		li.active > a {
			border-bottom: 2px solid ${props => props.theme.primaryColor};
		}

		li a {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 20px;
			margin: 0;
			height: 28px;
			text-transform: uppercase;
			transition: background-color 150ms ease-in;

			@media ${mediaQueries.tabletL} {
				margin: 5px 15px;
				padding: 20px 10px;
			}
			
			&:focus {
				background-color: transparent;
				text-decoration: none;
			}
			
			&:hover {
				border-bottom: 2px solid ${props => props.theme.primaryColor};
				margin-top: 4px;
			}
		}
	}

	> ul {
		
		flex-direction: row;
		background-position: center bottom -90px;
		justify-content: center;
		flex-wrap: wrap;

		> li {
			position: relative;
			top: -5px;

			&.open {
				> ul {
					background-color: #f2f2f2;
					display: inherit;
					min-width: 200px;
					> li {
						animation: 250ms ${slideIn} ease-in;
						animation-fill-mode: forwards;

						&:nth-child(2) {
							animation-delay: 50ms;
						}
						
						&:nth-child(3) {
							animation-delay: 100ms;
						}

						&:nth-child(4) {
							animation-delay: 150ms;
						}

						a {
							padding: 30px 20px;
						}
					}
				}
			}

			> a {
				
			}

			> ul {
				position: absolute;

				right: 0;

				> li {
					opacity: 0;
					position: relative;					
				}
			}
		}
	}
`;


const Hamburger = styled.button`
	background: none;
	appearance: none;
	border: 0;
	width: auto;
	color: #000;
	text-align: left;
	margin-left: 5px;

	.anticon {
		margin-right: 10px;
	}

	@media ${mediaQueries.tabletL} {
		display: none;
	}
`;

const TopHeaderStyle = styled.div`
	background-image: url(${topHeaderPNG});
	background-color: #252525;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top right;
	height: 50px;

	.logo {
		height: 50px;
	}

	@media ${mediaQueries.tabletL} {
		height: 100px;
		width: 100%;
		
		.logo {
			height: 100px;
		}
	}

	.ant-row {
		height: 0px;
		margin: 0px;
	}

	.title {
		position: absolute;
		left: 0px;

		@media ${mediaQueries.tablet} {
			display: block;
		}
	}
`;

const MobileTabInfoStyle = styled.div`
	position: absolute;
	right: 0px;
	top: 10px;
	right: 10px;

	span {
		display: block;

		span {
			display: inline-block;
		}
	}
`;

export {
	HeaderStyle,
	Logo,
	Nav,
	Hamburger,
	HeaderBackground,
	TopHeaderStyle,
	MobileTabInfoStyle
};