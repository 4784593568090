import styled from "styled-components";
import {Alert} from 'antd';

import 'antd/lib/alert/style';

export const AlertStyle = styled(Alert)`
	.ant-alert-message {
		color: #989998 !important;

	}
	&.ant-alert-info {
		background-color: transparent;
		border: none !important;
		padding: 0;
	
		.ant-alert-icon {
			color: #ff2e4a;
			position: initial;
			padding-right: 5px;
		}
	}
`;