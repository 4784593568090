export const GAME_MODES = {
	FOOTBALL: 'football',
	CYCLING: 'cycling',
	HOCKEY: 'hockey'
};

export const GAME_MODE_TYPES = {
	[GAME_MODES.CYCLING]: {
		DEFAULT: 'default',
		ELITE_U21: 'elite_u21'
	},
	[GAME_MODES.FOOTBALL]: {
		DEFAULT: 'default'
	},
	[GAME_MODES.HOCKEY]: {
		DEFAULT: 'default'
	}
}

const competitionInfo = process.env.REACT_APP_COMPETITION_CONFIG && process.env.REACT_APP_COMPETITION_CONFIG.length ?
	JSON.parse(process.env.REACT_APP_COMPETITION_CONFIG) : [];

export default {
	API_URL: process.env.REACT_APP_API_URL,
	HASH_SALT: 'scorrd',
	EXPERTS_LEAGUE_ID: 200,
	BUGSNAG_KEY: process.env.REACT_APP_BUGSNAG_KEY || '',
	LOCKR_PREFIX: process.env.REACT_APP_LOCKR_PREFIX,
	COMPETITION_CONFIG: process.env.REACT_APP_COMPETITION_CONFIG && process.env.REACT_APP_COMPETITION_CONFIG.length
		? JSON.parse(process.env.REACT_APP_COMPETITION_CONFIG) : {},
	COMPETITION_SETUP: process.env.REACT_APP_COMPETITION_SETUP && process.env.REACT_APP_COMPETITION_SETUP.length ?
		JSON.parse(process.env.REACT_APP_COMPETITION_SETUP) : {},
	VRT: process.env.REACT_APP_VRT && process.env.REACT_APP_VRT.length ?
		JSON.parse(process.env.REACT_APP_VRT) : {},
	TAWK_API: process.env.REACT_APP_TAWK_API,
	COMPETITIONS: Array.isArray(competitionInfo) ? competitionInfo : [competitionInfo],
	FB_APP_ID: process.env.REACT_APP_FB_APP_ID
};