const HockeyMaxPositionsPicks = {
	Goalkeeper: { min: 1, max: 1 },
	Defender: { min: 3, max: 5 },
	Midfielder: { min: 2, max: 4 },
	Forward: { min: 1, max: 4 },
	Bench: { min: 4, max: 4 }
};

const HockeyPositionIds = {
	Goalkeeper: 14,
	Defender: 15,
	Midfielder: 16,
	Forward: 17
};

const FootballMaxPositionsPicks = {
	Goalkeeper: { min: 1, max: 1 },
	Defender: { min: 4, max: 4 },
	Midfielder: { min: 3, max: 3 },
	Forward: { min: 3, max: 3 },
	Bench: { min: 4, max: 4 }
  };

const FootballPositionIds = {
	Goalkeeper: 14,
	Defender: 15,
	Midfielder: 16,
	Forward: 17
};

const BoosterTypes = {
	BANK: 'bank',
	TRIPLE_CAPTAIN: 'tripleCaptain',
	FREE_HIT: 'freeHit',
	WILDCARD: 'wildCard'
};

export {
	HockeyMaxPositionsPicks,
	HockeyPositionIds,
	FootballMaxPositionsPicks,
	FootballPositionIds,
	BoosterTypes
}