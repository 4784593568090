import styled from 'styled-components';

const StatsStyle = styled.div`
	border-radius: 0;
	// padding-top: 10px;
	// padding-bottom: 10p

	.stat-row {
		
		&.no-border {
			border-bottom: non;
		}

		.ant-col {
			// padding: 10px;
			// padding-left: 20px;
			// padding-right: 20px;

			&:nth-child(even) {
				text-align: right;
			}
		}

		.label {
			font-size: 14px;
			color: #989998;
			// line-height: 1.5;
			text-align: left;
			text-transform: none;
    		font-weight: normal;
		}

		.points {
			font-size: 14px;
			color: #000;
			display: block;
			font-weight: bold;
		}

		.has-transfers {
			span {
				border-bottom: 2px solid ${props => props.theme.primaryColor};
				cursor: pointer;
				padding: 0px 5px 0px 5px;
			}
		}

		.green {
			color: green;
		}

		.red {
			color: red;
		}
	}
`;


export {
	StatsStyle
};
