import styled from '../../../styles/styled-components';
import bullet from "../../../assets/img/bullet.png";

const H1Style = styled.h1`
	color: #344055;
	text-transform: uppercase;
`;

const H2Style = styled.h2`
    font-size: 24px;
	background-image: url(${bullet});
    background-repeat: no-repeat;
    background-position-y: center;
    background-size: 15px;
    font-family: "Circular Std";
    color: #000;
    margin-bottom: 0;
    margin-top: 0;
    text-transform: uppercase;
    background-color: #FFF;
    padding: 20px 20px 20px 45px;
    background-position-x: 15px;
`;

const H3Style = styled.h3`
    text-align: center;
    margin-bottom: 0px;
    padding: 10px;
    background-color: #344055;
    color: rgba(255, 255, 255, 0.6);
    font-size: 18px;
`;

const H4Style = styled.h4`
    text-align: center;
    margin-bottom: 0px;
    padding: 10px;
    background-color: #f2f2f2;
    font-size: 18px;
`;

export {
	H1Style,
	H2Style,
	H3Style,
	H4Style
};